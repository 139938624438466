import React, { useState, useEffect, useMemo } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Card,
    Input,
    Label,
    Modal,
    FormGroup,
} from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { useSelector, useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useHistory } from "react-router-dom"
import {
    loadDataEditform,
    create,
    update,
} from "services/vitta-core/appointment-book"
import { search as unitServiceSearch } from "services/vitta-core/unit"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import { Formik, Field, Form, FieldArray } from "formik"
import ModalServerAdd from "./ModalServerAdd"
import debounce from "debounce-promise"
import PeriodModal from "./PeriodModal"
import { search as searchItems } from "services/vitta-core/item"
const optionsWeekDay = [
    { value: "segunda", label: "Segunda-Feira" },
    { value: "terca", label: "Terça-Feira" },
    { value: "quarta", label: "Quarta-Feira" },
    { value: "quinta", label: "Quinta-Feira" },
    { value: "sexta", label: "Sexta-Feira" },
    { value: "sabado", label: "Sábado" },
    { value: "domingo", label: "Domingo" },
]

const defaultPeriodsAge = {
    service_id: null,
    max_age_years: 0,
    min_age_years: 0,
    max_age_months: 0,
    min_age_months: 0,
}
const defaultValues = {
    name: null,
    professional_id: null,
    unit_id: null,
    obs: null,
    status: true,
    accept_fit: false,
    periods_age_validity: [],
    periods_start_validity: null,
    periods: {
        segunda: [],
        terca: [],
        quarta: [],
        quinta: [],
        sexta: [],
        sabado: [],
        domingo: [],
    },
    overlap: [],
    out_period:null,
    general_fittings:5
}

const indicesWeek = Object.keys(defaultValues.periods)

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" {...field} {...props} />

const TextAreaInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="textarea" {...field} {...props} />

const TexttimeInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="time" disabled className="bg-light" {...field} {...props} />

const numberInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="number" {...field} {...props} />

const TextDateInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="date" {...field} {...props} />

const SwitchInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const propsCopy = { ...props };
    delete propsCopy.className;
    return(
<div className="custom-switch custom-switch-lg mb-3" dir="ltr">
        <Input
            type="checkbox"
            className="custom-control-input"
            {...field}
            {...propsCopy}
            /*onChange={value => { 
                setFieldValue(field.name, value.target.checked)
            }}*/
            onClick={() => {
                /*this.setState({
                  toggleSwitchLarge: !this.state
                    .toggleSwitchLarge,
                })*/
            }}
        />
        <label className="custom-control-label" htmlFor={props.id}></label>
    </div>
    )
}

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const RegistrationSchedule = () => {
    const [pageData, setPageData] = useState({
        formDefaultValues: defaultValues,
    })
    const [handleModalAdd, setHandleModal] = useState({
        show: false,
        data: null,
    })
    const [handlePeriodModal, setHandlePeriodModal] = useState({
        show: false,
        data: null,
    })

    const [controlState, setControlState] = useState(false)
    const routeMatch = useRouteMatch()
    let isQuoteOrder = false
    if (routeMatch.path.includes("orcamentos/")) isQuoteOrder = true
    const { id } = useParams()
    const { copy } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const Scope = useSelector(state => state.scope)

    const today = new Date().toLocaleDateString("pt-BR")
    const todayOnlyDate =
        today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

    useEffect(() => {
        if (id || copy) loadDataEditPage()
        else setPageData({ formDefaultValues: defaultValues })
        // else loadDataAddPage()
    }, [])

    const loadUnidadeInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await unitServiceSearch(
                    { term: inputValue },
                    1,
                    25
                )
                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })
                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadItemsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1
                let response = await searchItems(
                    {
                        term: inputValue,
                        status: statusActive,
                    },
                    1,
                    25
                )

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const loadProfessionalsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    25
                )
                let items_values = []
                items_values.push({
                    label: "Sem Profissional",
                    value: "",
                })
                response.data.forEach(element => {
                    items_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                    })
                })
                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsUnit = debounce(loadUnidadeInputOptions, 600)
    const debounceOptionsProfessional = debounce(
        loadProfessionalsInputOptions,
        600
    )
    const debounceOptionsItems = debounce(loadItemsInputOptions, 600)
    const onSubmit = async (dataForm, actions, e) => {
       //console.log("submit data", dataForm);
        setControlState(true)
       
        let data = JSON.parse(JSON.stringify(dataForm))
        data.professional_id = data.professional_id?.value
            ? data.professional_id.value
            : null
        data.unit_id = data.unit_id ? data.unit_id.value : null

        for (let i = 0; i < indicesWeek.length; i++) {
            const useWeek = data.periods[indicesWeek[i]]

            if (useWeek) {
                for (let ia = 0; ia < useWeek.length; ia++) {
                    let usePeriod = useWeek[ia]
                    //if(!usePeriod.accept_fit) usePeriod.within_period = 0
                   // if(!data.accept_fit && usePeriod.accept_fit) data.accept_fit = true //se tem encaixe interno entao agenda precisa aceitar encaixe
                    for (let it = 0; it < usePeriod.services.length; it++) {
                        let useService = usePeriod.services[it]
                        if (useService?.service_id) {
                            useService.group_id = useService.service_id.group_id
                            useService.name = useService.service_id.label
                            useService.service_id = useService.service_id.value
                        }
                    }
                }
            }
        }

        for (let i = 0; i < data?.periods_age_validity?.length; i++) {
            data.periods_age_validity[i] = {
                min_age_months: data?.periods_age_validity[i].min_age_months,
                max_age_months: data?.periods_age_validity[i].max_age_months,
                min_age_years: data?.periods_age_validity[i].min_age_years,
                max_age_years: data?.periods_age_validity[i].max_age_years,
                service_id: data?.periods_age_validity[i]?.service_id?.value,
            }
        }

        for (let i = 0; i < data.overlap.length; i++) {
            for (let j = 0; j < data.overlap[i].services.length; j++) {
                let useService = data.overlap[i].services[j]
                //if(!data.overlap[i].accept_fit) data.overlap[i].within_period = 0
                //if(data.overlap[i].accept_fit && !data.accept_fit) data.accept_fit = true
                if (useService?.service_id){
                    useService.group_id = useService?.service_id.group_id
                    useService.name = useService?.service_id.label
                    useService.service_id = useService?.service_id.value
                }
                    
            }
        }
        try {
            if (id) {
                await update(id, data)
            } else {
                await create(data)
            }
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            setControlState(false)
            history.push("/agendas")
        } catch (e) {
            setControlState(false)
            let title = "Ops.."
            let text =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde, ou entre em contato com o suporte."

            if (e?.response?.data?.code == "resource_already_created") {
                text =
                    "Agenda já criada com esses dados. Insira um novo profissional ."
            } else if (
                e?.response?.data?.error_code ==
                "appointment-book-period-empty-services"
            ) {
                text = "Obrigatório informar os serviços em todos os períodos."
            } else if (
                e?.response?.data?.error_code ==
                "appointment-book-overlap-invalid-date"
            ) {
                text =
                    "Informe uma data maior ou igual a hoje na configuração de sobreposição de datas."
            } else if (
                e?.response?.data?.error_code ==
                "appointment-book-overlap-fitting-value-less-than-zero"
            ) {
                text =
                    "Valor de encaixe negativo na Agenda de Dia Único"
            }else if (
                e?.response?.data?.error_code ==
                "appointment-book-fitting-invalid-value"
            ) {
                text =
                    `O valor de encaixe deve estar entre 0 e 5` 
            }
             else if (
                e?.response?.data?.error_code ==
                "appointment-book-start-validity-periods-invalid-date"
            ) {
                text =
                    "Informe uma data maior ou igual a hoje na data inicial da Grade Semanal de Horários."
            } else if (e?.response?.data?.error_code == "missing-data-field") {
                text = "Preencha todos os campos obrigatórios."

                if (e?.response?.data?.error_field == "unit_id") {
                    text = "Preencha o campo Unidade."
                } else if (e?.response?.data?.error_field == "name") {
                    text = "Preencha o campo Nome."
                }
            } else if (e?.response?.data?.error_code == "periods_age_invalid") {
                title = "Informações inválidas!"
                text =
                    'Para criar uma configuração adicional de serviço, é necessário preencher os campos "serviço" e  ao menos um dos campos de idade. Preencha corretamente e tente novamente!'
            } else if (e?.response?.data?.error_code == "existing_record") {
                title = "Configuração já criada!"
                text =
                    "Verifique se os dados inseridos não estão se repetindo e tente novamente!"
            } else if (
                e?.response?.data?.error_code ==
                "appointment-book-overlap-duplicate"
            ) {
                title = "Choque de horário em sobreposições de datas !"
                text =
                    "Verifique se os dados inseridos não estão se chocando e tente novamente!"
            }
            else if (
                e?.response?.data?.error_code ==
                "appointment-book-odonto-fitting-invalid-value"
            ) {
               
                text =
                `O valor de encaixe deve ser maior que 0`
            }
            else if (
                e?.response?.data?.error_code ==
                "invalid_duration"
            ) 
             {
                title = "Configuração Inválida!"
                text =
                    "Existem serviços com tempo de duração menores que 10 minutos, verifique e tente novamente..."
            }
            else if (
                e?.response?.data?.error_code ==
                "invalid_duration_usg"
            ) 
             {
                title = "Configuração Inválida!"
                text =
                    "Existem serviços de Ultrassonografia com duração mínima menor que 8 minutos, verifique e tente novamente..."
            }
            else if (
                e?.response?.data?.error_code ==
                "invalid_exec_mode"
            ) 
             {
                title = "Configuração Inválida!"
                text =
                    "Existem serviços sem modo de execução, verifique e tente novamente..."
            }
            else if (
                e?.response?.data?.error_code ==
                "professional-schedule-conflict"
            ) 
             {
                title = "Configuração Inválida!"
                text =
                    "O profissional já possui um período agendado em outra unidade para os horários escolhidos"
            }
            dispatch(
                showSweetAlertDialog({
                    title: title,
                    text: text,
                    type: "error",
                    showConfirm: true,
                })
            )

            console.log(e)
        }
    }

    const loadDataEditPage = async () => {
        try {
            let data
            if (copy) {
                data = await loadDataEditform(copy)
            } else {
                data = await loadDataEditform(id)
            }
            let periods = data.appointment_book.periods
            for (let i = 0; i < indicesWeek.length; i++) {
                const useDay = periods[indicesWeek[i]]
                for (let j = 0; j < useDay.length; j++) {
                    //useDay[j].within_period = useDay[j].within_period?useDay[j].within_period : 0
                    const useDayServices = useDay[j].services
                    for (let k = 0; k < useDayServices.length; k++) {
                        let auxService = {}
                        auxService.duration = useDayServices[k].duration
                        //Em caso de se criar campos novos no form e estiver editando uma agenda. O formik nao vai reconhecer o input do form se ele nao existir no initialvalues
                        if (
                            useDayServices[k].accept_online_appointment ===
                            undefined
                        ) {
                            auxService.accept_online_appointment =
                                defaultModalItemValues.accept_online_appointment
                        } else {
                            auxService.accept_online_appointment =
                                useDayServices[k].accept_online_appointment
                        }
                        if( !useDayServices[k]?.is_person_execution && !useDayServices[k]?.is_online_execution ){
                            auxService.is_person_execution = true;
                            auxService.is_online_execution = false;
                        }else{
                            auxService.is_person_execution = useDayServices[k]?.is_person_execution;
                            auxService.is_online_execution = useDayServices[k]?.is_online_execution;
                        }

                        auxService.service_id = {
                            label: useDayServices[k].service?.name,
                            value: useDayServices[k].service_id,
                            group_id: useDayServices[k].service?.group_id
                        } //Depois decobrir uma forma de adicionar o nome do serviço na label. Vir o nome do back?

                        useDayServices[k] = auxService
                    }
                }
            }

            let overlapsPage = []
            let overlapData = data.appointment_book.overlap
            for (let i = 0; i < overlapData.length; i++) {
                for (let j = 0; j < overlapData[i].overlaps.length; j++) {
                    let overlapObj = overlapData[i].overlaps[j]

                    for (let k = 0; k < overlapObj.services.length; k++) {
                        let useOverlapService = overlapObj.services[k]
                        useOverlapService.service_id = {
                            label: useOverlapService.service?.name,
                            value: useOverlapService.service_id,
                            group_id: useOverlapService.service?.group_id
                        }
                    }

                    overlapsPage.push(overlapObj)
                }
            }
            let appointment_config_services = []

            for (
                let i = 0;
                i < data?.appointment_book?.appointment_config_services?.length;
                i++
            ) {
                let use = data?.appointment_book?.appointment_config_services[i]
                appointment_config_services.push({
                    service_id: {
                        label: use?.item?.name,
                        value: use?.item?.id,
                    },
                    min_age_months: use?.min_age_months,
                    max_age_months: use?.max_age_months,
                    min_age_years: use?.min_age_years,
                    max_age_years: use?.max_age_years,
                })
            }

            setPageData({
                formDefaultValues: {
                    name: !copy ? data.appointment_book.name : "",
                    accept_fit: data.appointment_book.accept_fit ? true : false,
                    status: data.appointment_book.status ? true : false,
                    obs: data.appointment_book.obs,
                    periods_start_validity: data.appointment_book
                        ?.periods_start_validity
                        ? data.appointment_book?.periods_start_validity.substr(
                              0,
                              10
                          )
                        : null,
                    periods: data.appointment_book.periods,
                    professional_id: {
                        label: !copy
                            ? data.appointment_book?.professional?.name
                            : "",
                        value: !copy
                            ? data.appointment_book?.professional_id
                            : null,
                    },
                    periods_age_validity: appointment_config_services,
                    unit_id: {
                        label: !copy ? data.appointment_book.unit.name : "",
                        value: !copy ? data.appointment_book.unit_id : null,
                    },
                    overlap: overlapsPage,
                    general_fittings: data.appointment_book.general_fittings?data.appointment_book.general_fittings :0
                },
            })
        } catch (e) {
            console.log(e)
        }
    }

    const renderOverlapDate = (index, item, values, arrayHelpers) => {
        if (!item) return null

        return (
            <>
                <Row key={index} className="mt-4">
                    <Col sm="12">
                        <strong>
                            {item.date.substr(8, 2) +
                                "/" +
                                item.date.substr(5, 2) +
                                "/" +
                                item.date.substr(0, 4)}
                        </strong>
                    </Col>
                    <Col lg="4">
                        <Field
                            name={`overlap[${index}].start`}
                            defaultValue={item.start}
                            component={TexttimeInput}
                        />
                    </Col>
                    <Col lg="4">
                        <Field
                            name={`overlap[${index}].end`}
                            defaultValue={item.end}
                            component={TexttimeInput}
                        />
                    </Col>
                    <Col lg="4">
                        <Button
                            color="warning"
                            onClick={() =>
                                setHandlePeriodModal({
                                    show: true,
                                    remove: arrayHelpers.remove,
                                    add: arrayHelpers.push,
                                    data: {
                                        item: {
                                            data: item,
                                            index: index,
                                        },
                                        isOverlap: true,
                                        periods: values.overlap,
                                    },
                                })
                            }
                        >
                            <i className="fas fa-edit"></i>
                        </Button>
                        <Button
                            style={{
                                marginLeft: "10px",
                            }}
                            color="danger"
                            onClick={() => {
                                arrayHelpers.remove(index)
                            }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Formik
                        initialValues={pageData.formDefaultValues}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                    >
                        {({ values }) => (
                            <Form>
                                <Row>
                                    <Col xs="12">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-3">
                                                    Configurações da Agenda
                                                </CardTitle>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <Label>
                                                                Nome da Agenda
                                                            </Label>

                                                            <Field
                                                                placeholder="Nome da Agenda"
                                                                name="name"
                                                                component={
                                                                    TextInput
                                                                }
                                                                defaultValue={
                                                                    values.name
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <Label>
                                                                Unidade
                                                            </Label>
                                                            <Field
                                                                name="unit_id"
                                                                placeholder="Selecionar Unidade"
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={
                                                                    debounceOptionsUnit
                                                                }
                                                                // defaultValue={values.unit_id}
                                                                component={
                                                                    AsyncSelectInput
                                                                }
                                                                isDisabled={
                                                                    id
                                                                        ? true
                                                                        : false
                                                                }
                                                                defaultValue={
                                                                    values.unit_id
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label>
                                                                Profissional
                                                            </Label>

                                                            <Field
                                                                name="professional_id"
                                                                placeholder="Selecionar Profissional"
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={
                                                                    debounceOptionsProfessional
                                                                }
                                                                component={
                                                                    AsyncSelectInput
                                                                }
                                                                isDisabled={
                                                                    id
                                                                        ? true
                                                                        : false
                                                                }
                                                                defaultValue={
                                                                    values.professional_id
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="3" md="2">
                                                        <FormGroup>
                                                            <Label>
                                                                Aceita Encaixe?
                                                            </Label>

                                                            <Field
                                                                name={`accept_fit`}
                                                                id={`book_accept_fit`}
                                                                checked={
                                                                    values.accept_fit
                                                                }
                                                                component={
                                                                    SwitchInput
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="3" md="2">
                                                        <FormGroup>
                                                            <Label>
                                                                Agenda Ativa
                                                            </Label>
                                                            <Field
                                                                name={`status`}
                                                                id={`book_active_status`}
                                                                checked={
                                                                    values.status
                                                                }
                                                                component={
                                                                    SwitchInput
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md="6">
                                                        <Label>
                                                            Observações
                                                        </Label>

                                                        <Field
                                                            name="obs"
                                                            rows={3}
                                                            component={
                                                                TextAreaInput
                                                            }
                                                            maxLength={1000}
                                                            defaultValue={
                                                                values.obs
                                                            }
                                                        />
                                                    </Col>
                                                    {values.accept_fit?(
                                                        <>
                                                        <Col md="3">
                                                        <Label>
                                                            Máximo de Encaixes
                                                        </Label>
                                                        <Field
                                                            name="general_fittings"
                                                            component={
                                                                numberInput
                                                            }
                                                            defaultValue={
                                                                values.general_fittings
                                                            }
                                                        />
                                                    </Col>
                                                    </>
                                                    ):''}
                                                </Row>

                                                {/* fim linha */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6" xl="6">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-2">
                                                    Grade Semanal de Horários
                                                </CardTitle>
                                                <small>
                                                    Defina os horários semanais
                                                </small>
                                                <Row className="mt-2">
                                                    <Col md="6"></Col>
                                                    <Col md="6">
                                                        <Label>
                                                            <strong>
                                                                Data Inicial
                                                            </strong>
                                                        </Label>
                                                        <Field
                                                            name={`periods_start_validity`}
                                                            defaultValue={
                                                                todayOnlyDate
                                                            }
                                                            component={
                                                                TextDateInput
                                                            }
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="mt-2">
                                                    {/* fim item fixo */}
                                                    <Col
                                                        lg="12"
                                                        className="mt-2"
                                                    >
                                                        {/* for(let i=0; i<indicesWeek.length; i++) */}

                                                        {indicesWeek.map(
                                                            (weekday, i) => {
                                                                return (
                                                                    <>
                                                                        <Row>
                                                                            <Col sm="12">
                                                                                {/* <Label>
                                                                                   <strong> Segunda
                                                                                    feira</strong>
                                                                                </Label> */}
                                                                                <Label>
                                                                                    <strong>
                                                                                        {
                                                                                            optionsWeekDay[
                                                                                                i
                                                                                            ]
                                                                                                .label
                                                                                        }
                                                                                    </strong>
                                                                                </Label>
                                                                            </Col>
                                                                        </Row>
                                                                        <FieldArray
                                                                            name={
                                                                                "periods[" +
                                                                                weekday +
                                                                                "]"
                                                                            }
                                                                            render={arrayHelpers => (
                                                                                <div className="mb-4">
                                                                                    {values &&
                                                                                    values
                                                                                        .periods[
                                                                                        weekday
                                                                                    ]
                                                                                        .length >
                                                                                        0
                                                                                        ? values.periods[
                                                                                              weekday
                                                                                          ].map(
                                                                                              (
                                                                                                  item,
                                                                                                  index
                                                                                              ) => (
                                                                                                  <Row
                                                                                                      key={
                                                                                                          index
                                                                                                      }
                                                                                                      className="mb-3 align-items-center"
                                                                                                  >
                                                                                                      <Col lg="4">
                                                                                                          <Field
                                                                                                              name={`periods[${weekday}][${index}].start`}
                                                                                                              defaultValue={
                                                                                                                  item.start
                                                                                                              }
                                                                                                              component={
                                                                                                                  TexttimeInput
                                                                                                              }
                                                                                                          />
                                                                                                      </Col>
                                                                                                      <Col lg="4">
                                                                                                          <Field
                                                                                                              name={`periods[${weekday}][${index}].end`}
                                                                                                              defaultValue={
                                                                                                                  item.end
                                                                                                              }
                                                                                                              component={
                                                                                                                  TexttimeInput
                                                                                                              }
                                                                                                          />
                                                                                                      </Col>
                                                                                                      <Col lg="4">
                                                                                                          <Button
                                                                                                              color="warning"
                                                                                                              onClick={() =>
                                                                                                                  setHandlePeriodModal(
                                                                                                                      {
                                                                                                                          show: true,
                                                                                                                          remove:
                                                                                                                              arrayHelpers.remove,
                                                                                                                          add:
                                                                                                                              arrayHelpers.push,
                                                                                                                          data: {
                                                                                                                              item: {
                                                                                                                                  data: item,
                                                                                                                                  index: index,
                                                                                                                              },
                                                                                                                              weekday: {
                                                                                                                                  label:
                                                                                                                                      optionsWeekDay[
                                                                                                                                          i
                                                                                                                                      ]
                                                                                                                                          .label,
                                                                                                                                  value: weekday,
                                                                                                                              },
                                                                                                                              periods:
                                                                                                                                  values.periods,
                                                                                                                          },
                                                                                                                      }
                                                                                                                  )
                                                                                                              }
                                                                                                          >
                                                                                                              <i className="fas fa-edit"></i>
                                                                                                          </Button>
                                                                                                          <Button
                                                                                                              color="secondary"
                                                                                                              style={{
                                                                                                                  marginLeft:
                                                                                                                      "10px",
                                                                                                              }}                                                        
                                                                                                              onClick={() =>
                                                                                                                  setHandlePeriodModal(
                                                                                                                      {
                                                                                                                          show: true,
                                                                                                                          remove:
                                                                                                                              arrayHelpers.remove,
                                                                                                                          add:
                                                                                                                              arrayHelpers.push,
                                                                                                                          data: {
                                                                                                                              item: {
                                                                                                                                  data: item,
                                                                                                                                  index: index,
                                                                                                                              },
                                                                                                                              weekday: {
                                                                                                                                  label:
                                                                                                                                      optionsWeekDay[
                                                                                                                                          i
                                                                                                                                      ]
                                                                                                                                          .label,
                                                                                                                                  value: weekday,
                                                                                                                              },
                                                                                                                              periods:
                                                                                                                                  values.periods,
                                                                                                                              overlap:
                                                                                                                                  values.overlap,
                                                                                                                              iscopy: true,
                                                                                                                          },
                                                                                                                      }
                                                                                                                  )
                                                                                                              }
                                                                                                          >
                                                                                                              <i className="fas fa-copy"></i>
                                                                                                          </Button>
                                                                                                          <Button
                                                                                                              style={{
                                                                                                                  marginLeft:
                                                                                                                      "10px",
                                                                                                              }}
                                                                                                              color="danger"
                                                                                                              onClick={() => {
                                                                                                                  arrayHelpers.remove(
                                                                                                                      index
                                                                                                                  )
                                                                                                              }}
                                                                                                          >
                                                                                                              <i className="fas fa-trash-alt"></i>
                                                                                                          </Button>
                                                                                                      </Col>
                                                                                                  </Row>
                                                                                              )
                                                                                          )
                                                                                        : "Dia não configurado."}

                                                                                    <Row>
                                                                                        <Col sm="12">
                                                                                            <Button
                                                                                                color="primary"
                                                                                                onClick={() => {
                                                                                                    setHandlePeriodModal(
                                                                                                        {
                                                                                                            show: true,
                                                                                                            remove:
                                                                                                                arrayHelpers.remove,
                                                                                                            add:
                                                                                                                arrayHelpers.push,
                                                                                                            data: {
                                                                                                                item: null,
                                                                                                                weekday: {
                                                                                                                    label:
                                                                                                                        optionsWeekDay[
                                                                                                                            i
                                                                                                                        ]
                                                                                                                            .label,
                                                                                                                    value: weekday,
                                                                                                                },
                                                                                                                field_name: null,
                                                                                                                periods:
                                                                                                                    values.periods,
                                                                                                            },
                                                                                                        }
                                                                                                    )
                                                                                                }}
                                                                                            >
                                                                                                <i className="fas fa-plus"></i>
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/* fim linha */}
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col sm="6" xl="6">
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-2">
                                                    Sobreposições de datas -
                                                    Agenda de dia único
                                                </CardTitle>
                                                <small>
                                                    Adicione datas quando a
                                                    disponibilidade for
                                                    diferente dos horários
                                                    semanais
                                                </small>
                                                <Row className="mt-2 mb-4">
                                                    <Col sm="12">
                                                        <FieldArray
                                                            name="overlap"
                                                            render={arrayHelpers => (
                                                                <div>
                                                                    {values &&
                                                                    values
                                                                        ?.overlap
                                                                        ?.length >
                                                                        0
                                                                        ? values.overlap.map(
                                                                              (
                                                                                  item,
                                                                                  index
                                                                              ) => {
                                                                                  return renderOverlapDate(
                                                                                      index,
                                                                                      item,
                                                                                      values,
                                                                                      arrayHelpers
                                                                                  )
                                                                              }
                                                                          )
                                                                        : null}

                                                                    <Row className="mt-3">
                                                                        <Col sm="12">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    setHandlePeriodModal(
                                                                                        {
                                                                                            show: true,
                                                                                            remove:
                                                                                                arrayHelpers.remove,
                                                                                            add:
                                                                                                arrayHelpers.push,
                                                                                            data: {
                                                                                                item: null,
                                                                                                isOverlap: true,
                                                                                            },
                                                                                        }
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-plus"></i>
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-2">
                                                    Configurações adicionais de
                                                    serviços
                                                    <Button
                                                        color="warning"
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                        onClick={() => {
                                                            setHandleModal({
                                                                show: true,
                                                                data: {
                                                                    periods:
                                                                        values.periods,
                                                                    age:
                                                                        values.periods_age_validity,
                                                                },
                                                            })
                                                        }}
                                                    >
                                                        <i className="fas fa-bolt"></i>
                                                    </Button>
                                                </CardTitle>
                                                <small>
                                                    Se necessário, adicione uma
                                                    idade a um determinado
                                                    serviço
                                                </small>
                                                {/* <Row className="mt-3"> */}
                                                {/* <Col sm="4">
                                                   <Label>Serviço</Label>
                                                    </Col> */}
                                                {/* <Col sm="4">
                                                    <Label>Idade Mínima</Label>
                                                    </Col> */}
                                                {/* </Row> */}

                                                <Row className="mb-3">
                                                    <Col sm="12">
                                                        <FieldArray
                                                            name={
                                                                "periods_age_validity"
                                                            }
                                                            render={arrayHelpers => (
                                                                <>
                                                                    {values &&
                                                                    values
                                                                        ?.periods_age_validity
                                                                        ?.length >
                                                                        0
                                                                        ? values?.periods_age_validity.map(
                                                                              (
                                                                                  item,
                                                                                  index
                                                                              ) => (
                                                                                  <Row
                                                                                      key={
                                                                                          index
                                                                                      }
                                                                                      className="mb-3 align-items-center mt-4"
                                                                                  >
                                                                                      <Col sm="11">
                                                                                          <Label>
                                                                                              Serviço
                                                                                          </Label>
                                                                                          <Field
                                                                                              placeholder="Selecione um serviço"
                                                                                              name={`periods_age_validity[${index}].service_id`}
                                                                                              component={
                                                                                                  AsyncSelectInput
                                                                                              }
                                                                                              cacheOptions
                                                                                              defaultOptions
                                                                                              loadOptions={
                                                                                                  debounceOptionsItems
                                                                                              }
                                                                                              //   defaultValue={
                                                                                              //       item.service_id
                                                                                              //   }
                                                                                          />
                                                                                      </Col>
                                                                                      <Col
                                                                                          sm="1"
                                                                                          className="mt-4"
                                                                                      >
                                                                                          <Button
                                                                                              color="danger"
                                                                                              className="inner"
                                                                                              onClick={() =>
                                                                                                  arrayHelpers.remove(
                                                                                                      index
                                                                                                  )
                                                                                              }
                                                                                          >
                                                                                              <i className="fas fa-trash-alt"></i>
                                                                                          </Button>
                                                                                      </Col>

                                                                                      <Col
                                                                                          sm="3"
                                                                                          className="mt-3"
                                                                                      >
                                                                                          <Label>
                                                                                              Idade
                                                                                              mín.
                                                                                              anos
                                                                                          </Label>
                                                                                          <Field
                                                                                              placeholder="Idade mínima ano"
                                                                                              name={`periods_age_validity[${index}].min_age_years`}
                                                                                              component={
                                                                                                  numberInput
                                                                                              }
                                                                                          />
                                                                                      </Col>
                                                                                      <Col
                                                                                          sm="3"
                                                                                          className="mt-3"
                                                                                      >
                                                                                          <Label>
                                                                                              Idade
                                                                                              mín.
                                                                                              meses
                                                                                          </Label>
                                                                                          <Field
                                                                                              placeholder="Idade mínima meses"
                                                                                              name={`periods_age_validity[${index}].min_age_months`}
                                                                                              component={
                                                                                                  numberInput
                                                                                              }
                                                                                          />
                                                                                      </Col>
                                                                                      <Col
                                                                                          sm="3"
                                                                                          className="mt-3"
                                                                                      >
                                                                                          <Label>
                                                                                              Idade
                                                                                              máx.
                                                                                              anos
                                                                                          </Label>
                                                                                          <Field
                                                                                              placeholder="Idade máxima ano"
                                                                                              name={`periods_age_validity[${index}].max_age_years`}
                                                                                              component={
                                                                                                  numberInput
                                                                                              }
                                                                                          />
                                                                                      </Col>
                                                                                      <Col
                                                                                          sm="3"
                                                                                          className="mt-3"
                                                                                      >
                                                                                          <Label>
                                                                                              Idade
                                                                                              máx.
                                                                                              meses
                                                                                          </Label>
                                                                                          <Field
                                                                                              placeholder="Idade  máxima meses"
                                                                                              name={`periods_age_validity[${index}].max_age_months`}
                                                                                              component={
                                                                                                  numberInput
                                                                                              }
                                                                                          />
                                                                                      </Col>

                                                                                      {/* <Col sm="3">
                                                                                   <Field
                                                                                      placeholder="Informe uma idade"
                                                                                      name={`periods_age_validity[${index}].age`}
                                                                                      component={
                                                                                          numberInput
                                                                                      }

                                                                                    //   defaultValue={
                                                                                    //       item.age
                                                                                    //   }
            
                                                                                  /> 
                                                                              </Col> */}
                                                                                  </Row>
                                                                              )
                                                                          )
                                                                        : null}

                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            arrayHelpers.push(
                                                                                defaultPeriodsAge
                                                                            )
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-plus"></i>
                                                                    </Button>
                                                                </>
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    {/* col vazia para alinhar elementos */}
                                </Row>

                                <div align="end">
                                    {controlState ? (
                                        <Button
                                            color="primary"
                                            type="button"
                                            className="ml-1 "
                                            disabled
                                        >
                                            Salvar{" "}
                                            <i className="fas fa-lock fa-lg"></i>
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            type="submit"
                                            className="ml-1 "
                                        >
                                            Salvar{" "}
                                            <i className="far fa-save  fa-lg"></i>
                                        </Button>
                                    )}

                                    <Link
                                        to="/agendas"
                                        className="btn btn-danger ml-1"
                                    >
                                        Cancelar
                                    </Link>
                                </div>
                                <br />
                            </Form>
                        )}
                    </Formik>

                    <PeriodModal
                        show={handlePeriodModal.show}
                        onClose={() => {
                            setHandlePeriodModal({ show: false, data: null })
                        }}
                        data={handlePeriodModal.data}
                        add={handlePeriodModal.add}
                        remove={handlePeriodModal.remove}
                    />
                    <ModalServerAdd
                        show={handleModalAdd.show}
                        onClose={() => {
                            setHandleModal({ show: false, data: null })
                        }}
                        data={handleModalAdd.data}
                    ></ModalServerAdd>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default RegistrationSchedule
