import logoupload from "assets/images/logoupload.png"
import classnames from "classnames"
import debounce from "debounce-promise"
import FormData from "form-data"
import { FastField, Field, FieldArray, Form, Formik } from "formik"
import ModalLogs from "pages/AuditLogs/LogsModal/Modal"
import React, { useEffect, useMemo, useRef, useState } from "react"
import NumberFormat from "react-number-format"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Modal,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane,
    CardHeader,
    Collapse,
} from "reactstrap"
import { search as countySeacrh } from "services/vitta-core/county"
import { search as searchItems } from "services/vitta-core/item"
import {
    create,
    getNfseStatus,
    loadDataAddform,
    loadDataEditform,
    syncElectronicReceipt,
    syncTaxdocsData,
    update,
    uploadImageProfessional,
    disableSyncElectronicReceipt,
} from "services/vitta-core/professional"
import { update as updateProfessionalBankAccounts } from "services/vitta-core/professional-bank-accounts"
import {
    create as createProfessionalDocuments,
    deleteDocuments as deleteProfessionalDocuments,
    downloadProfessionalDocument,
} from "services/vitta-core/professional-documents"
import { update as updateProfessionalServices } from "services/vitta-core/professional-services"
import { uploadCertificate } from "services/vitta-core/professional-upload-certificate"
import { search as searchSpecialties } from "services/vitta-core/specialty"
import { search as searchTaxServices } from "services/vitta-core/tax-services-lc"
import { search as searchUnits } from "services/vitta-core/unit"
import { search as userServiceSearch } from "services/vitta-core/user"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import "toastr/build/toastr.min.css"
import BANK_CODES_TYPES from "utils/consts/bank-codes-types"
import states from "utils/states"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import PROFESSIONAL_DOCUMENTS_STATUS from "../../../utils/consts/professional-documents-status"
import { transformISODateTODMYWithTime } from "../../../utils/date"
import ModalImage from "./ModalImage/ModalImage"
import ModalInfo from "./ModalInfo/ModalInfo"
import ModalAccountBank from "./ModalAccountBank/ModalAccountBank"
import ACCOUNT_PERSON_TYPES from "utils/consts/account-person-types"
import { v4 as uuidv4 } from "uuid"
import "./style.css"
const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
        isDisabled={props?.disabled}
    />
)

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            if (field.name == "addr_company_state") {
                setFieldValue("addr_company_county_id", null)
            }
            if (field.name == "personal_address_state") {
                setFieldValue("personal_address_count_id", null)
            }
            setFieldValue(field.name, option)
        }}
        ref={props.Ref}
    />
)

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => <Input type="text" disabled={props?.disabled} {...field} {...props} />
const DateInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => <Input type="date" {...field} {...props} />

const FileInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => <Input type="file" {...field} {...props} />

const PasswordInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="password" {...field} {...props} />

const NumberInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        className="form-control"
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)

const optionsStatus = [
    { value: "1", label: "Ativo" },
    { value: "0", label: "Inativo" },
]
const optionsDocuments = [
    {
        label: "Identificação Pessoal",
        value: "identificacao_pessoal",
    },
    {
        label: "Identificação Empresarial",
        value: "identificacao_empresarial",
    },
    {
        label: "Residência Pessoal",
        value: "residencia_pessoal",
    },
    {
        label: "Residência Empresarial",
        value: "residencia_empresarial",
    },
    {
        label: "Atividade Empresarial",
        value: "atividade_empresarial",
    },
]
const optionsRegisterType = [
    { value: "", label: "Selecionar" },
    { value: "CBOO", label: "CBOO" },
    { value: "COREN", label: "COREN" },
    { value: "CRAS", label: "CRAS" },
    { value: "CRBIO", label: "CRBIO" },
    { value: "CREFITO", label: "CREFITO" },
    { value: "CRF", label: "CRF" },
    { value: "CRFA", label: "CRFA" },
    { value: "CRM", label: "CRM" },
    { value: "CRN", label: "CRN" },
    { value: "CRO", label: "CRO" },
    { value: "CRP", label: "CRP" },
    { value: "CRTR", label: "CRTR" },
    { value: "OUTROS", label: "Outros Conselhos" },
]

const optionsWsProd = [
    { value: null, label: "Selecionar" },
    { value: 1, label: "Habilitado" },
    { value: 0, label: "Não Habilitado" },
]

const optionsPartnerType = [
    { value: null, label: "Selecionar" },
    { value: 1, label: "Parceiro de Imagem" }
]

const optionsState = []
states.map(state =>
    optionsState.push({ value: state.sigla, label: state.estado })
)

const defaultProfessionalService = {
    item_id: "",
    tax_cnae: "",
    tax_service_code: "",
    tax_iss_aliquot: "",
}
const defaultDocumentService = {
    type: "",
    file: "",
}
const defaultAccountBankData = {
    bank_code: "",
    account_type: "",
    bank_branch: "",
    bank_account: "",
    account_person_type: "",
}

const defaultProfessionalData = {
    user_id: "",
    status: { value: "1", label: "Ativo" },
    name: "",
    cpf: "",
    email: "",
    phone: "",
    register_type: "",
    register_code: "",
    register_uf: "",
    account_person_type: "",
    services: [defaultProfessionalService],
    servicesDocuments: [defaultDocumentService],
    account_bank_data: [defaultAccountBankData],
    corporate_name: "",
    cnpj: "",
    fantasy_name: "",
    municipal_registration: "",
    tax_regime: "",
    special_tax_regime: "",
    digital_certificate_password: "",
    addr_company_tp_logradouro: "",
    addr_company_logradouro: "",
    addr_company_number: "",
    addr_company_complement: "",
    addr_company_district: "",
    addr_company_county_id: "",
    addr_company_state: "",
    addr_company_cep: "",
    rps_start_number: "",
    rps_start_serie: "",
    // tax_iss_aliquot: "",
    digital_certificate_file: "",
    tax_docs_last_updated_at: null,
    birth_date: "",
}

const RegistrationProfessionals = () => {
    const { id } = useParams()
    const [pageData, setPageData] = useState({
        professional: defaultProfessionalData,
    })
    const User = useSelector(state => state.auth.access.user)
    const [controlState, setControlState] = useState(false)
    const [controlStateDocuments, setControlStateDocuments] = useState(false)

    const [controlModalImage, setControlModalImage] = useState({
        show: false,
        data: null,
    })
    const [controlStateUpload, setControlStateUpload] = useState(false)
    const [modalLogs, setModalLogs] = useState({
        show: false,
        data: null,
    })
    const [modalInfo, setModalInfo] = useState({
        show: false,
        data: null,
    })
    const [modalAccountBank, setModalAccountBank] = useState({
        show: false,
        data: null,
    })
    const [options, setOptions] = useState({
        tax_regime: [],
        special_tax_regime: [],
        tp_logradouro: [],
        personal_address_tp_logradouro: [],
    })

    const [image, setImage] = useState("")
    const [fileDocuments, setFileDocuments] = useState({
        fileData: [],
    })

    const [documentsDelete, setDocumentsDelete] = useState([])
    const stateAddressInputRef = useRef(null)
    const stateAddressPersonalInputRef = useRef(null)

    const dispatch = useDispatch()
    const history = useHistory()

    const [syncTaxdocs, setSyncTaxdocs] = useState({
        syncing: false,
        errorMessage: null,
        successMessage: null,
    })
    const [
        controlSyncElectronicReceipt,
        setControlSyncElectronicReceipt,
    ] = useState(false)

    useEffect(() => {
        if (id) loadDataEditPage()
        else loadDataAddPage()
    }, [id])
    const [tab, setTab] = useState(null)
    const [activeTab, setactiveTab] = useState("1")
    const [activeTabEletronic, setActiveTabEletronic] = useState("1")
    const [
        vittaPaymentsElectronicMode,
        setVittaPaymentsElectronicMode,
    ] = useState(false)
    function toggle(tab) {
        if (activeTab != tab) {
            setactiveTab(tab)
        }
    }
    function HandleTab(index) {
        setTab(index)

        if (tab == index) {
            setTab(null)
        }
    }

    const toggleEletronic = tab => {
        if (activeTabEletronic != tab) setActiveTabEletronic(tab)
    }

    const loadDataEditPage = async () => {
        try {
            let data = await loadDataEditform(id)

            data.professional.result.user_id = data.professional.result.user_id
                ? {
                      value: data.professional.result.user.id,
                      label: data.professional.result.user.name,
                  }
                : null

            for (let i = 0; i < optionsStatus.length; i++) {
                if (optionsStatus[i].value == data.professional.result.status) {
                    data.professional.result.status = optionsStatus[i]
                    break
                }
            }

            for (let i = 0; i < optionsRegisterType.length; i++) {
                if (
                    optionsRegisterType[i].value ==
                    data.professional.result.register_type
                ) {
                    data.professional.result.register_type =
                        optionsRegisterType[i]
                    break
                }
            }

            for (let i = 0; i < optionsState.length; i++) {
                if (
                    optionsState[i].value ==
                    data.professional.result.register_uf
                ) {
                    data.professional.result.register_uf = optionsState[i]
                    break
                }
            }
            for (let j = 0; j < optionsState.length; j++) {
                if (
                    optionsState[j].value ==
                    data.professional.result.addr_company_state
                ) {
                    data.professional.result.addr_company_state =
                        optionsState[j]
                    break
                }
            }
            for (let j = 0; j < optionsState.length; j++) {
                if (optionsState[j].value == data.professional.result.rg_uf) {
                    data.professional.result.rg_uf = optionsState[j]
                    break
                }
            }
            for (let i = 0; i < data?.companyTpLogradouro.length; i++) {
                if (
                    data.companyTpLogradouro[i].value ==
                    data.professional.result.addr_company_tp_logradouro
                ) {
                    data.professional.result.addr_company_tp_logradouro =
                        data.companyTpLogradouro[i]

                    break
                }
            }

            for (let i = 0; i < data?.specialTaxRegimeOptions.length; i++) {
                if (
                    data.specialTaxRegimeOptions[i].value ==
                    data.professional.result.special_tax_regime
                ) {
                    data.professional.result.special_tax_regime =
                        data.specialTaxRegimeOptions[i]
                    break
                }
            }
            for (let i = 0; i < data?.taxRegimeOptions.length; i++) {
                if (
                    data.taxRegimeOptions[i].value ==
                    data.professional.result.tax_regime
                ) {
                    data.professional.result.tax_regime =
                        data.taxRegimeOptions[i]
                    break
                }
            }

            let dataDocuments = []
            if (
                data.professional.result.professional_documents &&
                data.professional.result.professional_documents.length > 0
            ) {
                for (
                    let i = 0;
                    i < data.professional.result.professional_documents.length;
                    i++
                ) {
                    let use = data.professional.result.professional_documents[i]
                    let zoop_status = null
                    if (
                        ACCOUNT_PERSON_TYPES.PHYSICAL_PERSON ==
                        data.professional.result.account_person_type
                    ) {
                        zoop_status = use?.zoop_status_pf
                    } else if (
                        ACCOUNT_PERSON_TYPES.LEGAL_PERSON ==
                        data.professional.result.account_person_type
                    ) {
                        zoop_status = use?.zoop_status_pj
                    }
                    dataDocuments.push({
                        id: use?.id,
                        type: handleStatusDocument(use?.type),
                        file: use?.path,
                        status: use?.status,
                        zoop_status: zoop_status,
                    })
                }
            }
            data.professional.result.servicesDocuments = dataDocuments
            if (data.professional.result.account_person_type) {
                if (data.professional.result.account_person_type == 1) {
                    data.professional.result.account_person_type = {
                        label: "Pessoa Física",
                        value: 1,
                    }
                    data.professional.result.zoop_id =
                        data.professional.result.zoop_id_pf
                    data.professional.result.zoop_status =
                        data.professional.result.zoop_status_pf
                } else if (data.professional.result.account_person_type == 2) {
                    data.professional.result.account_person_type = {
                        label: "Pessoa Jurídica",
                        value: 2,
                    }
                    data.professional.result.zoop_id =
                        data.professional.result.zoop_id_pj
                    data.professional.result.zoop_status =
                        data.professional.result.zoop_status_pj
                }
            }
            let formatedAccountBank = []
            if (data?.professional?.result?.account_bank_data?.length > 0) {
                for (
                    let i = 0;
                    i < data?.professional?.result?.account_bank_data?.length;
                    i++
                ) {
                    let use = data?.professional?.result?.account_bank_data[i]
                    if (use) {
                        let useBank = BANK_CODES_TYPES.find(
                            e => e.code == use.bank_code
                        )
                        use.bank_code = {
                            label: useBank.code + " - " + useBank.name,
                            value: useBank.code,
                        }

                        if (use.account_type == 1) {
                            use.account_type = {
                                label: "Corrente",
                                value: 1,
                            }
                        } else if (use?.account_type == 2) {
                            use.account_type = {
                                label: "Poupança",
                                value: 2,
                            }
                        }
                        if (
                            use?.account_person_type ==
                            ACCOUNT_PERSON_TYPES.PHYSICAL_PERSON
                        ) {
                            use.account_person_type = {
                                label: "Pessoa Física",
                                value: 1,
                            }
                        } else if (
                            use?.account_person_type ==
                            ACCOUNT_PERSON_TYPES.LEGAL_PERSON
                        ) {
                            use.account_person_type = {
                                label: "Pessoa Jurídica",
                                value: 2,
                            }
                        }
                    }

                    formatedAccountBank.push(use)
                }

                data.professional.account_bank_data = formatedAccountBank
            }

            if (data.professional.result?.services?.length) {
                for (
                    let i = 0;
                    i < data.professional.result.services.length;
                    i++
                ) {
                    let useService = data.professional.result.services[i]
                    useService.item_id = {
                        value: useService.item.id,
                        label: useService.item.name,
                    }
                    useService.tax_iss_aliquot =
                        useService.tax_iss_aliquot > 0
                            ? useService.tax_iss_aliquot / 100
                            : 0
                    // useService.tax_service_code = {
                    //     value: useService.taxservice.lc_id,
                    //     label: useService.taxservice.name,
                    // }
                }
            }
            data.professional.result.addr_company_county_id = {
                value: data?.professional.result?.county?.id,
                label: data?.professional.result?.county?.name,
            }
            data.professional.result.personal_address_count_id = {
                value: data?.professional.result?.county_personal?.id,
                label: data?.professional.result?.county_personal?.name,
            }
            data.professional.result.statusGenInvoice =
                data?.professional?.statusGenInvoice

            data.professional.result.register_specialty = {
                label: data?.professional?.result?.specialty?.name,
                value: data?.professional?.result?.specialty?.id,
            }
            if (data.professional.result.personal_address_tp_logradouro) {
                for (let i = 0; i < data?.companyTpLogradouro.length; i++) {
                    if (
                        data.companyTpLogradouro[i].value ==
                        data.professional.result.personal_address_tp_logradouro
                    ) {
                        data.professional.result.personal_address_tp_logradouro =
                            data.companyTpLogradouro[i]

                        break
                    }
                }
            }

            if (data.professional.result.personal_address_state) {
                for (let i = 0; i < optionsState.length; i++) {
                    if (
                        optionsState[i].value ==
                        data.professional.result.personal_address_state
                    ) {
                        data.professional.result.personal_address_state =
                            optionsState[i]
                        break
                    }
                }
            }

            if (
                data?.professional?.result?.digital_certificate_url &&
                data?.professional?.result?.digital_certificate_url != ""
            ) {
                setControlStateUpload(1)
            }
            if (data?.professional?.result?.partner_type) {
                for (let i = 0; i < optionsPartnerType.length; i++) {
                    if (
                        optionsPartnerType[i].value ==
                        data.professional.result.partner_type
                    ) {
                        data.professional.result.partner_type = optionsPartnerType[i]
                        break
                    }
                }
            }
            setOptions({
                tp_logradouro: data?.companyTpLogradouro,
                tax_regime: data?.taxRegimeOptions,
                special_tax_regime: data?.specialTaxRegimeOptions,
            })

            if (
                data?.professional?.result?.picture &&
                data?.professional?.result?.picture != ""
            ) {
                setControlModalImage({
                    show: false,
                    data: data?.professional?.result?.picture,
                })
            }
            if (
                data?.professional?.result?.ws_prod_is_active &&
                data?.professional?.result?.ws_prod_is_active == 1
            ) {
                data.professional.result.ws_prod_is_active = {
                    value: 1,
                    label: "Habilitado",
                }
            } else if (data?.professional?.result?.ws_prod_is_active == 0) {
                data.professional.result.ws_prod_is_active = {
                    value: 0,
                    label: "Não Habilitado",
                }
            }

            setPageData({
                professional: data.professional.result,
            })
        } catch (e) {
            console.log(e)
        }
    }

    const handleSyncProfessional = async id => {
        try {
            setSyncTaxdocs({
                ...syncTaxdocs,
                errorMessage: null,
                successMessage: null,
                syncing: true,
            })

            const result = await syncTaxdocsData(id)

            const lastUpdatedAt = result.lastUpdatedAt

            setSyncTaxdocs({
                ...syncTaxdocs,
                errorMessage: null,
                successMessage: "Sincronização realizada com sucesso!",
                syncing: false,
            })

            setPageData({
                professional: {
                    ...pageData.professional,
                    tax_docs_updated_at: lastUpdatedAt,
                },
            })
        } catch (e) {
            console.error(e)
            let error = e?.response?.data

            let errorMessage =
                "Ocorreu um erro ao tentar sincronizar o certificado."

            if (error && error.error_message) {
                errorMessage = error.error_message
            }

            setSyncTaxdocs({
                ...syncTaxdocs,
                errorMessage: errorMessage,
                successMessage: null,
                syncing: false,
            })
        }
    }

    const loadDataAddPage = async () => {
        try {
            let data = await loadDataAddform()
            setOptions({
                tp_logradouro: data.companyTpLogradouro,
                tax_regime: data.taxRegimeOptions,
                special_tax_regime: data.specialTaxRegimeOptions,
            })
        } catch (e) {
            console.log(e)
        }
    }

    const handleCep = async values => {
        try {
            let dataCep = document.getElementById("cep").value

            if (
                !dataCep ||
                isNaN(dataCep) === true ||
                dataCep.toString().length < 8
            ) {
                return
            }
            const url = "https://viacep.com.br/ws/" + dataCep + "/json/"

            let data = await fetch(url)
            const endereco = await data.json()

            preencher(endereco, values)
        } catch (e) {
            console.log(e)
        }
    }
    const handleCepPersonal = async values => {
        try {
            let dataCep = document.getElementById("personalCep").value

            if (
                !dataCep ||
                isNaN(dataCep) === true ||
                dataCep.toString().length < 8
            ) {
                return
            }
            const url = "https://viacep.com.br/ws/" + dataCep + "/json/"

            let data = await fetch(url)
            const endereco = await data.json()

            preencher(endereco, values)
        } catch (e) {
            console.log(e)
        }
    }

    const preencher = async (data, values) => {
        try {
            let response = await countySeacrh({ ibge_code: data.ibge })
            let state = null
            for (let i = 0; i < optionsState.length; i++) {
                if (optionsState[i].value == data?.uf) {
                    state = optionsState[i]
                    break
                }
            }

            values.addr_company_logradouro = data?.logradouro
                ? data?.logradouro
                : ""
            values.addr_company_district = data?.bairro ? data?.bairro : ""
            values.addr_company_state = state ? state : ""
            values.addr_company_county_id = {
                label: response?.name ? response?.name : "",
                value: response?.id ? response?.id : "",
            }
            values.addr_company_complement = data?.complemento
                ? data?.complemento
                : ""

            setPageData({
                professional: values,
            })
        } catch (e) {
            console.log(e)
        }
    }

    const loadCountyInputOptions = async term => {
        return new Promise(async (resolve, reject) => {
            try {
                if (
                    !stateAddressInputRef ||
                    !stateAddressInputRef.current?.props?.value?.value
                )
                    return resolve([])

                //console.log("loading loadCountyInputOptions", stateAddressInputRef.current, stateAddressInputRef.current.value);

                let response = await countySeacrh(
                    {
                        state:
                            stateAddressInputRef.current?.props?.value?.value,
                        term: term,
                    },
                    1,
                    20
                )

                if (!response) return

                let counties_value = []
                response.forEach(element => {
                    counties_value.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(counties_value)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsCounty = debounce(loadCountyInputOptions, 600)
    const loadCountyPersonalInputOptions = async term => {
        return new Promise(async (resolve, reject) => {
            try {
                if (
                    !stateAddressPersonalInputRef ||
                    !stateAddressPersonalInputRef.current?.props?.value?.value
                )
                    return resolve([])

                //console.log("loading loadCountyInputOptions", stateAddressInputRef.current, stateAddressInputRef.current.value);

                let response = await countySeacrh(
                    {
                        state:
                            stateAddressPersonalInputRef.current?.props?.value
                                ?.value,
                        term: term,
                    },
                    1,
                    20
                )

                if (!response) return

                let counties_value = []
                response.forEach(element => {
                    counties_value.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(counties_value)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceCountyPersonalOptionsCounty = debounce(
        loadCountyPersonalInputOptions,
        600
    )
    const handleSaveServicesAndTax = async (id, values) => {
        try {
            setControlState(true)
            let dataSend = JSON.parse(JSON.stringify(values))
            // let itemsToCreate = []
            let itemsToUpdate = []

            if (dataSend?.length > 0) {
                for (let i = 0; i < dataSend.length; i++) {
                    let useDataService = dataSend[i]
                    useDataService.tax_iss_aliquot =
                        useDataService.tax_iss_aliquot * 100

                    if (useDataService.item_id) {
                        if (!useDataService.item_id.value) {
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Ops... ",
                                    text:
                                        "Campo Serviço é obrigatórios para cadastrar um serviço prestado.",
                                    type: "warning",
                                })
                            )
                            return
                        }
                        useDataService.item_id = useDataService.item_id.value
                    }

                    itemsToUpdate.push(useDataService)
                }
            }

            await updateProfessionalServices(id, itemsToUpdate)
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            setControlState(false)
        } catch (e) {
            console.log(e)
            setControlState(false)
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }
    const handleSaveDataBank = async (id, values) => {
        try {
            setControlState(true)
            let data = JSON.parse(JSON.stringify(values))
            let objSend = {
                bank_account: data?.bank_account,
                bank_branch: data?.bank_branch,
                professional_id: id,
            }
            // if (data?.account_person_type)
            //     objSend.account_person_type = data?.account_person_type?.value
            if (data?.bank_code) objSend.bank_code = data?.bank_code?.value
            if (data?.account_type)
                objSend.account_type = data?.account_type?.value

            await updateProfessionalBankAccounts(id, objSend)
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            setControlState(false)
        } catch (e) {
            console.log(e)
            setControlState(false)
            if (e?.response?.data?.error_code == "cpf_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "CPF inválido!",
                        text: 'Preencha um "CPF" válido e tente novamente!',
                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "cnpj_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "CNPJ inválido!",
                        text: 'Preencha um "CNPJ" válido e tente novamente!',
                        type: "error",
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }
    const handleDocuments = async (id, values) => {
        try {
            setControlStateDocuments(true)
            let dataValues = JSON.parse(JSON.stringify(values))
            if (id && dataValues) {
                if (values.id) {
                    return
                }
                if (
                    values.type == "" ||
                    !values.type ||
                    !values?.file ||
                    values?.file == ""
                ) {
                    dispatch(
                        showSweetAlertDialog({
                            title: 'É necessário preencher "Tipo" e "Arquivo"!',
                            type: "error",
                        })
                    )
                    setControlStateDocuments(false)
                    return
                }

                let formData = new FormData()
                formData.append("file", values?.file, values?.file?.name)

                formData.append("data", values?.type?.value)

                await createProfessionalDocuments(
                    id,
                    formData,
                    vittaPaymentsElectronicMode
                )
            }

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            loadDataEditPage()
            setControlStateDocuments(false)
        } catch (e) {
            console.log(e)
            setControlStateDocuments(false)
            if (e?.response?.data?.error_code == "document_existent") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Documento já adicionado!",
                        type: "error",
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }
    const renderStatusDocument = status => {
        let text = ""
        let badgeClass = "badge-soft-success"
        switch (status) {
            case PROFESSIONAL_DOCUMENTS_STATUS.PENDING_ANALYSIS:
                text = "Análise Pendente"
                badgeClass = "badge-soft-info"
                break
            case PROFESSIONAL_DOCUMENTS_STATUS.UNDER_ANALYSIS:
                text = "Em Análise"
                badgeClass = "badge-soft-info"
                break
            case PROFESSIONAL_DOCUMENTS_STATUS.VALID:
                text = "Validado"
                badgeClass = "badge-soft-success"
                break
            case PROFESSIONAL_DOCUMENTS_STATUS.INVALID:
                text = "Inválido"
                badgeClass = "badge-soft-danger"
                break
        }

        return (
            <Badge className={"font-size-14 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }
    const renderStatusProfessionalElectronicReceipt = status => {
        let text = ""
        let badgeClass = "badge-soft-success"
        switch (status) {
            case "pending":
                text = "Pendente"
                badgeClass = "badge-soft-info"
                break
            case "new":
                text = "Novo"
                badgeClass = "badge-soft-primary"
                break
            case "expired":
                text = "Expirado"
                badgeClass = "badge-soft-danger"
                break
            case "action_required":
                text = "Ação requerida"
                badgeClass = "badge-soft-primary"
                break
            case "enabled":
                text = "Ativado"
                badgeClass = "badge-soft-success"
                break
            case "active":
                text = "Ativo"
                badgeClass = "badge-soft-success"
                break
            case "disabled":
                text = "Desativado"
                badgeClass = "badge-soft-danger"
                break
            case "deleted":
                text = "Deletado"
                badgeClass = "badge-soft-danger"
                break
            case "denied":
                text = "Rejeitado"
                badgeClass = "badge-soft-danger"
                break
            default:
                text = "Não sincronizado"
                badgeClass = "badge-soft-danger"
                break
        }

        return (
            <Badge className={"font-size-13 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }
    const renderStatusProfessionalElectronicReceiptVittaPag = values => {
        let text = ""
        let badgeClass = "badge-soft-success"
        let status = ""
        if (values?.account_person_type?.value == 1) {
            status = values?.vittapag_status_pf
        } else {
            status = values?.vittapag_status_pj
        }
        switch (status) {
            case "PENDING":
                text = "Pendente"
                badgeClass = "badge-soft-info"
                break
            case "NEW":
                text = "Novo"
                badgeClass = "badge-soft-primary"
                break
            case "EXPIRED":
                text = "Expirado"
                badgeClass = "badge-soft-danger"
                break
            case "ACTION_REQUIRED":
                text = "Ação requerida"
                badgeClass = "badge-soft-primary"
                break
            case "ENABLED":
                text = "Ativado"
                badgeClass = "badge-soft-success"
                break
            case "ACTIVE":
                text = "Ativo"
                badgeClass = "badge-soft-success"
                break
            case "DISABLED":
                text = "Desativado"
                badgeClass = "badge-soft-danger"
                break
            case "DELETED":
                text = "Deletado"
                badgeClass = "badge-soft-danger"
                break
            case "DENIED":
                text = "Rejeitado"
                badgeClass = "badge-soft-danger"
                break
            default:
                text = "Não sincronizado"
                badgeClass = "badge-soft-danger"
                break
        }

        return (
            <Badge className={"font-size-13 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }
    const renderLastSync = lastSync => {
        if (!lastSync) {
            return ""
        }
        const date = new Date(lastSync)
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        let dt = date.getDate()

        if (dt < 10) {
            dt = "0" + dt
        }
        if (month < 10) {
            month = "0" + month
        }

        const dateStr = `${dt}/${month}/${year}`

        let hours = date.getHours()
        let minutes = date.getMinutes()

        if (hours < 10) hours = "0" + hours

        if (minutes < 10) minutes = "0" + minutes

        const hoursStr = `${hours}:${minutes}`

        return (
            <Badge className={"font-size-12 info"} pill>
                {`Última tentativa de sincronização: ${dateStr} às ${hoursStr}`}
            </Badge>
        )
    }
    const handleStatusDocument = status => {
        let data = null
        switch (status) {
            case "identificacao_pessoal":
                data = {
                    label: "Identificação Pessoal",
                    value: "identificacao_pessoal",
                }
                break
            case "identificacao_empresarial":
                data = {
                    label: "Identificação Empresarial",
                    value: "identificacao_empresarial",
                }
                break
            case "residencia_pessoal":
                data = {
                    label: "Residência Pessoal",
                    value: "residencia_pessoal",
                }
                break
            case "residencia_empresarial":
                data = {
                    label: "Residência Empresarial",
                    value: "residencia_empresarial",
                }
                break
            case "atividade_empresarial":
                data = {
                    label: "Atividade Empresarial",
                    value: "atividade_empresarial",
                }
                break
        }

        return data
    }
    const handleSubmit = async formData => {
        //todo, adicionar validações nos campos
        setControlState(true)

        try {

            let data = JSON.parse(JSON.stringify(formData)) //Remove refs
            
            if (data.account_person_type)
                data.account_person_type = data.account_person_type.value
            if (data.account_type) data.account_type = data.account_type.value
            if (data.personal_address_count_id)
                data.personal_address_count_id =
                    data.personal_address_count_id.value
            if (data.personal_address_state)
                data.personal_address_state = data.personal_address_state.value
            if (data.personal_address_tp_logradouro)
                data.personal_address_tp_logradouro =
                    data.personal_address_tp_logradouro.value
            if (data.user_id) data.user_id = data.user_id.value
            if (data.status) data.status = data.status.value
            if (data.register_type)
                data.register_type = data.register_type.value
            if (data.register_uf) data.register_uf = data.register_uf.value
            if (data.addr_company_county_id)
                data.addr_company_county_id = data.addr_company_county_id.value
            if (data.tax_regime) data.tax_regime = data.tax_regime.value
            if (data.special_tax_regime)
                data.special_tax_regime = data.special_tax_regime.value
            if (data.addr_company_state)
                data.addr_company_state = data.addr_company_state.value
            if (data.addr_company_tp_logradouro)
                data.addr_company_tp_logradouro =
                    data.addr_company_tp_logradouro.value
            if (data?.partner_type) data.partner_type = data.partner_type.value
            if (data?.ws_prod_is_active)
                data.ws_prod_is_active = data.ws_prod_is_active.value
            if (data?.rg_uf) data.rg_uf = data.rg_uf.value
            if (data.services?.length) {
                for (let i = 0; i < data.services.length; i++) {
                    let useDataService = data.services[i]
                    useDataService.tax_iss_aliquot =
                        useDataService.tax_iss_aliquot * 100

                    if (useDataService.item_id || useDataService.unit_id) {
                        if (!useDataService.item_id?.value) {
                            return dispatch(
                                showSweetAlertDialog({
                                    title: "Ops... ",
                                    text:
                                        "Campo Serviço é obrigatórios para cadastrar um serviço prestado.",
                                    type: "warning",
                                })
                            )
                        }
                        useDataService.item_id = useDataService.item_id.value
                    }
                }
            }
            if (
                !data?.personal_address_tp_logradouro &&
                data?.personal_address_logradouro
            ) {
                setControlState(false)
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            'Preencha o campo "Tipo Logradouro" em Dados Pessoais para prosseguir...',
                        type: "error",
                    })
                )

                return
            }
            if (
                !data?.addr_company_tp_logradouro &&
                data?.addr_company_logradouro
            ) {
                setControlState(false)
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            'Preencha o campo "Tipo Logradouro" em Dados empresariais para prosseguir...',
                        type: "error",
                    })
                )

                return
            }

            let professional_id

            data.specialty_id = data?.register_specialty
                ? data?.register_specialty?.value
                : null

            if (id) {
                await update(id, data)
                professional_id = id
            } else {
                const response = await create(data)
                professional_id = response.id
            }

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            setControlState(false)

            if (id) {
                setSyncTaxdocs({
                    errorMessage: null,
                    syncing: false,
                    successMessage: null,
                })

                loadDataEditPage()
                // const status = await updateNfseStatus(id)

                // setControlStateUpload(1)

                // setPageData({
                //     professional: {
                //         ...pageData.professional,
                //         statusGenInvoice: status,
                //         updated_at: new Date().toISOString(),
                //     },
                // })
            } else {
                history.push("/profissionais/editar/" + professional_id)
            }
        } catch (e) {
            console.log(e)
            setControlState(false)
            if (
                e?.response?.data?.error_code ==
                "need_disable_electronic_receipt_cnpj"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            'Para alterar o "CNPJ" desative a sincronização de recebimento eletrônico!',
                        type: "error",
                    })
                )
                return
            }
            if (
                e?.response?.data?.error_code ==
                "need_disable_electronic_receipt_cpf"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            'Para alterar o "CPF" desative a sincronização de recebimento eletrônico!',
                        text: "",
                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "missing_name") {
                dispatch(
                    showSweetAlertDialog({
                        title: "É obrigatório preencher o campo 'nome' !  ",
                        text: "",

                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.code == "resource_already_created") {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            "Profissional já criado com esses dados. Insira um novo CPF.",
                        text: "",

                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "user_already_linked") {
                dispatch(
                    showSweetAlertDialog({
                        title:
                            "Já existe um profissional vinculado a esse usuário!",
                        text: "Verifique o usuário e tente novamente.",

                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "cpf_required") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Digite um CPF!",

                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "cpf_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "CPF inválido!",
                        text: 'Preencha um "CPF" válido e tente novamente!',
                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "cnpj_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "CNPJ inválido!",
                        text: 'Preencha um "CNPJ" válido e tente novamente!',
                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "missing_cpf") {
                dispatch(
                    showSweetAlertDialog({
                        title: "É obrigatório preencher o campo 'CPF' !  ",
                        text: "",

                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "cep_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "CEP INVÁILIDO!  ",
                        text:
                            "Verifique se o CEP informado está correto e tente novamente.",

                        type: "error",
                    })
                )
                return
            }
            if (
                e?.response?.data?.error_code == "missing_account_person_type"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: 'Campo "Contrato Vittá" inválido!',
                        text:
                            'Preencha o campo "Contrato Vittá" e tente novamente.',

                        type: "error",
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
        }
    }

    const loadUserInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await userServiceSearch(
                    { term: inputValue },
                    1,
                    25
                )

                let users_values = []
                response.data.forEach(element => {
                    users_values.push({
                        label:
                            `${element.id}-` +
                            element.name +
                            `<${element.email}>`,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadItemsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await searchItems(
                    {
                        term: inputValue,
                        type: process.env.REACT_APP_ITEM_SERVICE_TYPE,
                    },
                    1,
                    25
                )

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadUnitsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await searchUnits({ term: inputValue }, 1, 25)

                let items_values = []
                response.data.forEach(element => {
                    items_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const loadTaxServicesInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await searchTaxServices(
                    { term: inputValue },
                    1,
                    25
                )

                let tax_values = []
                response.data.forEach(element => {
                    tax_values.push({
                        label: element.name,
                        value: element.lc_id,
                    })
                })

                return resolve(tax_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const loadSpecialtyOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await searchSpecialties(
                    { term: inputValue },
                    1,
                    25
                )

                let values = []
                response.data.forEach(element => {
                    values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsSpecialty = debounce(loadSpecialtyOptions, 600)
    const debounceOptionsUnit = debounce(loadUnitsInputOptions, 600)
    const debounceOptionsUser = debounce(loadUserInputOptions, 600)
    const debounceOptionsItems = debounce(loadItemsInputOptions, 600)
    const debounceOptionsTaxServices = debounce(
        loadTaxServicesInputOptions,
        600
    )

    const updateNfseStatus = async id => {
        try {
            const status = await getNfseStatus(id)

            return status

            console.log("status", status)

            setPageData({
                ...pageData,
                professional: {
                    ...pageData.professional,
                    statusGenInvoice: status,
                },
            })
        } catch (e) {
            console.error(e)
            return "insufficient"
        }
    }

    const handleCertificate = async values => {
        try {
            let data = { ...values }
            let record = null
            const formData = new FormData()
            if (image && image.name && values?.digital_certificate_password) {
                formData.append("image", image, image.name)

                formData.append("data", values?.digital_certificate_password)

                record = await uploadCertificate(id, formData)
            } else {
                dispatch(
                    showSweetAlertDialog({
                        title: "Preencher Campos !",
                        text: "Preencha os dois campos do certificado.",
                        type: "error",
                    })
                )
                return
            }

            data.digital_certificate_url = record?.digital_certificate_url

            const status = await updateNfseStatus(data.id)

            data.statusGenInvoice = status

            setControlStateUpload(1)

            setPageData({
                professional: data,
            })

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registros salvos com Sucesso!",
                    type: "success",
                })
            )
        } catch (e) {
            if (e && e.response && e.response.data) {
                const error = e.response.data

                const errorMessage = error?.error_message

                dispatch(
                    showSweetAlertDialog({
                        title: errorMessage,
                        text: "Verifique o arquivo e tente novamente.",
                        type: "error",
                    })
                )
            } else {
                dispatch(
                    showSweetAlertDialog({
                        title: "Ops... ",
                        text:
                            "Houve um erro ao processar sua solicitação, verifique o formato do arquivo enviado ou tente novamente mais tarde.",
                        type: "error",
                    })
                )
            }
        }
    }

    const SyncTaxdocsButton = ({ disabled }) => {
        if (syncTaxdocs.syncing) {
            return <Spinner>Loading...</Spinner>
        }

        return (
            <Button
                type="button"
                color="success"
                onClick={() => handleSyncProfessional(id)}
                style={{
                    marginTop: 10,
                }}
                disabled={disabled}
            >
                Sincronizar dados NFSe
            </Button>
        )
    }

    const SyncTaxdocsLastUpdatedAt = ({ lastUpdatedAt }) => {
        if (!lastUpdatedAt) return <></>

        return (
            <div style={{ marginTop: 15 }}>
                <span style={{ fontSize: 11 }}>
                    Última sincronização realizada em:{" "}
                    {transformISODateTODMYWithTime(lastUpdatedAt)}
                </span>
            </div>
        )
    }

    const LastProfessionalUpdatedAt = ({ lastUpdatedAt }) => {
        if (!lastUpdatedAt) return <></>

        return (
            <div style={{ marginTop: 5 }}>
                <span style={{ fontSize: 11 }}>
                    Última atualização do profissional realizada em:{" "}
                    {transformISODateTODMYWithTime(lastUpdatedAt)}
                </span>
            </div>
        )
    }

    const TaxdocsNeedToSyncMessage = ({
        lastTaxdocsUpdate,
        lastProfessionalUpdate,
    }) => {
        if (!lastTaxdocsUpdate || !lastProfessionalUpdate) {
            return <></>
        }

        const date1 = new Date(lastTaxdocsUpdate)
        const date2 = new Date(lastProfessionalUpdate)

        if (date1.getTime() < date2.getTime()) {
            return (
                <div style={{ marginTop: 5 }}>
                    {" "}
                    <span style={{ fontSize: 12, color: "red" }}>
                        Os dados de emissão de NFSe deste profissional podem
                        estar desatualizados. Realize a sincronização para
                        mantê-los atualizados.
                    </span>
                </div>
            )
        }

        return <></>
    }

    const SyncTaxdocsStatusDataMessage = ({ status }) => {
        let statusComponent = <></>

        switch (status) {
            case "available":
                statusComponent = (
                    <Badge
                        className="badge-soft-success"
                        style={{
                            fontSize: "13px",
                        }}
                    >
                        Emissão NFSe : Disponível{" "}
                        <i className="fas fa-check ml-1 fa-lg"></i>
                    </Badge>
                )
                break

            case "ready_for_sync":
                statusComponent = (
                    <Badge
                        className="badge-soft-primary"
                        style={{
                            fontSize: "13px",
                        }}
                    >
                        Emissão NFSe : Pronto para sincronizar{" "}
                        <i className="fas fa-check ml-1 fa-lg"></i>
                    </Badge>
                )
                break

            case "insufficient":
                statusComponent = (
                    <Badge
                        className="badge-soft-danger"
                        style={{
                            fontSize: "13px",
                        }}
                    >
                        Emissão NFSe : Dados Insuficientes{" "}
                        <i className="fas fa-exclamation-triangle ml-1 fa-lg"></i>
                    </Badge>
                )
                break
        }
        return (
            <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Col sm="3">{statusComponent}</Col>
            </Row>
        )
    }

    const SyncTaxdocsMessage = () => {
        if (syncTaxdocs.syncing) return <></>

        if (syncTaxdocs.successMessage && syncTaxdocs.successMessage.length)
            return (
                <span style={{ fontWeight: "bold", color: "green" }}>
                    {syncTaxdocs.successMessage}
                </span>
            )

        if (syncTaxdocs.errorMessage && syncTaxdocs.errorMessage.length)
            return (
                <span style={{ fontWeight: "bold", color: "red" }}>
                    {syncTaxdocs.errorMessage}
                </span>
            )

        return <></>
    }

    const SyncingModal = () => {
        return <Modal></Modal>
    }

    const controlPermissionViewLogs = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "view_audit_logs") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return "view_audit_logs"
        } else {
            return ""
        }
    }, [User])
    const controlPermissionDataElectronicReceipt = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "prof_electronic_payment_manage") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return true
        } else {
            return false
        }
    }, [User])

    const handleDownloadDocument = async id => {
        try {
            setControlStateDocuments(true)
            dispatch(
                showToast({
                    title: "Baixando Documento",
                    text: "Aguarde...",
                    type: "info",
                })
            )
            let response = await downloadProfessionalDocument(
                id,
                vittaPaymentsElectronicMode
            )
            if (!response || !response.url) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Arquivo não encontrado!",
                        type: "error",
                    })
                )
                setControlStateDocuments(false)
                return
            }

            window.open(response.url, "_blank")
            setControlStateDocuments(false)
        } catch (e) {
            setControlStateDocuments(false)
            console.log(e)
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde.",
                    type: "error",
                })
            )
        }
    }
    const handleSyncElectronicReceipt = async entity_to_sync => {
        try {
            setControlSyncElectronicReceipt(true)
            await syncElectronicReceipt(id, entity_to_sync)
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Ação concluída.",
                    type: "success",
                })
            )
            setControlSyncElectronicReceipt(false)
            loadDataEditPage()
        } catch (e) {
            console.log(e)
            setControlSyncElectronicReceipt(false)
            if (e?.response?.data?.error_code == "general_data_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Dados gerais inválidos!",
                        text:
                            "É necessário preencher todos os campos do cadastro.",
                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "bank_details_invalid") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Dados bancários inválidos!",
                        text:
                            "É necessário preencher todos os campos de dados bancários.",
                        type: "error",
                    })
                )
                return
            }
            if (
                e?.response?.data?.error_code == "professional_document_invalid"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Documentos inválidos.",
                        text:
                            "É necessário adicionar documentos referentes ao profissional.",
                        type: "error",
                    })
                )
                return
            }
            if (e?.response?.data?.error_code == "invalid_bank_code") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Banco inválido.",
                        text: `Não é possível utilizar o banco escolhido na conta bancária ${
                            pageData?.professional?.account_person_type &&
                            pageData?.professional?.account_person_type ==
                                ACCOUNT_PERSON_TYPES.PHYSICAL_PERSON
                                ? "PF"
                                : "PJ"
                        }.`,
                        type: "error",
                    })
                )
                return
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde.",
                    type: "error",
                })
            )
        }
    }
    const confirmDisableSyncElectronicReceipt = entity => {
        dispatch(
            showSweetAlertDialog({
                title: "Tem certeza que deseja desativar? ",
                text:
                    "Após desativar o recebimento eletrônico, o profissional ficará impossibilitado de receber até ser sincronizado novamente.",
                type: "warning",
                onConfirmAction: () => {
                    handleDisableSyncElectronicReceipt(entity)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const handleDisableSyncElectronicReceipt = async entity => {
        try {
            setControlSyncElectronicReceipt(true)
            await disableSyncElectronicReceipt(id, entity)
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Ação concluída.",
                    type: "success",
                })
            )
            loadDataEditPage()
            setControlSyncElectronicReceipt(false)
        } catch (e) {
            console.log(e)
            setControlSyncElectronicReceipt(false)

            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, verifique o formato do arquivo enviado ou tente novamente mais tarde.",
                    type: "error",
                })
            )
        }
    }

    const handleShowBankAccounts = (
        bank_accounts,
        vitta_payment_electronic_mode
    ) => {
        return bank_accounts
    }

    const handleShowDocuments = (documents, vitta_payment_electronic_mode) => {
        return documents
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Profissionais"
                        breadcrumbItem="Cadastro de profissionais"
                    />

                    <Formik
                        initialValues={pageData.professional}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                    >
                        {({ values, dirty, setFieldValue }) => {
                            const isTaxDocsButtonEnabled =
                                values.statusGenInvoice !== "insufficient"
                            return (
                                <Form>
                                    <Row>
                                        <Col xs="12">
                                            <Card className="shadow-sm  bg-white rounded">
                                                <CardBody>
                                                    <Nav
                                                        tabs
                                                        className="nav-tabs-custom "
                                                    >
                                                        <NavItem>
                                                            <NavLink
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                }}
                                                                className={classnames(
                                                                    {
                                                                        active:
                                                                            activeTab ===
                                                                            "1",
                                                                    }
                                                                )}
                                                                onClick={() => {
                                                                    toggle("1")
                                                                }}
                                                            >
                                                                Dados Gerais
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                }}
                                                                className={classnames(
                                                                    {
                                                                        active:
                                                                            activeTab ===
                                                                            "2",
                                                                    }
                                                                )}
                                                                onClick={() => {
                                                                    toggle("2")
                                                                }}
                                                            >
                                                                Dados
                                                                Empresariais
                                                            </NavLink>
                                                        </NavItem>
                                                        {id ? (
                                                            <NavItem>
                                                                <NavLink
                                                                    style={{
                                                                        cursor:
                                                                            "pointer",
                                                                    }}
                                                                    className={classnames(
                                                                        {
                                                                            active:
                                                                                activeTab ===
                                                                                "3",
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        toggle(
                                                                            "3"
                                                                        )
                                                                    }}
                                                                >
                                                                    Serviços e
                                                                    Fiscal
                                                                </NavLink>
                                                            </NavItem>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {id &&
                                                        controlPermissionDataElectronicReceipt ? (
                                                            <NavItem>
                                                                <NavLink
                                                                    style={{
                                                                        cursor:
                                                                            "pointer",
                                                                    }}
                                                                    className={classnames(
                                                                        {
                                                                            active:
                                                                                activeTab ===
                                                                                "4",
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        toggle(
                                                                            "4"
                                                                        )
                                                                    }}
                                                                >
                                                                    Dados
                                                                    Bancários
                                                                </NavLink>
                                                            </NavItem>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {id &&
                                                        controlPermissionDataElectronicReceipt ? (
                                                            <NavItem>
                                                                <NavLink
                                                                    style={{
                                                                        cursor:
                                                                            "pointer",
                                                                    }}
                                                                    className={classnames(
                                                                        {
                                                                            active:
                                                                                activeTab ===
                                                                                "5",
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        toggle(
                                                                            "5"
                                                                        )
                                                                    }}
                                                                >
                                                                    Documentos
                                                                </NavLink>
                                                            </NavItem>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                                left: "30px",
                                                            }}
                                                        >
                                                            <div className="custom-control custom-switch custom-switch-sm">
                                                                <input
                                                                    id="vittapaymentscheckbox"
                                                                    type="checkbox"
                                                                    className="custom-control-input checkbox"
                                                                    checked={
                                                                        vittaPaymentsElectronicMode
                                                                    }
                                                                    onChange={() => {
                                                                        setVittaPaymentsElectronicMode(
                                                                            !vittaPaymentsElectronicMode
                                                                        )
                                                                        if (
                                                                            vittaPaymentsElectronicMode
                                                                        ) {
                                                                            setActiveTabEletronic(
                                                                                "1"
                                                                            )
                                                                        } else {
                                                                            setActiveTabEletronic(
                                                                                "2"
                                                                            )
                                                                        }
                                                                    }}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="vittapaymentscheckbox"
                                                                >
                                                                    <small>
                                                                        {" "}
                                                                        <strong
                                                                            className="text-info"
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                top:
                                                                                    "-1px",
                                                                            }}
                                                                        >
                                                                            Vittá
                                                                            Pagamentos
                                                                        </strong>
                                                                    </small>
                                                                </label>
                                                            </div>
                                                        </div> */}
                                                    </Nav>
                                                </CardBody>
                                            </Card>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1">
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <CardTitle>
                                                                        {" "}
                                                                        Dados
                                                                        gerais
                                                                    </CardTitle>
                                                                    <CardSubtitle className="mb-3">
                                                                        Informe
                                                                        dados
                                                                        sobre o
                                                                        profissional
                                                                    </CardSubtitle>
                                                                </Col>
                                                                <Col
                                                                    sm="6"
                                                                    align="end"
                                                                >
                                                                    {controlPermissionViewLogs &&
                                                                    controlPermissionViewLogs !=
                                                                        "" ? (
                                                                        <Button
                                                                            type="button"
                                                                            color="info"
                                                                            onClick={() => {
                                                                                const data = {
                                                                                    entity:
                                                                                        "professional",
                                                                                    entity_id: id,
                                                                                }

                                                                                setModalLogs(
                                                                                    {
                                                                                        show: true,
                                                                                        data: data,
                                                                                    }
                                                                                )
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            <i className="fas fa-book fa-lg text-white"></i>
                                                                        </Button>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                {id ? (
                                                                    <Col
                                                                        sm="2"
                                                                        align="start"
                                                                    >
                                                                        <div className="form-group col-12 mw-20 hover-zoom ">
                                                                            <div className="card-picture d-flex justify-content-center align-items-center ">
                                                                                <img
                                                                                    style={{
                                                                                        cursor:
                                                                                            "pointer",
                                                                                    }}
                                                                                    src={
                                                                                        controlModalImage?.data
                                                                                            ? controlModalImage?.data
                                                                                            : logoupload
                                                                                    }
                                                                                    id="img-doctor"
                                                                                    alt="doctor"
                                                                                    height={
                                                                                        controlModalImage?.data
                                                                                            ? "140"
                                                                                            : "130"
                                                                                    }
                                                                                    name="doctor"
                                                                                    width={
                                                                                        controlModalImage?.data
                                                                                            ? "140"
                                                                                            : "120"
                                                                                    }
                                                                                    className="mt-2 conteudo"
                                                                                    onClick={() => {
                                                                                        setControlModalImage(
                                                                                            {
                                                                                                show: true,
                                                                                                data: controlModalImage?.data
                                                                                                    ? controlModalImage?.data
                                                                                                    : null,
                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                />
                                                                                <label>
                                                                                    <small></small>
                                                                                </label>
                                                                            </div>
                                                                            <input
                                                                                type="file"
                                                                                id="input-doctor"
                                                                                name="picture"
                                                                                accept="/image"
                                                                                className="d-none "
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                {id ? (
                                                                    <Col sm="1"></Col>
                                                                ) : null}

                                                                <Col
                                                                    sm={
                                                                        id
                                                                            ? "5"
                                                                            : "6"
                                                                    }
                                                                >
                                                                    <Row>
                                                                        <Col sm="12">
                                                                            {" "}
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Nome
                                                                                </Label>
                                                                                <FastField
                                                                                    placeholder="Digite..."
                                                                                    name="name"
                                                                                    component={
                                                                                        TextInput
                                                                                    }
                                                                                    defaultValue={
                                                                                        values.name
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>

                                                                        <Col sm="12">
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Contrato
                                                                                    Vittá
                                                                                </Label>
                                                                                <FastField
                                                                                    placeholder="Selecione..."
                                                                                    name="account_person_type"
                                                                                    component={
                                                                                        ReactSelectInput
                                                                                    }
                                                                                    options={[
                                                                                        {
                                                                                            label:
                                                                                                "Pessoa Física",
                                                                                            value: 1,
                                                                                        },
                                                                                        {
                                                                                            label:
                                                                                                "Pessoa Jurídica",
                                                                                            value: 2,
                                                                                        },
                                                                                    ]}
                                                                                    defaultValue={
                                                                                        values.account_person_type
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col
                                                                    sm={
                                                                        id
                                                                            ? "4"
                                                                            : "6"
                                                                    }
                                                                >
                                                                    <Row>
                                                                        <Col sm="12">
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Usuário
                                                                                    do
                                                                                    Sistema
                                                                                </Label>
                                                                                <FastField
                                                                                    placeholder="Selecione..."
                                                                                    name="user_id"
                                                                                    component={
                                                                                        AsyncSelectInput
                                                                                    }
                                                                                    cacheOptions
                                                                                    defaultOptions
                                                                                    loadOptions={
                                                                                        debounceOptionsUser
                                                                                    }
                                                                                    disabled={
                                                                                        pageData
                                                                                            ?.professional
                                                                                            ?.user_id
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    defaultValue={
                                                                                        values.user_id
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>

                                                                        <Col sm="12">
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Status
                                                                                </Label>
                                                                                <FastField
                                                                                    name="status"
                                                                                    component={
                                                                                        ReactSelectInput
                                                                                    }
                                                                                    isMulti={
                                                                                        false
                                                                                    }
                                                                                    options={
                                                                                        optionsStatus
                                                                                    }
                                                                                    defaultValue={
                                                                                        values.status
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <CardTitle>
                                                                        {" "}
                                                                        Dados
                                                                        Pessoais
                                                                    </CardTitle>
                                                                    <CardSubtitle className="mb-3">
                                                                        Informe
                                                                        dados
                                                                        pessoais
                                                                        sobre o
                                                                        profissional
                                                                    </CardSubtitle>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            CPF
                                                                        </Label>
                                                                        <FastField
                                                                            placeholder="Digite..."
                                                                            name="cpf"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.cpf
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Data
                                                                            de
                                                                            nascimento
                                                                        </Label>
                                                                        <FastField
                                                                            placeholder="Digite..."
                                                                            name="birth_date"
                                                                            component={
                                                                                DateInput
                                                                            }
                                                                            defaultValue={
                                                                                values.birth_date
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Email
                                                                        </Label>
                                                                        <FastField
                                                                            placeholder="Digite..."
                                                                            name="email"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.email
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Telefone
                                                                        </Label>
                                                                        <FastField
                                                                            placeholder="Telefone"
                                                                            name="phone"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.phone
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            CEP
                                                                        </Label>
                                                                        <Field
                                                                            name="personal_address_cep"
                                                                            id="personalCep"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.personal_address_cep
                                                                            }
                                                                            maxLength="8"
                                                                            onKeyUp={() => {
                                                                                handleCepPersonal(
                                                                                    values
                                                                                )
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Tipo
                                                                            Logradouro
                                                                        </Label>
                                                                        <Field
                                                                            name="personal_address_tp_logradouro"
                                                                            placeholder="Selecione "
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            options={
                                                                                options.tp_logradouro
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            defaultValue={
                                                                                values.personal_address_tp_logradouro
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Endereço
                                                                            (Logradouro)
                                                                        </Label>
                                                                        <FastField
                                                                            id="personal_address_logradouro"
                                                                            name="personal_address_logradouro"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.personal_address_logradouro
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Bairro
                                                                        </Label>
                                                                        <FastField
                                                                            name="personal_address_district"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.personal_address_district
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Estado
                                                                        </Label>
                                                                        <FastField
                                                                            name="personal_address_state"
                                                                            placeholder="Selecione um estado..."
                                                                            Ref={
                                                                                stateAddressPersonalInputRef
                                                                            }
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            options={
                                                                                optionsState
                                                                            }
                                                                            defaultValue={
                                                                                values.personal_address_state
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                {stateAddressPersonalInputRef
                                                                    .current
                                                                    ?.props
                                                                    ?.value
                                                                    ?.value ? (
                                                                    <Col sm="4">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Cidade
                                                                            </Label>
                                                                            <FastField
                                                                                placeholder="Selecione..."
                                                                                name="personal_address_count_id"
                                                                                component={
                                                                                    AsyncSelectInput
                                                                                }
                                                                                loadOptions={
                                                                                    debounceCountyPersonalOptionsCounty
                                                                                }
                                                                                defaultValue={
                                                                                    values.personal_address_count_id
                                                                                }
                                                                                noOptionsMessage={() =>
                                                                                    "Sem Resultados. Digite para Buscar"
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Número
                                                                        </Label>
                                                                        <FastField
                                                                            name="personal_address_number"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.personal_address_number
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Complemento
                                                                        </Label>
                                                                        <FastField
                                                                            name="personal_address_complement"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.personal_address_complement
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col sm="9">
                                                                    <CardTitle>
                                                                        Documentos
                                                                    </CardTitle>
                                                                    <CardSubtitle className="mb-3">
                                                                        Informe
                                                                        os dados
                                                                        do RG do
                                                                        profissional
                                                                    </CardSubtitle>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            RG
                                                                        </Label>
                                                                        <FastField
                                                                            name="rg_number"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.rg_number
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            UF
                                                                            do
                                                                            RG
                                                                        </Label>
                                                                        <FastField
                                                                            name="rg_uf"
                                                                            placeholder="Selecione..."
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            defaultValue={
                                                                                values.rg_uf
                                                                            }
                                                                            options={
                                                                                optionsState
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Orgão
                                                                            Emissor
                                                                        </Label>
                                                                        <FastField
                                                                            name="rg_org"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.rg_org
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Data
                                                                            de
                                                                            Expedição
                                                                        </Label>
                                                                        <FastField
                                                                            name="rg_emission"
                                                                            component={
                                                                                DateInput
                                                                            }
                                                                            defaultValue={
                                                                                values.rg_emission
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col sm="9">
                                                                    <CardTitle>
                                                                       Configurações
                                                                    </CardTitle>
                                                                    <CardSubtitle className="mb-3">
                                                                        Configurações extras
                                                                    </CardSubtitle>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Tipo de Parceiro
                                                                        </Label>
                                                                        <FastField
                                                                            name="partner_type"
                                                                            component={ReactSelectInput}
                                                                            options={
                                                                                optionsPartnerType
                                                                            }
                                                                            defaultValue={values.partner_type}
                                                                            placeholder="Selecione..."
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="2">
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col sm="9">
                                                                    <CardTitle>
                                                                        Dados
                                                                        empresariais
                                                                    </CardTitle>
                                                                    <CardSubtitle className="mb-4">
                                                                        Informe
                                                                        os dados
                                                                        empresariais
                                                                        do
                                                                        profissional
                                                                    </CardSubtitle>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            CNPJ
                                                                        </Label>
                                                                        <FastField
                                                                            name="cnpj"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.cnpj
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Razão
                                                                            Social
                                                                        </Label>
                                                                        <FastField
                                                                            name="corporate_name"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.corporate_name
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Nome
                                                                            Fantasia
                                                                        </Label>
                                                                        <FastField
                                                                            name="fantasy_name"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.fantasy_name
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            CEP
                                                                        </Label>
                                                                        <Field
                                                                            name="addr_company_cep"
                                                                            id="cep"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.addr_company_cep
                                                                            }
                                                                            maxLength="8"
                                                                            onKeyUp={() => {
                                                                                handleCep(
                                                                                    values
                                                                                )
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Tipo
                                                                            Logradouro
                                                                        </Label>
                                                                        <Field
                                                                            name="addr_company_tp_logradouro"
                                                                            placeholder="Selecione "
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            options={
                                                                                options.tp_logradouro
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            defaultValue={
                                                                                values.addr_company_tp_logradouro
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Endereço
                                                                            (Logradouro)
                                                                        </Label>
                                                                        <FastField
                                                                            id="logradouro"
                                                                            name="addr_company_logradouro"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.addr_company_logradouro
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Complemento
                                                                        </Label>
                                                                        <FastField
                                                                            name="addr_company_complement"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.addr_company_complement
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Bairro
                                                                        </Label>
                                                                        <FastField
                                                                            name="addr_company_district"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.addr_company_district
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Estado
                                                                        </Label>
                                                                        <FastField
                                                                            name="addr_company_state"
                                                                            placeholder="Selecione um estado..."
                                                                            Ref={
                                                                                stateAddressInputRef
                                                                            }
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            options={
                                                                                optionsState
                                                                            }
                                                                            defaultValue={
                                                                                values.addr_company_state
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                {stateAddressInputRef
                                                                    .current
                                                                    ?.props
                                                                    ?.value
                                                                    ?.value ? (
                                                                    <Col sm="4">
                                                                        <FormGroup>
                                                                            <Label>
                                                                                Cidade
                                                                            </Label>
                                                                            <FastField
                                                                                placeholder="Selecione..."
                                                                                name="addr_company_county_id"
                                                                                component={
                                                                                    AsyncSelectInput
                                                                                }
                                                                                loadOptions={
                                                                                    debounceOptionsCounty
                                                                                }
                                                                                defaultValue={
                                                                                    values.addr_company_county_id
                                                                                }
                                                                                noOptionsMessage={() =>
                                                                                    "Sem Resultados. Digite para Buscar"
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Número
                                                                        </Label>
                                                                        <FastField
                                                                            name="addr_company_number"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.addr_company_number
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardBody>
                                                            <CardTitle>
                                                                Dados Contábeis
                                                            </CardTitle>
                                                            <CardSubtitle className="mb-3">
                                                                Informe os dados
                                                                contábeis
                                                            </CardSubtitle>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Transmissão
                                                                            WS
                                                                            Produção
                                                                        </Label>
                                                                        <FastField
                                                                            name="ws_prod_is_active"
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            placeholder="Selecione"
                                                                            options={
                                                                                optionsWsProd
                                                                            }
                                                                            // defaultValue={
                                                                            //     values.status
                                                                            // }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            RPS
                                                                            Sequencial
                                                                        </Label>
                                                                        <FastField
                                                                            name="rps_start_number"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.rps_start_number
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            RPS
                                                                            Serie
                                                                        </Label>
                                                                        <FastField
                                                                            name="rps_start_serie"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.rps_start_serie
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Regime
                                                                            Tributario
                                                                        </Label>
                                                                        <Field
                                                                            name="tax_regime"
                                                                            placeholder="Selecione "
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            options={
                                                                                options.tax_regime
                                                                            }
                                                                            defaultValue={
                                                                                values.tax_regime
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Regime
                                                                            Tributario
                                                                            Especial
                                                                        </Label>
                                                                        <Field
                                                                            name="special_tax_regime"
                                                                            placeholder="Selecione "
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            options={
                                                                                options.special_tax_regime
                                                                            }
                                                                            defaultValue={
                                                                                values.special_tax_regime
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Inscrição
                                                                            Municipal
                                                                        </Label>
                                                                        <FastField
                                                                            name="municipal_registration"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.municipal_registration
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardBody>
                                                            <CardTitle>
                                                                Conselho
                                                                Profissional
                                                            </CardTitle>
                                                            <CardSubtitle className="mb-3">
                                                                Informe os dados
                                                                do Conselho
                                                                Profissional
                                                            </CardSubtitle>
                                                            <Row>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Especialidade
                                                                            Principal
                                                                        </Label>
                                                                        <Field
                                                                            name="register_specialty"
                                                                            placeholder="Selecione a especialidade"
                                                                            cacheOptions
                                                                            defaultOptions
                                                                            isClearable
                                                                            loadOptions={
                                                                                debounceOptionsSpecialty
                                                                            }
                                                                            component={
                                                                                AsyncSelectInput
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Registro
                                                                        </Label>
                                                                        <FastField
                                                                            name="register_type"
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            placeholder="Selecione..."
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            options={
                                                                                optionsRegisterType
                                                                            }
                                                                            defaultValue={
                                                                                values.register_type
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Número
                                                                        </Label>
                                                                        <FastField
                                                                            name="register_code"
                                                                            component={
                                                                                TextInput
                                                                            }
                                                                            defaultValue={
                                                                                values.register_code
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Estado
                                                                        </Label>
                                                                        <FastField
                                                                            name="register_uf"
                                                                            placeholder="Selecione um estado..."
                                                                            component={
                                                                                ReactSelectInput
                                                                            }
                                                                            isMulti={
                                                                                false
                                                                            }
                                                                            options={
                                                                                optionsState
                                                                            }
                                                                            defaultValue={
                                                                                values.register_uf
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs="12">
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="3">
                                                    <Card>
                                                        <CardBody>
                                                            <CardTitle>
                                                                Serviços
                                                                Prestados
                                                            </CardTitle>
                                                            <CardSubtitle className="mb-3">
                                                                Informe os
                                                                Serviços que o
                                                                profisional pode
                                                                prestar e suas
                                                                configurações
                                                            </CardSubtitle>
                                                            <Row>
                                                                <Col sm="3">
                                                                    <Label>
                                                                        Serviço
                                                                    </Label>
                                                                </Col>
                                                                <Col sm="1">
                                                                    <Label>
                                                                        LC 116
                                                                    </Label>
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Label>
                                                                        Código
                                                                        tributação
                                                                        no
                                                                        município
                                                                    </Label>
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Label>
                                                                        CNAE
                                                                        (Cód.
                                                                        Nacional
                                                                        Atividade
                                                                        Eco.)
                                                                    </Label>
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Label>
                                                                        Alíquota
                                                                        ISS
                                                                    </Label>
                                                                </Col>
                                                            </Row>
                                                            <FieldArray
                                                                name={
                                                                    "services"
                                                                }
                                                                render={arrayHelpers => (
                                                                    <>
                                                                        {values &&
                                                                        values
                                                                            ?.services
                                                                            ?.length >
                                                                            0
                                                                            ? values.services.map(
                                                                                  (
                                                                                      item,
                                                                                      index
                                                                                  ) => (
                                                                                      <Row
                                                                                          key={
                                                                                              index
                                                                                          }
                                                                                          className="mb-3 align-items-center"
                                                                                      >
                                                                                          <Col sm="3">
                                                                                              <FastField
                                                                                                  placeholder="Selecione..."
                                                                                                  name={`services[${index}].item_id`}
                                                                                                  component={
                                                                                                      AsyncSelectInput
                                                                                                  }
                                                                                                  cacheOptions
                                                                                                  defaultOptions
                                                                                                  loadOptions={
                                                                                                      debounceOptionsItems
                                                                                                  }
                                                                                                  defaultValue={
                                                                                                      item.item_id
                                                                                                  }
                                                                                                  required
                                                                                              />
                                                                                          </Col>
                                                                                          <Col sm="1">
                                                                                              <Field
                                                                                                  name={`services[${index}].tax_service_code`}
                                                                                                  component={
                                                                                                      TextInput
                                                                                                  }
                                                                                                  defaultValue={
                                                                                                      item.tax_service_code
                                                                                                  }
                                                                                              />
                                                                                          </Col>
                                                                                          <Col sm="2">
                                                                                              <Field
                                                                                                  name={`services[${index}].tax_municipality`}
                                                                                                  component={
                                                                                                      TextInput
                                                                                                  }
                                                                                                  defaultValue={
                                                                                                      item.tax_municipality
                                                                                                  }
                                                                                              />

                                                                                              {/* <Field
                                                                                      placeholder="Selecione..."
                                                                                      name={`services[${index}].tax_service_code`}
                                                                                      component={
                                                                                          AsyncSelectInput
                                                                                      }
                                                                                      loadOptions={
                                                                                          debounceOptionsTaxServices
                                                                                      }
                                                                                      defaultValue={
                                                                                          item.tax_service_code
                                                                                      }
                                                                                      noOptionsMessage={() =>
                                                                                          "Sem Resultados. Digite para Buscar"
                                                                                      }
                                                                                  /> */}
                                                                                          </Col>

                                                                                          <Col sm="2">
                                                                                              <Field
                                                                                                  name={`services[${index}].tax_cnae`}
                                                                                                  component={
                                                                                                      TextInput
                                                                                                  }
                                                                                                  defaultValue={
                                                                                                      item.tax_cnae
                                                                                                  }
                                                                                              />
                                                                                          </Col>
                                                                                          <Col sm="2">
                                                                                              <FastField
                                                                                                  name={`services[${index}]tax_iss_aliquot`}
                                                                                                  component={
                                                                                                      NumberInput
                                                                                                  }
                                                                                                  displayType={
                                                                                                      "input"
                                                                                                  }
                                                                                                  thousandSeparator={
                                                                                                      "."
                                                                                                  }
                                                                                                  decimalSeparator={
                                                                                                      ","
                                                                                                  }
                                                                                                  decimalScale={
                                                                                                      2
                                                                                                  }
                                                                                                  fixedDecimalScale={
                                                                                                      true
                                                                                                  }
                                                                                                  allowEmptyFormatting={
                                                                                                      true
                                                                                                  }
                                                                                                  defaultValue={
                                                                                                      values.tax_iss_aliquot
                                                                                                  }
                                                                                              />
                                                                                          </Col>

                                                                                          <Col sm="1">
                                                                                              <Button
                                                                                                  color="info"
                                                                                                  className="inner"
                                                                                                  onClick={() => {
                                                                                                      const copyData = {
                                                                                                          item_id: {
                                                                                                              label:
                                                                                                                  item
                                                                                                                      ?.item_id
                                                                                                                      ?.label,
                                                                                                              value:
                                                                                                                  item
                                                                                                                      ?.item_id
                                                                                                                      ?.value,
                                                                                                          },
                                                                                                          tax_cnae:
                                                                                                              item?.tax_cnae,
                                                                                                          tax_municipality:
                                                                                                              item?.tax_municipality,
                                                                                                          tax_iss_aliquot:
                                                                                                              item?.tax_iss_aliquot,
                                                                                                          tax_service_code:
                                                                                                              item?.tax_service_code,
                                                                                                      }

                                                                                                      arrayHelpers.push(
                                                                                                          copyData
                                                                                                      )
                                                                                                  }}
                                                                                              >
                                                                                                  <i className="fas fa-copy fa-lg"></i>
                                                                                              </Button>
                                                                                          </Col>
                                                                                          <Col sm="1">
                                                                                              <Button
                                                                                                  align="start"
                                                                                                  color="danger"
                                                                                                  className="inner"
                                                                                                  onClick={() =>
                                                                                                      arrayHelpers.remove(
                                                                                                          index
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <i className="fas fa-trash-alt fa-lg"></i>
                                                                                              </Button>
                                                                                          </Col>
                                                                                      </Row>
                                                                                  )
                                                                              )
                                                                            : null}

                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                arrayHelpers.push(
                                                                                    defaultProfessionalService
                                                                                )
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-plus"></i>
                                                                        </Button>
                                                                        <Row>
                                                                            <Col
                                                                                sm="12"
                                                                                align="end"
                                                                            >
                                                                                <Button
                                                                                    type="button"
                                                                                    color="primary"
                                                                                    disabled={
                                                                                        controlState
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onClick={() => {
                                                                                        handleSaveServicesAndTax(
                                                                                            values.id,
                                                                                            values?.services
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Salvar
                                                                                    Serviços{" "}
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                )}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                    {id ? (
                                                        <Row
                                                            style={{
                                                                marginTop: 20,
                                                            }}
                                                        >
                                                            <Col xs="12">
                                                                <Card>
                                                                    <CardBody>
                                                                        <Row>
                                                                            <Col sm="9">
                                                                                <CardTitle>
                                                                                    Sincronização
                                                                                    com
                                                                                    o
                                                                                    serviço
                                                                                    de
                                                                                    emissão
                                                                                    de
                                                                                    NFS-E
                                                                                </CardTitle>
                                                                                <CardSubtitle className="mb-3">
                                                                                    Área
                                                                                    reservada
                                                                                    para
                                                                                    a
                                                                                    sincronização
                                                                                    dos
                                                                                    dados
                                                                                    deste
                                                                                    funcionário
                                                                                    com
                                                                                    o
                                                                                    serviço
                                                                                    de
                                                                                    emissão
                                                                                    de
                                                                                    notas
                                                                                    fiscais
                                                                                    eletrônicas
                                                                                </CardSubtitle>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row
                                                                            style={{
                                                                                marginTop: 15,
                                                                            }}
                                                                        >
                                                                            <Col sm="3">
                                                                                <FormGroup>
                                                                                    <Label>
                                                                                        Senha
                                                                                        Certificado
                                                                                    </Label>
                                                                                    <FastField
                                                                                        name="digital_certificate_password"
                                                                                        component={
                                                                                            PasswordInput
                                                                                        }
                                                                                        defaultValue={
                                                                                            values.digital_certificate_password
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm="3">
                                                                                <FormGroup>
                                                                                    <Label>
                                                                                        Certificado
                                                                                        A3
                                                                                        (pfx/p12)
                                                                                    </Label>
                                                                                    <Input
                                                                                        id="file"
                                                                                        type="file"
                                                                                        name="digital_certificate_file"
                                                                                        component={
                                                                                            FileInput
                                                                                        }
                                                                                        onChange={e => {
                                                                                            setImage(
                                                                                                e
                                                                                                    .target
                                                                                                    .files[0]
                                                                                            ),
                                                                                                setControlStateUpload(
                                                                                                    0
                                                                                                )
                                                                                        }}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm="3">
                                                                                <FormGroup className="mt-3">
                                                                                    {controlStateUpload ==
                                                                                    0 ? (
                                                                                        <Button
                                                                                            type="button"
                                                                                            color="warning"
                                                                                            onClick={() =>
                                                                                                handleCertificate(
                                                                                                    values
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Atualizar
                                                                                            Certificado
                                                                                        </Button>
                                                                                    ) : (
                                                                                        <Button
                                                                                            type="button"
                                                                                            color="warning"
                                                                                            disabled={
                                                                                                true
                                                                                            }
                                                                                            onClick={() =>
                                                                                                handleCertificate(
                                                                                                    values
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Atualizar
                                                                                            Certificado
                                                                                            <i className="fas fa-lock fa-lg ml-1"></i>
                                                                                        </Button>
                                                                                    )}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            {values.digital_certificate_url ? (
                                                                                <Col>
                                                                                    {" "}
                                                                                    <Badge
                                                                                        className="badge-soft-success"
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "13px",
                                                                                        }}
                                                                                    >
                                                                                        Certificado{" "}
                                                                                        {values.digital_certificate_url.substring(
                                                                                            33,
                                                                                            41
                                                                                        )}{" "}
                                                                                        :
                                                                                        Registrado{" "}
                                                                                        <i className="fas fa-check ml-1 fa-lg"></i>
                                                                                    </Badge>
                                                                                </Col>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </Row>

                                                                        <hr />
                                                                        <Row
                                                                            style={{
                                                                                marginTop: 15,
                                                                            }}
                                                                        >
                                                                            <Col>
                                                                                <CardSubtitle>
                                                                                    Após
                                                                                    realizar
                                                                                    o
                                                                                    upload
                                                                                    do
                                                                                    certificado
                                                                                    ou
                                                                                    atualizada
                                                                                    alguma
                                                                                    informação
                                                                                    do
                                                                                    colaborador,
                                                                                    clique
                                                                                    no
                                                                                    botão
                                                                                    abaixo
                                                                                    para
                                                                                    sincronizar
                                                                                    todos
                                                                                    os
                                                                                    dados
                                                                                    com
                                                                                    o
                                                                                    serviço
                                                                                    de
                                                                                    emissão
                                                                                    de
                                                                                    notas
                                                                                    fiscais.
                                                                                </CardSubtitle>

                                                                                <SyncTaxdocsStatusDataMessage
                                                                                    status={
                                                                                        values.statusGenInvoice
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col>
                                                                                <SyncTaxdocsLastUpdatedAt
                                                                                    lastUpdatedAt={
                                                                                        isTaxDocsButtonEnabled
                                                                                            ? pageData
                                                                                                  .professional
                                                                                                  .tax_docs_updated_at
                                                                                            : null
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col>
                                                                                <LastProfessionalUpdatedAt
                                                                                    lastUpdatedAt={
                                                                                        isTaxDocsButtonEnabled
                                                                                            ? pageData
                                                                                                  .professional
                                                                                                  .updated_at
                                                                                            : null
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <TaxdocsNeedToSyncMessage
                                                                                    lastProfessionalUpdate={
                                                                                        isTaxDocsButtonEnabled
                                                                                            ? pageData
                                                                                                  .professional
                                                                                                  .updated_at
                                                                                            : null
                                                                                    }
                                                                                    lastTaxdocsUpdate={
                                                                                        isTaxDocsButtonEnabled
                                                                                            ? pageData
                                                                                                  .professional
                                                                                                  .tax_docs_updated_at
                                                                                            : null
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col>
                                                                                <SyncTaxdocsButton
                                                                                    disabled={
                                                                                        !isTaxDocsButtonEnabled
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                        <Row
                                                                            style={{
                                                                                marginTop: 15,
                                                                            }}
                                                                        >
                                                                            <Col>
                                                                                <SyncTaxdocsMessage />
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        ""
                                                    )}
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="4">
                                            <Card>
                                                <CardBody>
                                                    <Row className="justify-content-end">
                                                        <Col>
                                                            <div>
                                                                <div className="custom-control custom-switch custom-switch-sm">
                                                                    <input
                                                                        id="vittapaymentscheckbox"
                                                                        name="anticipation_enabled"
                                                                        type="checkbox"
                                                                        className="custom-control-input checkbox"
                                                                        checked={
                                                                            pageData?.professional?.anticipation_enabled ?? false
                                                                        }
                                                                        onChange={() => {

                                                                            let data = {...pageData?.professional};
                                                                          
                                                                            data.anticipation_enabled = !data.anticipation_enabled;

                                                                            setPageData({...pageData, professional: data});
                                                                          
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor="vittapaymentscheckbox"
                                                                    >
                                                                        <small>
                                                                            {" "}
                                                                            <span
                                                                                className="text-info"
                                                                                style={{
                                                                                    fontWeight:
                                                                                        "600",
                                                                                    position:
                                                                                        "relative",
                                                                                    top:
                                                                                        "-1px",
                                                                                }}
                                                                            >
                                                                                Antecipação
                                                                                de
                                                                                Recebíveis
                                                                            </span>
                                                                        </small>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="6" align="end">
                                                            {vittaPaymentsElectronicMode &&
                                                            !values?.vittapag_id_id ? (
                                                                <div>
                                                                    <strong className="text-danger">
                                                                        {" "}
                                                                        <i className="fas fa-info-circle"></i>{" "}
                                                                        Necessário
                                                                        sincronizar
                                                                        o
                                                                        profissional
                                                                        com
                                                                        Vittá
                                                                        Pagamentos.
                                                                    </strong>
                                                                </div>
                                                            ) : null}{" "}
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                disabled={
                                                                    vittaPaymentsElectronicMode &&
                                                                    !values?.vittapag_id_id
                                                                }
                                                                onClick={() => {
                                                                    setModalAccountBank(
                                                                        {
                                                                            show: true,
                                                                            data: null,
                                                                        }
                                                                    )
                                                                }}
                                                            >
                                                                Adicionar Conta
                                                                Bancária
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <FieldArray
                                                        name={
                                                            "account_bank_data"
                                                        }
                                                        render={arrayHelpersDocuments => (
                                                            <>
                                                                <CardTitle>
                                                                    Contas
                                                                    Registradas{" "}
                                                                </CardTitle>
                                                                {handleShowBankAccounts(
                                                                    values?.account_bank_data,
                                                                    vittaPaymentsElectronicMode
                                                                ) &&
                                                                handleShowBankAccounts(
                                                                    values?.account_bank_data,
                                                                    vittaPaymentsElectronicMode
                                                                )?.length > 0
                                                                    ? values.account_bank_data.map(
                                                                          (
                                                                              itemAccountBank,
                                                                              indexAccountBank
                                                                          ) => (
                                                                              <Row
                                                                                  key={
                                                                                      indexAccountBank
                                                                                  }
                                                                              >
                                                                                  <Col sm="10">
                                                                                      <Row>
                                                                                          <Col sm="12">
                                                                                              {" "}
                                                                                              <CardHeader
                                                                                                  style={{
                                                                                                      cursor:
                                                                                                          "pointer",
                                                                                                      height:
                                                                                                          "40px",

                                                                                                      //  background:"orange"
                                                                                                  }}
                                                                                                  className="shadow-lg bg-white mt-3"
                                                                                                  onClick={() => {
                                                                                                      //   setTab(!index)
                                                                                                      HandleTab(
                                                                                                          indexAccountBank
                                                                                                      )
                                                                                                  }}
                                                                                              >
                                                                                                  <Row>
                                                                                                      <Col xs="10">
                                                                                                          {" "}
                                                                                                          <Badge
                                                                                                              pill
                                                                                                              className="badge-soft-dark font-size-12 "
                                                                                                              align="center"
                                                                                                          >
                                                                                                              Conta
                                                                                                              Bancária
                                                                                                              <span
                                                                                                                  className="ml-1"
                                                                                                                  style={{
                                                                                                                      fontWeight:
                                                                                                                          "bold",
                                                                                                                  }}
                                                                                                              >
                                                                                                                  {
                                                                                                                      itemAccountBank
                                                                                                                          ?.account_person_type
                                                                                                                          ?.label
                                                                                                                  }
                                                                                                              </span>

                                                                                                              -
                                                                                                              #
                                                                                                              {
                                                                                                                  indexAccountBank
                                                                                                              }
                                                                                                              <i className="far fa-file fa-lg ml-1"></i>
                                                                                                          </Badge>{" "}
                                                                                                      </Col>
                                                                                                      <Col xs="2">
                                                                                                          {" "}
                                                                                                          {tab ==
                                                                                                          indexAccountBank ? (
                                                                                                              <div align="end">
                                                                                                                  {" "}
                                                                                                                  <i className="fas fas fa-arrow-up fa-lg">
                                                                                                                      {" "}
                                                                                                                  </i>
                                                                                                              </div>
                                                                                                          ) : (
                                                                                                              <div align="end">
                                                                                                                  {" "}
                                                                                                                  <i className="fas fas fa-arrow-down fa-lg">
                                                                                                                      {" "}
                                                                                                                  </i>{" "}
                                                                                                              </div>
                                                                                                          )}
                                                                                                      </Col>{" "}
                                                                                                  </Row>
                                                                                              </CardHeader>
                                                                                          </Col>
                                                                                      </Row>

                                                                                      <Collapse
                                                                                          isOpen={
                                                                                              tab ==
                                                                                              indexAccountBank
                                                                                          }
                                                                                      >
                                                                                          <Card className="mt-2 shadow-lg">
                                                                                              <CardBody>
                                                                                                  <Row>
                                                                                                      <Col sm="3">
                                                                                                          {" "}
                                                                                                          <FormGroup>
                                                                                                              <Label>
                                                                                                                  Banco
                                                                                                              </Label>
                                                                                                              <Field
                                                                                                                  isDisabled={
                                                                                                                      true
                                                                                                                  }
                                                                                                                  placeholder="Selecione..."
                                                                                                                  name="bank_code"
                                                                                                                  component={
                                                                                                                      ReactSelectInput
                                                                                                                  }
                                                                                                                  value={
                                                                                                                      itemAccountBank.bank_code
                                                                                                                  }
                                                                                                              />
                                                                                                          </FormGroup>
                                                                                                      </Col>

                                                                                                      <Col sm="3">
                                                                                                          <FormGroup>
                                                                                                              <Label>
                                                                                                                  Tipo
                                                                                                                  Conta
                                                                                                                  Bancária
                                                                                                              </Label>
                                                                                                              <Field
                                                                                                                  isDisabled={
                                                                                                                      true
                                                                                                                  }
                                                                                                                  placeholder="Selecione..."
                                                                                                                  name="account_type"
                                                                                                                  component={
                                                                                                                      ReactSelectInput
                                                                                                                  }
                                                                                                                  isMulti={
                                                                                                                      false
                                                                                                                  }
                                                                                                                  options={[
                                                                                                                      {
                                                                                                                          label:
                                                                                                                              "Corrente",
                                                                                                                          value: 1,
                                                                                                                      },
                                                                                                                      {
                                                                                                                          label:
                                                                                                                              "Poupança",
                                                                                                                          value: 2,
                                                                                                                      },
                                                                                                                  ]}
                                                                                                                  value={
                                                                                                                      itemAccountBank.account_type
                                                                                                                  }
                                                                                                              />
                                                                                                          </FormGroup>
                                                                                                      </Col>
                                                                                                      <Col sm="3">
                                                                                                          {" "}
                                                                                                          <FormGroup>
                                                                                                              <Label>
                                                                                                                  Agência
                                                                                                              </Label>
                                                                                                              <Field
                                                                                                                  style={{
                                                                                                                      backgroundColor:
                                                                                                                          "#f2f2f2",
                                                                                                                  }}
                                                                                                                  disabled={
                                                                                                                      true
                                                                                                                  }
                                                                                                                  placeholder="Digite..."
                                                                                                                  name="bank_branch"
                                                                                                                  component={
                                                                                                                      TextInput
                                                                                                                  }
                                                                                                                  value={
                                                                                                                      itemAccountBank.bank_branch
                                                                                                                  }
                                                                                                                  maxLength="4"
                                                                                                              />
                                                                                                          </FormGroup>
                                                                                                      </Col>
                                                                                                      <Col sm="3">
                                                                                                          {" "}
                                                                                                          <FormGroup>
                                                                                                              <Label>
                                                                                                                  Conta
                                                                                                              </Label>
                                                                                                              <Field
                                                                                                                  style={{
                                                                                                                      backgroundColor:
                                                                                                                          "#f2f2f2",
                                                                                                                  }}
                                                                                                                  disabled={
                                                                                                                      true
                                                                                                                  }
                                                                                                                  placeholder="Digite..."
                                                                                                                  name="bank_account"
                                                                                                                  component={
                                                                                                                      TextInput
                                                                                                                  }
                                                                                                                  value={
                                                                                                                      itemAccountBank.bank_account
                                                                                                                  }
                                                                                                              />
                                                                                                          </FormGroup>
                                                                                                      </Col>
                                                                                                  </Row>
                                                                                              </CardBody>
                                                                                          </Card>
                                                                                      </Collapse>
                                                                                  </Col>

                                                                                  <Col
                                                                                      sm="2"
                                                                                      className="mt-3"
                                                                                  >
                                                                                      {/* <Button
                                                                                              type="button"
                                                                                              color="danger"
                                                                                              onClick={() => {
                                                                                                  dispatch(
                                                                                                      showSweetAlertDialog(
                                                                                                          {
                                                                                                              title:
                                                                                                                  "Atenção ",
                                                                                                              text:
                                                                                                                  "Tem certeza que deseja excluir este registro?",
                                                                                                              type:
                                                                                                                  "warning",
                                                                                                              onConfirmAction: async () => {
                                                                                                                  await destroy(
                                                                                                                      itemAccountBank?.id
                                                                                                                  )
                                                                                                                  dispatch(
                                                                                                                      showToast(
                                                                                                                          {
                                                                                                                              title:
                                                                                                                                  "Sucesso!",
                                                                                                                              text:
                                                                                                                                  "Ação concluída.",
                                                                                                                              type:
                                                                                                                                  "success",
                                                                                                                          }
                                                                                                                      )
                                                                                                                  )

                                                                                                                  loadDataEditPage()
                                                                                                              },
                                                                                                              showCancel: true,
                                                                                                              showConfirm: true,
                                                                                                              cancelBtnText:
                                                                                                                  "Não",
                                                                                                              confirmBtnText:
                                                                                                                  "Sim",
                                                                                                          }
                                                                                                      )
                                                                                                  )
                                                                                              }}
                                                                                          >
                                                                                              {" "}
                                                                                              <i className="fas fa-trash-alt text-white"></i>
                                                                                          </Button> */}
                                                                                      <Button
                                                                                          type="button"
                                                                                          color="info"
                                                                                          className="ml-3"
                                                                                          onClick={() => {
                                                                                              setModalAccountBank(
                                                                                                  {
                                                                                                      show: true,
                                                                                                      data: itemAccountBank,
                                                                                                  }
                                                                                              )
                                                                                          }}
                                                                                      >
                                                                                          {" "}
                                                                                          <i className="fas fa-edit text-white"></i>
                                                                                      </Button>
                                                                                  </Col>
                                                                              </Row>
                                                                          )
                                                                      )
                                                                    : "Sem contas bancárias registradas"}
                                                            </>
                                                        )}
                                                    />

                                                    {/* <Row>
                                                        <Col
                                                            sm="12"
                                                            align="end"
                                                            className="mt-2"
                                                        >
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                disabled={
                                                                    controlState
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={() => {
                                                                    handleSaveDataBank(
                                                                        values?.id,
                                                                        values
                                                                    )
                                                                }}
                                                            >
                                                                Salvar Dados
                                                                Bancários{" "}
                                                            </Button>
                                                        </Col>
                                                    </Row> */}
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <div>
                                                        <Nav
                                                            tabs
                                                            className="nav-tabs-custom "
                                                        >
                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames(
                                                                        {
                                                                            active:
                                                                                activeTabEletronic ===
                                                                                "1",
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        toggleEletronic(
                                                                            "1"
                                                                        )
                                                                    }}
                                                                >
                                                                    Sincronizar
                                                                    ZOOP
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames(
                                                                        {
                                                                            active:
                                                                                activeTabEletronic ===
                                                                                "2",
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        toggleEletronic(
                                                                            "2"
                                                                        )
                                                                    }}
                                                                >
                                                                    Sincronizar
                                                                    Vittá
                                                                    Pagamentos
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                        <TabContent
                                                            activeTab={
                                                                activeTabEletronic
                                                            }
                                                        >
                                                            <TabPane tabId="1">
                                                                <Row>
                                                                    <Col sm="12">
                                                                        <Row className="mt-3">
                                                                            {" "}
                                                                            <Col sm="6">
                                                                                <CardTitle>
                                                                                    Recebimento
                                                                                    Eletrônico{" "}
                                                                                    <strong>
                                                                                        (ZOOP)
                                                                                    </strong>
                                                                                </CardTitle>
                                                                                <CardSubtitle>
                                                                                    Verifique
                                                                                    se
                                                                                    o
                                                                                    cadastro
                                                                                    está
                                                                                    apto
                                                                                    a
                                                                                    receber
                                                                                    de
                                                                                    forma
                                                                                    eletrônica.
                                                                                </CardSubtitle>
                                                                            </Col>
                                                                            <Col
                                                                                sm="6"
                                                                                align="end"
                                                                            >
                                                                                <Button
                                                                                    type="button"
                                                                                    color="dark"
                                                                                    onClick={() => {
                                                                                        setModalInfo(
                                                                                            {
                                                                                                show: true,
                                                                                                data: null,
                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-info fa-lg"></i>
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="mt-3">
                                                                            <Col sm="6">
                                                                                {renderStatusProfessionalElectronicReceipt(
                                                                                    values?.zoop_status
                                                                                )}
                                                                                <div className="mt-1">
                                                                                    {" "}
                                                                                    {renderLastSync(
                                                                                        values?.zoop_sync_at
                                                                                    )}
                                                                                </div>
                                                                                {values
                                                                                    ?.servicesDocuments
                                                                                    ?.length >
                                                                                    0 &&
                                                                                values?.servicesDocuments?.find(
                                                                                    e =>
                                                                                        e?.zoop_status ==
                                                                                            "rejected" ||
                                                                                        e?.zoop_status ==
                                                                                            "action_required"
                                                                                )
                                                                                    ? values?.servicesDocuments?.map(
                                                                                          (
                                                                                              document,
                                                                                              indexDoc
                                                                                          ) => {
                                                                                              return (
                                                                                                  <div className="mt-1">
                                                                                                      {document.zoop_status ==
                                                                                                          "action_required" ||
                                                                                                      document.zoop_status ==
                                                                                                          "rejected" ? (
                                                                                                          <Badge
                                                                                                              className={
                                                                                                                  "font-size-10 badge-soft-warning"
                                                                                                              }
                                                                                                              pill
                                                                                                          >
                                                                                                              Documento
                                                                                                              de
                                                                                                              ID
                                                                                                              #
                                                                                                              {
                                                                                                                  document?.id
                                                                                                              }{" "}
                                                                                                              está
                                                                                                              apresentando
                                                                                                              algum
                                                                                                              problema
                                                                                                          </Badge>
                                                                                                      ) : (
                                                                                                          ""
                                                                                                      )}
                                                                                                  </div>
                                                                                              )
                                                                                          }
                                                                                      )
                                                                                    : ""}
                                                                            </Col>
                                                                            {controlSyncElectronicReceipt ? (
                                                                                <Col
                                                                                    sm="6"
                                                                                    align="start"
                                                                                >
                                                                                    <Spinner
                                                                                        style={{
                                                                                            color:
                                                                                                "#50a5f1",
                                                                                        }}
                                                                                    >
                                                                                        Loading...
                                                                                    </Spinner>
                                                                                </Col>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </Row>
                                                                        <Row className="mt-3">
                                                                            <Col sm="6">
                                                                                <Button
                                                                                    className="btn btn-info text-white"
                                                                                    type="button"
                                                                                    disabled={
                                                                                        controlSyncElectronicReceipt
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onClick={() => {
                                                                                        handleSyncElectronicReceipt(
                                                                                            "zoop"
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Sincronizar
                                                                                </Button>
                                                                                {values?.zoop_id ? (
                                                                                    <Button
                                                                                        className="btn btn-danger text-white ml-2"
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            confirmDisableSyncElectronicReceipt(
                                                                                                "zoop"
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        Desativar
                                                                                    </Button>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId="2">
                                                                <Row>
                                                                    <Col sm="12">
                                                                        <Row className="mt-3">
                                                                            {" "}
                                                                            <Col sm="6">
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "row",
                                                                                        gap:
                                                                                            "20px",
                                                                                    }}
                                                                                >
                                                                                    <CardTitle>
                                                                                        Recebimento
                                                                                        Eletrônico{" "}
                                                                                        <strong>
                                                                                            (Vittá
                                                                                            Pagamentos)
                                                                                        </strong>
                                                                                    </CardTitle>
                                                                                </div>
                                                                                <CardSubtitle>
                                                                                    Verifique
                                                                                    se
                                                                                    o
                                                                                    cadastro
                                                                                    está
                                                                                    apto
                                                                                    a
                                                                                    receber
                                                                                    de
                                                                                    forma
                                                                                    eletrônica.
                                                                                </CardSubtitle>
                                                                            </Col>
                                                                            <Col
                                                                                sm="6"
                                                                                align="end"
                                                                            >
                                                                                <Button
                                                                                    type="button"
                                                                                    color="dark"
                                                                                    onClick={() => {
                                                                                        setModalInfo(
                                                                                            {
                                                                                                show: true,
                                                                                                data: null,
                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-info fa-lg"></i>
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="mt-3">
                                                                            <Col sm="6">
                                                                                {renderStatusProfessionalElectronicReceiptVittaPag(
                                                                                    values
                                                                                )}
                                                                                <div className="mt-1">
                                                                                    {" "}
                                                                                    {renderLastSync(
                                                                                        values?.vittapag_sync_at
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            {controlSyncElectronicReceipt ? (
                                                                                <Col
                                                                                    sm="6"
                                                                                    align="start"
                                                                                >
                                                                                    <Spinner
                                                                                        style={{
                                                                                            color:
                                                                                                "#50a5f1",
                                                                                        }}
                                                                                    >
                                                                                        Loading...
                                                                                    </Spinner>
                                                                                </Col>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </Row>
                                                                        <Row className="mt-3">
                                                                            <Col sm="6">
                                                                                <Button
                                                                                    className="btn btn-info text-white"
                                                                                    type="button"
                                                                                    disabled={
                                                                                        controlSyncElectronicReceipt
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onClick={() => {
                                                                                        handleSyncElectronicReceipt(
                                                                                            "vittapag"
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Sincronizar
                                                                                </Button>
                                                                                {values?.zoop_id ? (
                                                                                    <Button
                                                                                        className="btn btn-danger text-white ml-2"
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            confirmDisableSyncElectronicReceipt(
                                                                                                "vittapag"
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        Desativar
                                                                                    </Button>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                    </TabContent>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="5">
                                            <Card>
                                                <CardBody>
                                                    <CardTitle className="mb-3">
                                                        Arquivos{" "}
                                                    </CardTitle>
                                                    <Row>
                                                        {handleShowDocuments(
                                                            values?.servicesDocuments,
                                                            vittaPaymentsElectronicMode
                                                        )?.length > 0 &&
                                                        handleShowDocuments(
                                                            values?.servicesDocuments,
                                                            vittaPaymentsElectronicMode
                                                        )?.find(e => e?.id) ? (
                                                            <>
                                                                <Col
                                                                    sm="1"
                                                                    align="start"
                                                                >
                                                                    <Label>
                                                                        ID
                                                                    </Label>
                                                                </Col>
                                                                <Col sm="5">
                                                                    <Label>
                                                                        Tipo
                                                                    </Label>
                                                                </Col>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {/* <Col sm="3">
                                                            <Label className="ml-1">
                                                                Arquivo
                                                            </Label>
                                                        </Col> */}
                                                    </Row>
                                                    <FieldArray
                                                        name={
                                                            "servicesDocuments"
                                                        }
                                                        render={arrayHelpersDocuments => (
                                                            <>
                                                                {handleShowDocuments(
                                                                    values?.servicesDocuments,
                                                                    vittaPaymentsElectronicMode
                                                                ) &&
                                                                handleShowDocuments(
                                                                    values?.servicesDocuments,
                                                                    vittaPaymentsElectronicMode
                                                                )?.length > 0
                                                                    ? handleShowDocuments(
                                                                          values?.servicesDocuments,
                                                                          vittaPaymentsElectronicMode
                                                                      )?.map(
                                                                          (
                                                                              itemDocument,
                                                                              indexDocument
                                                                          ) => (
                                                                              <Row
                                                                                  key={
                                                                                      indexDocument
                                                                                  }
                                                                                  className="mb-3 align-items-center"
                                                                              >
                                                                                  {!itemDocument?.id ? (
                                                                                      <>
                                                                                          <Col sm="5">
                                                                                              <FastField
                                                                                                  name={`servicesDocuments[${indexDocument}].type`}
                                                                                                  component={
                                                                                                      ReactSelectInput
                                                                                                  }
                                                                                                  isDisabled={
                                                                                                      itemDocument?.id
                                                                                                          ? true
                                                                                                          : false
                                                                                                  }
                                                                                                  placeholder="Selecione..."
                                                                                                  options={
                                                                                                      optionsDocuments
                                                                                                  }
                                                                                                  defaultValue={
                                                                                                      itemDocument?.type
                                                                                                  }
                                                                                              />
                                                                                          </Col>

                                                                                          <Col sm="3">
                                                                                              <Field
                                                                                                  name={`servicesDocuments[${indexDocument}].file`}
                                                                                                  type="file"
                                                                                                  component={
                                                                                                      FileInput
                                                                                                  }
                                                                                                  value={
                                                                                                      undefined
                                                                                                  }
                                                                                                  onChange={e => {
                                                                                                      setFieldValue(
                                                                                                          `servicesDocuments[${indexDocument}].file`,
                                                                                                          e
                                                                                                              ?.currentTarget
                                                                                                              ?.files[0]
                                                                                                      )
                                                                                                  }}
                                                                                              />
                                                                                          </Col>

                                                                                          <Col
                                                                                              sm="1"
                                                                                              align="end"
                                                                                          >
                                                                                              <Button
                                                                                                  align="start"
                                                                                                  color="danger"
                                                                                                  disabled={
                                                                                                      controlStateDocuments
                                                                                                  }
                                                                                                  className="inner"
                                                                                                  onClick={async () => {
                                                                                                      try {
                                                                                                          if (
                                                                                                              itemDocument?.id
                                                                                                          ) {
                                                                                                              dispatch(
                                                                                                                  showSweetAlertDialog(
                                                                                                                      {
                                                                                                                          title:
                                                                                                                              "Atenção ",
                                                                                                                          text:
                                                                                                                              "Tem certeza que deseja excluir este registro?",
                                                                                                                          type:
                                                                                                                              "warning",
                                                                                                                          onConfirmAction: async () => {
                                                                                                                              setControlStateDocuments(
                                                                                                                                  true
                                                                                                                              )
                                                                                                                              await deleteProfessionalDocuments(
                                                                                                                                  itemDocument?.id,
                                                                                                                                  vittaPaymentsElectronicMode
                                                                                                                              )
                                                                                                                              arrayHelpersDocuments.remove(
                                                                                                                                  indexDocument
                                                                                                                              )
                                                                                                                              dispatch(
                                                                                                                                  showToast(
                                                                                                                                      {
                                                                                                                                          title:
                                                                                                                                              "Sucesso",
                                                                                                                                          text:
                                                                                                                                              "Registro excluído!",
                                                                                                                                          type:
                                                                                                                                              "success",
                                                                                                                                      }
                                                                                                                                  )
                                                                                                                              )
                                                                                                                              setControlStateDocuments(
                                                                                                                                  false
                                                                                                                              )
                                                                                                                          },
                                                                                                                          showCancel: true,
                                                                                                                          showConfirm: true,
                                                                                                                          cancelBtnText:
                                                                                                                              "Não",
                                                                                                                          confirmBtnText:
                                                                                                                              "Sim",
                                                                                                                      }
                                                                                                                  )
                                                                                                              )
                                                                                                          } else {
                                                                                                              setControlStateDocuments(
                                                                                                                  true
                                                                                                              )
                                                                                                              const copy = [
                                                                                                                  ...values.servicesDocuments,
                                                                                                              ]
                                                                                                              let newData = copy?.filter(
                                                                                                                  e =>
                                                                                                                      !e?.identifier_id ||
                                                                                                                      e?.identifier_id !=
                                                                                                                          itemDocument?.identifier_id
                                                                                                              )

                                                                                                              setFieldValue(
                                                                                                                  "servicesDocuments",
                                                                                                                  newData
                                                                                                              )

                                                                                                              //   arrayHelpersDocuments.remove(
                                                                                                              //       indexDocument
                                                                                                              //   )
                                                                                                              setControlStateDocuments(
                                                                                                                  false
                                                                                                              )
                                                                                                          }
                                                                                                      } catch (e) {
                                                                                                          setControlStateDocuments(
                                                                                                              false
                                                                                                          )
                                                                                                          console.log(
                                                                                                              e
                                                                                                          )
                                                                                                          dispatch(
                                                                                                              showSweetAlertDialog(
                                                                                                                  {
                                                                                                                      title:
                                                                                                                          "Ops... ",
                                                                                                                      text:
                                                                                                                          "Houve um erro ao processar sua solicitação, tente novamente mais tarde.",
                                                                                                                      type:
                                                                                                                          "error",
                                                                                                                  }
                                                                                                              )
                                                                                                          )
                                                                                                      }
                                                                                                  }}
                                                                                              >
                                                                                                  <i className="fas fa-trash-alt fa-lg"></i>
                                                                                              </Button>
                                                                                          </Col>
                                                                                          <Col sm="2">
                                                                                              <Button
                                                                                                  disabled={
                                                                                                      controlStateDocuments
                                                                                                  }
                                                                                                  align="start"
                                                                                                  color="info"
                                                                                                  className="inner"
                                                                                                  onClick={async () => {
                                                                                                      try {
                                                                                                          handleDocuments(
                                                                                                              values?.id,
                                                                                                              values
                                                                                                                  ?.servicesDocuments[
                                                                                                                  indexDocument
                                                                                                              ]
                                                                                                          )
                                                                                                      } catch (e) {
                                                                                                          console.log(
                                                                                                              e
                                                                                                          )
                                                                                                      }
                                                                                                  }}
                                                                                              >
                                                                                                  Enviar
                                                                                                  Documento
                                                                                              </Button>
                                                                                          </Col>
                                                                                      </>
                                                                                  ) : (
                                                                                      <>
                                                                                          <Col sm="1">
                                                                                              <FastField
                                                                                                  name={`servicesDocuments[${indexDocument}].id`}
                                                                                                  component={
                                                                                                      TextInput
                                                                                                  }
                                                                                                  style={{
                                                                                                      backgroundColor:
                                                                                                          "#f2f2f2",
                                                                                                  }}
                                                                                                  disabled={
                                                                                                      true
                                                                                                  }
                                                                                                  defaultValue={
                                                                                                      itemDocument?.id
                                                                                                  }
                                                                                              />
                                                                                          </Col>
                                                                                          <Col sm="5">
                                                                                              <Field
                                                                                                  name={`servicesDocuments[${indexDocument}].type`}
                                                                                                  component={
                                                                                                      ReactSelectInput
                                                                                                  }
                                                                                                  isDisabled={
                                                                                                      true
                                                                                                  }
                                                                                                  placeholder="Selecione..."
                                                                                                  options={[]}
                                                                                                  defaultValue={
                                                                                                      itemDocument?.type
                                                                                                  }
                                                                                              />
                                                                                          </Col>
                                                                                          {/* <Col
                                                                                              sm="3"
                                                                                              align="start"
                                                                                              className="mr-2"
                                                                                          >
                                                                                              {renderStatusDocument(
                                                                                                  itemDocument?.status
                                                                                              )}
                                                                                          </Col> */}
                                                                                          <Col
                                                                                              sm="1"
                                                                                              align="end"
                                                                                          >
                                                                                              <Button
                                                                                                  type="button"
                                                                                                  disabled={
                                                                                                      controlStateDocuments
                                                                                                  }
                                                                                                  className="btn btn-info"
                                                                                                  onClick={() => {
                                                                                                      handleDownloadDocument(
                                                                                                          itemDocument.id
                                                                                                      )
                                                                                                  }}
                                                                                              >
                                                                                                  <i className="fas fa-download fa-2x text-white"></i>
                                                                                              </Button>
                                                                                          </Col>

                                                                                          <Col
                                                                                              sm="1"
                                                                                              align="start"
                                                                                          >
                                                                                              <Button
                                                                                                  align="start"
                                                                                                  color="danger"
                                                                                                  disabled={
                                                                                                      itemDocument?.status !=
                                                                                                      PROFESSIONAL_DOCUMENTS_STATUS.VALID
                                                                                                          ? controlStateDocuments
                                                                                                          : true
                                                                                                  }
                                                                                                  className="inner"
                                                                                                  onClick={async () => {
                                                                                                      try {
                                                                                                          if (
                                                                                                              itemDocument?.id
                                                                                                          ) {
                                                                                                              dispatch(
                                                                                                                  showSweetAlertDialog(
                                                                                                                      {
                                                                                                                          title:
                                                                                                                              "Atenção ",
                                                                                                                          text:
                                                                                                                              "Tem certeza que deseja excluir este registro?",
                                                                                                                          type:
                                                                                                                              "warning",
                                                                                                                          onConfirmAction: async () => {
                                                                                                                              setControlStateDocuments(
                                                                                                                                  true
                                                                                                                              )
                                                                                                                              await deleteProfessionalDocuments(
                                                                                                                                  itemDocument?.id,
                                                                                                                                  vittaPaymentsElectronicMode
                                                                                                                              )
                                                                                                                              arrayHelpersDocuments.remove(
                                                                                                                                  indexDocument
                                                                                                                              )
                                                                                                                              dispatch(
                                                                                                                                  showToast(
                                                                                                                                      {
                                                                                                                                          title:
                                                                                                                                              "Sucesso",
                                                                                                                                          text:
                                                                                                                                              "Registro excluído!",
                                                                                                                                          type:
                                                                                                                                              "success",
                                                                                                                                      }
                                                                                                                                  )
                                                                                                                              )
                                                                                                                              setControlStateDocuments(
                                                                                                                                  false
                                                                                                                              )
                                                                                                                          },
                                                                                                                          showCancel: true,
                                                                                                                          showConfirm: true,
                                                                                                                          cancelBtnText:
                                                                                                                              "Não",
                                                                                                                          confirmBtnText:
                                                                                                                              "Sim",
                                                                                                                      }
                                                                                                                  )
                                                                                                              )
                                                                                                          }
                                                                                                      } catch (e) {
                                                                                                          console.log(
                                                                                                              e
                                                                                                          )
                                                                                                      }
                                                                                                  }}
                                                                                              >
                                                                                                  <i className="fas fa-trash-alt fa-2x"></i>
                                                                                              </Button>
                                                                                          </Col>
                                                                                      </>
                                                                                  )}
                                                                              </Row>
                                                                          )
                                                                      )
                                                                    : null}
                                                                {vittaPaymentsElectronicMode &&
                                                                !values?.vittapag_id_id ? (
                                                                    <div>
                                                                        <strong className="text-danger">
                                                                            {" "}
                                                                            <i className="fas fa-info-circle"></i>{" "}
                                                                            Necessário
                                                                            sincronizar
                                                                            o
                                                                            profissional
                                                                            com
                                                                            Vittá
                                                                            Pagamentos.
                                                                        </strong>
                                                                    </div>
                                                                ) : null}
                                                                <Button
                                                                    disabled={
                                                                        vittaPaymentsElectronicMode &&
                                                                        !values?.vittapag_id_id
                                                                    }
                                                                    name="buttondocuments"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        arrayHelpersDocuments.push(
                                                                            {
                                                                                ...defaultDocumentService,
                                                                                vitta_payments_electronic_mode: vittaPaymentsElectronicMode,
                                                                                identifier_id: uuidv4(),
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>

                                                                {/* <Col
                                                                    sm="12"
                                                                    align="end"
                                                                >
                                                                    <Button
                                                                        type="button"
                                                                        color="primary"
                                                                        
                                                                        onClick={() => {
                                                                            handleDocuments(
                                                                                values?.id,
                                                                                values?.servicesDocuments
                                                                            )
                                                                        }}
                                                                    >
                                                                        Salvar
                                                                        Documentos{" "}
                                                                    </Button>
                                                                </Col> */}
                                                            </>
                                                        )}
                                                    />
                                                </CardBody>
                                            </Card>
                                            <Card></Card>
                                            <Card></Card>
                                        </TabPane>
                                    </TabContent>
                                    <div
                                        align="end"
                                        className={
                                            activeTab == 5 ||
                                            activeTab == 3 ||
                                            activeTab == 4
                                                ? "d-none"
                                                : ""
                                        }
                                    >
                                        {controlState ? (
                                            <Button
                                                color="primary"
                                                type="button"
                                                disabled
                                            >
                                                Salvar{" "}
                                                <i className="fas fa-lock fa-lg ml-1"></i>
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                type="submit"
                                                disabled={controlState ? true : false}
                                            >
                                                Salvar
                                            </Button>
                                        )}

                                        <Link
                                            to="/profissionais"
                                            className="btn btn-danger ml-1"
                                        >
                                            Cancelar
                                        </Link>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </Container>
            </div>
            <ModalImage
                show={controlModalImage.show}
                data={controlModalImage.data ? controlModalImage.data : null}
                onClose={async data => {
                    try {
                        const formDataProf = new FormData()
                        if (data?.src && data?.objectImage) {
                            formDataProf.append(
                                "image",
                                data?.objectImage,
                                data?.objectImage?.name
                            )

                            let url = await uploadImageProfessional(
                                id,
                                formDataProf
                            )

                            if (!url.public_url) {
                                dispatch(
                                    showSweetAlertDialog({
                                        title: "Ops... ",
                                        text:
                                            "Houve um erro ao processar sua solicitação, verifique o formato do arquivo enviado ou tente novamente mais tarde.",
                                        type: "error",
                                    })
                                )
                                return
                            }

                            setControlModalImage({
                                show: false,
                                data: data?.src ? data?.src : url.public_url,
                            })
                            dispatch(
                                showToast({
                                    title: "Sucesso",
                                    text: "Imagem Atualizada!",
                                    type: "success",
                                })
                            )
                        } else {
                            setControlModalImage({
                                show: false,
                                data: controlModalImage.data,
                            })
                        }
                    } catch (e) {
                        console.log(e)
                        if (e?.response?.data?.error_code == "file_invalid") {
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Formato inválido ! ",
                                    text:
                                        "Verifique o arquivo e tente novamente.",
                                    type: "error",
                                })
                            )
                            return
                        } else {
                            dispatch(
                                showSweetAlertDialog({
                                    title: "Ops... ",
                                    text:
                                        "Houve um erro ao processar sua solicitação, verifique o formato do arquivo enviado ou tente novamente mais tarde.",
                                    type: "error",
                                })
                            )
                        }
                    }
                }}
            ></ModalImage>
            <ModalLogs
                show={modalLogs.show}
                data={modalLogs.data}
                onClose={response => {
                    setModalLogs({
                        show: false,
                        data: null,
                    })
                }}
            ></ModalLogs>
            <ModalInfo
                show={modalInfo.show}
                data={modalInfo.data}
                onClose={response => {
                    setModalInfo({
                        show: false,
                        data: null,
                    })
                }}
            ></ModalInfo>
            <ModalAccountBank
                professional_id={id}
                show={modalAccountBank.show}
                data={modalAccountBank.data}
                onClose={refresh => {
                    setModalAccountBank({
                        show: false,
                        data: null,
                    })
                    if (refresh) {
                        dispatch(
                            showToast({
                                title: "Sucesso",
                                text: "Ação bem sucedida!",
                                type: "success",
                            })
                        )
                        loadDataEditPage()
                    }
                }}
                vitta_payments_electronic_mode={vittaPaymentsElectronicMode}
            ></ModalAccountBank>
        </React.Fragment>
    )
}

export default RegistrationProfessionals
