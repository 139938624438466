import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap"
const statusTranslation = {
    PENDING: { label: "Pendente", className: "badge-soft-warning" },
    PAID: { label: "Pago", className: "badge-soft-success" },
    CONFIRMED: { label: "Confirmado", className: "badge-soft-success" },
    LIQUIDATED: { label: "Liquidado", className: "badge-soft-success" },
    CANCELED: { label: "Cancelado", className: "badge-soft-danger" },
    REVERSED: { label: "Revertido", className: "badge-soft-danger" },
    DISPUTE: { label: "Em Disputa", className: "badge-soft-info" },
    CHARGEDBACK: { label: "Chargeback", className: "badge-soft-info" },
    SUCCEEDED: { label: "Bem-sucedido", className: "badge-soft-info" },
    FAILED: { label: "Falhou", className: "badge-soft-danger" },
    PRE_AUTHORIZED: { label: "Pré-Autorizado", className: "badge-soft-info" },
    REFUNDED: { label: "Reembolsado", className: "badge-soft-info" },
    NEW: { label: "Novo", className: "badge-soft-info" },
    PARTIAL_REFUNDED: { label: "Parcialmente Reembolsado", className: "badge-soft-info" },
};
const dateFormat = (date) => {
    if (!date) return null;
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day}/${month}/${year}`;
};

const ListStatementColumns = () => {
    return [
        {
            dataField: "created_at",
            text: "Data",
            formatter: (cellContent, row) => {
                return dateFormat(row?.created_at)
            },
        },
        {
            isDummyField: true,
            text: "Tipo",
            formatter: (id, row) => {
                const isComplemento = row?.transfer?.external_reference?.includes('COMPREPASSE');
                if (row?.type_origin == '1') {
                    if (row.transfer) {
                        if (row.transfer.type == '1') {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>Transferência {row?.amount < 0 ? "Enviada" : "Recebida"} - Entre Contas</span>
                                    {isComplemento && (
                                        <span style={{
                                            display: 'inline-block',
                                            marginTop: 4,
                                            padding: '2px 6px',
                                            fontSize: '0.75em',
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: 4,
                                            color: '#333',
                                            alignSelf: 'flex-start'
                                        }}>
                                            Complemento do repasse
                                        </span>
                                    )}
                                </div>
                            );
                        } else if (row.transfer.type == '2') {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>Transferência {row?.amount < 0 ? "Enviada" : "Recebida"} - Banco Externo</span>
                                    {isComplemento && (
                                        <span style={{
                                            display: 'inline-block',
                                            marginTop: 4,
                                            padding: '2px 6px',
                                            fontSize: '0.75em',
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: 4,
                                            color: '#333',
                                            alignSelf: 'flex-start'
                                        }}>
                                            Complemento do repasse
                                        </span>
                                    )}
                                </div>
                            );
                        }
                    }

                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>Transferência {row?.amount < 0 ? "Enviada" : "Recebida"}</span>
                            {isComplemento && (
                                <span style={{
                                    display: 'inline-block',
                                    marginTop: 4,
                                    padding: '2px 6px',
                                    fontSize: '0.75em',
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: 4,
                                    color: '#333',
                                    alignSelf: 'flex-start'
                                }}>
                                    Complemento do repasse
                                </span>
                            )}
                        </div>
                    );
                }
                return <span>Pagamento Recebido</span>;
            }
        },
        {
            isDummyField: true,
            text: "Valor",
            formatter: (cellContent, row) => (
                <div style={{ color: row?.amount < 0 ? 'red' : 'green' }}>
                    {(row?.amount / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
            ),
        },
        {
            text: "Ped. Venda",
            formatter: (cellContent, row) => (
                <>
                {row?.transfer?.external_reference?.includes('COMPREPASSE') ? (
                    (() => {
                        const parts = row.transfer.external_reference.split('|');
                        const pedidoId = parts[1];
                        return (
                            <Link
                                to={"/pedidos/" + pedidoId}
                                target="_blank"
                                className="mr-3 text-primary"
                            >
                                # {pedidoId}
                            </Link>
                        );
                    })()
                ) : (
                    row?.sales_transactions_recipient_receivables?.recipient?.payment_info
                        ?.map((payment, index) => (
                            <Link
                                key={index}
                                to={"/pedidos/" + payment?.sales_order?.id}
                                target="_blank"
                                className="mr-3 text-primary"
                            >
                                # {payment?.sales_order?.id}
                            </Link>
                        ))
                        .reduce((prev, curr) => [prev, ', ', curr])
                )}
            </>
            ),
        },

    ]
}

export default ListStatementColumns
