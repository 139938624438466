import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
  Spinner,
} from "reactstrap";
import { listSalesVouchers } from "services/vitta-core/sales-vouchers";

const SalesVouchersModal = ({ isOpen, toggle, salesOrderId }) => {
  const [salesVouchers, setSalesVouchers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const voucherBaseUrl =
    process.env.REACT_APP_PARTNERS_WEB_URL;

    console.log("voucherBaseUrl",voucherBaseUrl);

  useEffect(() => {
    if (isOpen && salesOrderId) {
      fetchSalesVouchers();
    }
  }, [isOpen, page, salesOrderId]);

  const fetchSalesVouchers = async () => {
    setLoading(true);
    try {
      const response = await listSalesVouchers(salesOrderId, page);
      setSalesVouchers(response || []);
      setTotalPages(response?.total_pages || 1);
    } catch (error) {
      console.error("Erro ao buscar vouchers:", error);
    } finally {
      setLoading(false);
    }
  };

  const STATUS_LABELS = {
    pending: "Pendente",
    executed: "Executado",
    canceled: "Cancelado",
  };

  const translateStatus = (status) => STATUS_LABELS[status] || "Desconhecido";

  const handlePagination = (direction) => {
    if (direction === "next" && page < totalPages) setPage(page + 1);
    if (direction === "prev" && page > 1) setPage(page - 1);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Sales Vouchers</ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : salesVouchers.length > 0 ? (
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Código do Voucher</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {salesVouchers.map((voucher) => (
                <tr key={voucher.id}>
                  <td>{voucher.id}</td>
                  <td>{voucher.voucher_code}</td>
                  <td>{translateStatus(voucher.status)}</td>
                  <td>
                  <Button
                    tag="a"
                    href={`${voucherBaseUrl}/voucher/${voucher.voucher_code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="secondary"
                    size="sm"
                    title="Visualizar Voucher"
                    >
                    <i className="fas fa-link"></i> Abrir
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="text-center">Nenhum voucher encontrado.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => handlePagination("prev")}
          disabled={page === 1}
        >
          Anterior
        </Button>
        <Button
          color="secondary"
          onClick={() => handlePagination("next")}
          disabled={page === totalPages}
        >
          Próximo
        </Button>
        <Button color="danger" onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SalesVouchersModal;
