import React, { useState, useEffect, useRef, useMemo } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Card,
    Input,
    Label,
    Modal,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { useSelector, useDispatch } from "react-redux"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useHistory } from "react-router-dom"
import {
    loadDataEditform,
    create,
    update,
} from "services/vitta-core/appointment-book-lock"
import { Formik, Field, Form, FieldArray } from "formik"
import Select from "react-select"
import { search as ScheduleServiceSearch } from "services/vitta-core/appointment-book"
import { search as UnitServiceSearch } from "services/vitta-core/unit"
import { element } from "prop-types"
import debounce from "debounce-promise"
const defaultValues = {
    unit_id: "",
    appointment_book_id: "",
    date_start: "",
    date_end: "",
    time_start: "",
    time_end: "",
}

const TextdateInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="date" {...field} {...props} />

const TexttimeInput2 = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="time" {...field} {...props} />
const TexttimeInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="time" {...field} {...props} />

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const SelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
        ref={props.Ref}
    />
)
const RegistrationScheduleLock = () => {
    const [pageData, setPageData] = useState({
        formDefaultValues: defaultValues,
    })
    const Scope = useSelector(state => state.scope)
    const User = useSelector(state => state.auth.access.user)
    const { id } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const unitId = useRef()
    useEffect(() => {
        if (id) loadDataEditPage()
        // else loadDataAddPage()
    }, [])

    const handleStatusConfirmation = async (data, type) => {
        dispatch(
            showSweetAlertDialog({
                title: "Tem certeza ? ",
                text: "Você irá bloquear todas as agendas dessa Unidade !",
                type: "warning",
                onConfirmAction: () => {
                    handleSubmitUnit(data, type)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Bloquear Agendas",
            })
        )
    }
    const handleSubmitUnit = async (data, type) => {
        const dataSubmit = {
            date_start:
                data.date_start && data.time_start
                    ? data.date_start + " " + data.time_start + ":00"
                    : null,
            date_end:
                data.date_end && data.time_end
                    ? data.date_end + " " + data.time_end + ":00"
                    : null,
            unit_id: data.unit_id.value,
            type: "unidade",
        }

        try {
            await create(dataSubmit, dataSubmit.unit_id)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )

            history.push("/bloqueio-agendas")
        } catch (e) {
            
            if(e?.response?.data?.error_code =="date_invalid")
            {
                dispatch(
                    showSweetAlertDialog({
                        title: "Data inválida!",
                        text:
                            "Houve um erro ao processar sua solicitação, verifique as datas e os horários preenchidos e tente novamente.",
                        type: "error",
                    })
                )
                return;
            }
            if(e?.response?.data?.error_code =="record_existent")
            {
                dispatch(
                    showSweetAlertDialog({
                        title: "Regra de bloqueio já criada!",
                        text:
                            "Houve um erro ao processar sua solicitação, verifique os dados inseridos.",
                        type: "error",
                    })
                )
                return;
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    const onSubmit = async (data, actions, e) => {
        let appointment_book = []

        for (let i = 0; i < data.appointment_book_id?.length; i++) {
            appointment_book.push(data.appointment_book_id[i]?.value)
        }

        const dataSubmit = {
            date_start:
                data.date_start && data.time_start
                    ? data.date_start + " " + data.time_start + ":00"
                    : null,
            date_end:
                data.date_end && data.time_end
                    ? data.date_end + " " + data.time_end + ":00"
                    : null,
            appointment_book_id: data.appointment_book_id,
            unit_id: data.unit_id.value,
        }

        try {
            if (dataSubmit.appointment_book_id.length > 0) {
                await create(dataSubmit, dataSubmit.unit_id)

                dispatch(
                    showToast({
                        title: "Sucesso!",
                        text: "Registro salvo com Sucesso!",
                        type: "success",
                    })
                )

                history.push("/bloqueio-agendas")
            }
        } catch (e) {
          
            if(e?.response?.data?.error_code =="date_invalid")
            {
                dispatch(
                    showSweetAlertDialog({
                        title: "Data inválida!",
                        text:
                            "Houve um erro ao processar sua solicitação, verifique as datas e os horários preenchidos e tente novamente.",
                        type: "error",
                    })
                )
                return;
            }
            if(e?.response?.data?.error_code =="record_existent")
            {
                dispatch(
                    showSweetAlertDialog({
                        title: "Regra de bloqueio já criada!",
                        text:
                            "Houve um erro ao processar sua solicitação, verifique os dados inseridos.",
                        type: "error",
                    })
                )
                return;
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        "Houve um erro ao processar sua solicitação, tente novamente mais tarde...",
                    type: "error",
                })
            )
            console.log(e)
        }
    }

    // const loadDataEditPage = async () => {
    //     try {
    //         let data = await loadDataEditform(id)

    //         setPageData({
    //             formDefaultValues: {
    //                 appointment_book_id: data.appointment_book.name,
    //                 date_start: data.data_start,
    //                 date_end: data.data_end,
    //                 time_start: data.time_start,
    //                 time_end: data.time_end,
    //             },
    //         })
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }
    const loadScheduleInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!unitId || !unitId.current || !unitId.current.props.value)
                    return resolve([])

                let response = await ScheduleServiceSearch(
                    {
                        unit_id: unitId?.current?.props?.value?.value,
                        term: inputValue,
                    },
                    1,
                    10
                )
                if (!response) return

                let schedule_values = []

                //Vazio
                // schedule_values.push({
                //     label: "Não existe nenhuma agenda nessa unidade.",
                //     value: null,
                // })

                response.data.forEach(element => {
                    schedule_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(schedule_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const loadUnits = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await UnitServiceSearch(
                    { term: inputValue },
                    // 1,
                    // 20
                )

                let schedule_values = []

                //Vazio

                response.data.forEach(element => {
                    schedule_values.push({
                        label:
                            `${element.id} - ` +
                            element.name +
                            (element.cpf ? ` <${element.cpf}>` : ""),
                        value: element.id,
                    })
                })

                return resolve(schedule_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsSchedule = debounce(loadScheduleInputOptions, 600)
    const debounceOptionsUnit = debounce(loadUnits, 600)

    const handleOptionSchedule = status => {
        if (status === "agenda") {
            setPageData({
                formDefaultValues: {
                    status: "agenda",
                },
            })
        }
        if (status === "unidade") {
            setPageData({
                formDefaultValues: {
                    status: "unidade",
                },
            })
        }
    }
    const unitsOptions = useMemo(() => {
        if (!User?.units) return []

        let options_list = []
        User.units.forEach(element => {
            options_list.push({
                label: `#${element.id} ` + element.name?.substr(0, 40),
                value: element.id,
            })
        })
        return options_list
    }, [User.units])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Formik
                        initialValues={pageData.formDefaultValues}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                    >
                        {({ values }) => (
                            <Form>
                                <Row className="justify-content-center">
                                    <Col xs="12">
                                        <Card>
                                            <CardBody className="mb-4">
                                                <CardTitle className="mb-4">
                                                    Bloqueio de Agenda
                                                </CardTitle>

                                                <Row className="mb-4">
                                                    <Col sm="12">
                                                        <span>
                                                            Qual a modalidade de bloqueio ?{" "}
                                                            <Button
                                                                color={
                                                                    values?.status ===
                                                                    "agenda"
                                                                        ? "primary"
                                                                        : "secondary"
                                                                }
                                                                className="mr-1 ml-4 "
                                                                onClick={() => {
                                                                    handleOptionSchedule(
                                                                        "agenda"
                                                                    )
                                                                }}
                                                            >
                                                                Agenda <i className="fas fa-calendar-plus fa-lg ml-1"></i>
                                                            </Button>{" "}
                                                            <Button
                                                                color={
                                                                    values?.status ===
                                                                    "unidade"
                                                                        ? "primary"
                                                                        : "secondary"
                                                                }
                                                                onClick={() => {
                                                                    handleOptionSchedule(
                                                                        "unidade"
                                                                    )
                                                                }}
                                                            >
                                                                Unidade <i className="fas fa-house-user fa-lg ml-1"></i>
                                                            </Button>{" "}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                {values.status &&
                                                values.status === "agenda" ? (
                                                    <div>
                                                        <Row>
                                                            <Col md="12">
                                                                Nesta modalidade de Bloqueio, serão bloqueadas somente as agendas selecionadas.
                                                                <br/>
                                                                <br/>
                                                                </Col>
                                                            </Row>
                                                        {" "}
                                                        <Row>
                                                            <Col md="6">
                                                                <Label>
                                                                    Unidade
                                                                </Label>

                                                                <Field
                                                                    name="unit_id"
                                                                    placeholder="Selecione a unidade"
                                                                    component={
                                                                        SelectInput
                                                                    }
                                                                    Ref={unitId}
                                                                    options={
                                                                        unitsOptions
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col md="6">
                                                                <Label>
                                                                    Agendas
                                                                </Label>

                                                                <Field
                                                                    name="appointment_book_id"
                                                                    placeholder="Selecione a Agenda"
                                                                    defaultOptions
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                    loadOptions={
                                                                        debounceOptionsSchedule
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                    defaultValue={
                                                                        values.appointment_book_id
                                                                            ? values.appointment_book_id
                                                                            : ""
                                                                    }
                                                                    noOptionsMessage={() =>
                                                                        "Sem Resultados. Digite para Buscar (campo unidade precisa estar preenchido)"
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col
                                                                md="6"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Data de:
                                                                </Label>
                                                                <Field
                                                                    placeholder="Digite..."
                                                                    name="date_start"
                                                                    component={
                                                                        TextdateInput
                                                                    }
                                                                    defaultValue={
                                                                        values.date_start
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                md="6"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Hora de:
                                                                </Label>
                                                                <Field
                                                                    placeholder="Digite..."
                                                                    name="time_start"
                                                                    component={
                                                                        TexttimeInput2
                                                                    }
                                                                    defaultValue={
                                                                        values.time_start
                                                                            ? values.time_start
                                                                            : ""
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col
                                                                md="6"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Data Até:
                                                                </Label>
                                                                <Field
                                                                    placeholder="Digite..."
                                                                    name="date_end"
                                                                    component={
                                                                        TextdateInput
                                                                    }
                                                                    defaultValue={
                                                                        values.date_end
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                md="6"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Hora Até:
                                                                </Label>
                                                                <Field
                                                                    placeholder="Digite..."
                                                                    name="time_end"
                                                                    component={
                                                                        TexttimeInput2
                                                                    }
                                                                    defaultValue={
                                                                        values.time_end
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {values.status &&
                                                values.status === "unidade" ? (
                                                    <div>
                                                         <Row>
                                                            <Col md="12">
                                                                Nesta modalidade de Bloqueio, serão bloqueadas todas as agendas da unidade selecionada.
                                                                <br/>
                                                                <br/>
                                                                </Col>
                                                            </Row>
                                                        {" "}
                                                        <Row>
                                                            <Col md="6">
                                                                <Label>
                                                                    Unidade
                                                                </Label>

                                                                <Field
                                                                    name="unit_id"
                                                                    placeholder="Selecione a Unidade"
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    loadOptions={
                                                                        debounceOptionsUnit
                                                                    }
                                                                    // defaultValue={values.unit_id}
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                    defaultValue={
                                                                        values.unit_id
                                                                            ? values.unit_id
                                                                            : ""
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col
                                                                md="6"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Data de:
                                                                </Label>
                                                                <Field
                                                                    placeholder="Digite..."
                                                                    name="date_start"
                                                                    component={
                                                                        TextdateInput
                                                                    }
                                                                    defaultValue={
                                                                        values.date_start
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                md="6"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Hora de:
                                                                </Label>
                                                                <Field
                                                                    placeholder="Digite..."
                                                                    name="time_start"
                                                                    component={
                                                                        TexttimeInput2
                                                                    }
                                                                    defaultValue={
                                                                        values.time_start
                                                                            ? values.time_start
                                                                            : ""
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col
                                                                md="6"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Data Até:
                                                                </Label>
                                                                <Field
                                                                    placeholder="Digite..."
                                                                    name="date_end"
                                                                    component={
                                                                        TextdateInput
                                                                    }
                                                                    defaultValue={
                                                                        values.date_end
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                md="6"
                                                                className="mt-2"
                                                            >
                                                                <Label>
                                                                    Hora Até:
                                                                </Label>
                                                                <Field
                                                                    placeholder="Digite..."
                                                                    name="time_end"
                                                                    component={
                                                                        TexttimeInput2
                                                                    }
                                                                    defaultValue={
                                                                        values.time_end
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {/* fim linha */}
                                            </CardBody>
                                        </Card>
                                        {values.status &&
                                        values.status === "agenda" ? (
                                            <div align="end">
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    className="ml-1 "
                                                >
                                                    Salvar
                                                </Button>
                                                <Link
                                                    to="/bloqueio-agendas"
                                                    className="btn btn-danger ml-1"
                                                >
                                                    Cancelar
                                                </Link>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {values.status &&
                                        values.status === "unidade" ? (
                                            <div align="end">
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="ml-1 "
                                                    onClick={() => {
                                                        handleStatusConfirmation(
                                                            values,
                                                            "unidade"
                                                        )
                                                    }}
                                                >
                                                    Salvar
                                                </Button>
                                                <Link
                                                    to="/bloqueio-agendas"
                                                    className="btn btn-danger ml-1"
                                                >
                                                    Cancelar
                                                </Link>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default RegistrationScheduleLock
