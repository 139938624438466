import React from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const PaymentForecast = ({ forecastRules, transactionMode }) => {
  return (
    <div style={{ marginTop:'20px', padding: '10px', borderRadius: '5px', backgroundColor: '#f5f5f5', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', width: '100%', margin: '10px auto' }}>
      <h3 style={{ fontSize: '18px', fontWeight: 'bold', color: '#333', marginBottom: '10px' }}> {transactionMode == 1 ? "Recebimento  em Dinheiro" :"Previsão de Recebimento via Transferência Bancária"}</h3>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {forecastRules.map((forecast, idx) => {
          const formattedDate = format(parseISO(forecast.forecast_date), 'dd/MM/yyyy');
          return (
            <div key={idx} style={{ display: 'flex', gap:'30px', alignItems: 'start', padding: '5px', borderBottom: '1px solid #ddd' }}>
              <div style={{ fontSize: '14px', color: '#555' }}>
                {formattedDate}
              </div>
              <div style={{ fontSize: '14px', color: '#555' }}>
                R$ {(forecast.total_value / 100).toFixed(2)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentForecast;