import request from './config/request';

const listSalesVouchers = async (salesOrderId, page = 1, size = 10) => {
    return request({
        method: "GET",
        url: `${process.env.REACT_APP_VITTA_SERVICES_HOST}/vittanet/sales-orders/${salesOrderId}/sales-vouchers?page=${page}&size=${size}`,
        vtAuth: true,
    });
};


export { listSalesVouchers };
