import React, { useState, useEffect, use } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import { Link, useLocation } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { useHistory } from "react-router-dom"
import { showDemonstrative } from "services/vitta-core/professional-transfer-invoices"
import PaymentForecast from "./components/PaymentForecastComponent"
import { parseISO, isAfter, isEqual } from 'date-fns';


const DemonstrativeReport = ({ filter }) => {
    const history = useHistory()

    const [pageData, setPageData] = useState({
        header: null,
        data: [],
        totalPaid: 0,
        total_note_electronic_paid: 0,
        total_note_manual_paid: 0,
    })
    const [controlState, setControlState] = useState(false)
    useEffect(() => {
        const loadData = async () => {
            try {
                setControlState(true)
                let search = location.search
                const params = new URLSearchParams(search)
                const professional_id = params.get("professional_id")
                const unit_id = params.get("unit_id")
                const date_exec = params.get("date_exec")

                let result = await showDemonstrative({
                    date_exec: date_exec,
                    unit_id: unit_id,
                    professional_id: professional_id,
                })

                let totalNota = 0
                let totalPaid = 0
                let total_note_electronic_paid = 0
                let total_note_manual_paid = 0
                let total_advance_receipt = 0

                for (let i = 0; i < result?.record?.length; i++) {
                    let useInvoice = result?.record[i]
                    let {
                        totalProcessingFeeManual,
                        totalProcessingFeeElectronic,
                        totalProcessingFee,
                    } = calcTotalProcessingFees(useInvoice.items, useInvoice)
                    let {
                        totalAnticipationFeeManual,
                        totalAnticipationFeeElectronic,
                        totalAnticipationFee,
                    } = calcTotalAnticipationFees(useInvoice.items, useInvoice)

                    let totalElectronicFees =
                        totalProcessingFeeElectronic +
                        totalAnticipationFeeElectronic
                    let totalManualFees =
                        totalProcessingFeeManual + totalAnticipationFeeManual

                    //totalNota += (totalByItems - totalFees)
                    //totalPaid += (useInvoice.total_note_paid - totalFees)
                    // total_note_electronic_paid += (useInvoice.total_note_electronic_paid + totalElectronicFees)

                  
                    total_note_electronic_paid +=
                        useInvoice.total_note_electronic_paid -
                        (totalElectronicFees || 0)
                    total_note_manual_paid += useInvoice.total_note_manual_paid
                    total_advance_receipt += useInvoice.total_advance_receipt
                }

                let groupedDateRules =groupPaymentDateRules (result?.record);
        
                let calcBaseboardDataResult = calcBaseboardData(result?.record);
               
                setPageData({
                    header: {
                        unit: result?.unit,
                        professional: result?.professional,
                        date_exec: date_exec,
                    },
                    totalNota: totalNota / 100,
                    totalPaid: totalPaid / 100,
                    total_note_electronic_paid:
                        total_note_electronic_paid / 100,
                    total_note_manual_paid: total_note_manual_paid / 100,
                    total_advance_receipt: total_advance_receipt / 100,
                    data: result?.record,
                    forecast_date_rules:groupedDateRules,
                    calc_baseboard_data:calcBaseboardDataResult
                })
                setControlState(false)
            } catch (e) {
                console.log(e)
                setControlState(false)
            }
        }

        loadData()
    }, [])


    const calcBaseboardData = (invoices)=>
    {
        let total_electronic_paid = 0;
        let total_manual_paid = 0;
        let total_processing_fee = 0;
        let total_anticipation_fee = 0;
        let total_liquid = 0;

        
        for(let i=0; i<invoices?.length; i++)
        {
            let invoice = invoices[i];
            total_electronic_paid += (invoice?.total_note_electronic_paid || 0);
            total_manual_paid += (invoice?.total_note_manual_paid || 0) + (invoice?.total_processing_fee_manual || 0)  + (invoice?.total_anticipation_fee_manual || 0);
            total_processing_fee += (invoice?.total_processing_fee || 0);
            total_anticipation_fee += (invoice?.total_anticipation_fee || 0);
            total_liquid += ((invoice?.total_note_paid || 0) - (invoice?.total_processing_fee_electronic || 0) - (invoice?.total_anticipation_fee_electronic || 0));
        }

        return {total_electronic_paid, total_manual_paid, total_processing_fee, total_anticipation_fee, total_liquid}
    }
    const groupPaymentDateRules = (invoices)=>
    {
        let groupedRules = {
            can_show:true,
            electronic_data:[],
            manual_data:[]
        }

        const dateSOToValid = '2025-02-27';
        for(let i=0; i<invoices?.length; i++)
        {
            let invoice = invoices[i];


            let soCreatedAt = invoice?.so?.created_at;
            if (soCreatedAt) {
                let parsedSODate = parseISO(soCreatedAt);
        
                let parsedValidDate = parseISO(dateSOToValid);
        
                if (isAfter(parsedSODate, parsedValidDate) || isEqual(parsedSODate, parsedValidDate)) {} 
                else {
                    //A data do pedido de venda não é válida
                    groupedRules.can_show = false;
                }
            }
            for(let j=0; j<invoice?.prof_transfer_invoice_payment?.length; j++)
            {
                let profPayment = invoice?.prof_transfer_invoice_payment[j];

                let forecastRules = [];
                if(typeof profPayment?.forecast_date_rules == "string")
                {
                    forecastRules = JSON.parse(profPayment?.forecast_date_rules) ?? [];
                }
                else
                {
                    if(typeof profPayment?.forecast_date_rules == "object")
                    {
                        forecastRules = profPayment?.forecast_date_rules ?? [];
                    }
                }
                

                if(forecastRules?.length <= 0) continue;

                for(let k=0; k<forecastRules?.length; k++)
                {
                    let forecastRule = forecastRules[k];

                    let verifyDateRuleExistent = null;

                    if(forecastRule?.transaction_mode == 1)
                    {
                        verifyDateRuleExistent = groupedRules.manual_data.find(e=>e?.forecast_date == forecastRule?.forecast_date && e?.transaction_mode == 1);
                        if(!verifyDateRuleExistent)
                        {
                            groupedRules.manual_data.push({
                                forecast_date: forecastRule?.forecast_date,
                                total_value:forecastRule?.liquid_amount,
                                transaction_mode: forecastRule?.transaction_mode
                            })
                        }
                    }
                    else
                    {
                        verifyDateRuleExistent = groupedRules.electronic_data.find(e=>e?.forecast_date == forecastRule?.forecast_date && e?.transaction_mode == 2);
                        if(!verifyDateRuleExistent)
                        {
                            groupedRules.electronic_data.push({
                                forecast_date: forecastRule?.forecast_date,
                                total_value:forecastRule?.liquid_amount,
                                transaction_mode: forecastRule?.transaction_mode
                            })
                        }
                    }
                  
                    if(verifyDateRuleExistent)
                    {
                        verifyDateRuleExistent.total_value += forecastRule?.liquid_amount;
                    }
                 
                }
            }
        }

        return groupedRules;
    }
    const calcTotalNota = items => {
        let total = 0
        for (let i = 0; i < items.length; i++) {
            let useItem = items[i]

            total += useItem.quantity * (useItem.price ? useItem.price : 0)
        }

        return total
    }

    const calcTotalProcessingFees = (items, invoice) => {
        let totalProcessingFeeManual = 0
        let totalProcessingFeeElectronic = 0
        let totalProcessingFee = 0
        let profTransferInvoicePayments =
            invoice?.prof_transfer_invoice_payment ?? []
        for (let i = 0; i < items.length; i++) {
            let useItem = items[i]

            totalProcessingFeeManual +=
                useItem?.sales_order_item_comission?.processing_fee_value ?? 0

            useItem.processing_fee_rules = {
                total_fee_manual: totalProcessingFeeManual,
                total_fee_electronic: 0,
                fees: [],
            }

            for (let j = 0; j < profTransferInvoicePayments.length; j++) {
                let useProfTransferInvoicePayment =
                    profTransferInvoicePayments[j]
                if (useProfTransferInvoicePayment?.processing_fee_rules) {

                    let processingFeeRules = [];
                    if (typeof useProfTransferInvoicePayment?.processing_fee_rules == "string") {
                        processingFeeRules = JSON.parse(useProfTransferInvoicePayment?.processing_fee_rules)?.fee_per_items ?? [];
                    }
                    else
                    {
                        if (typeof useProfTransferInvoicePayment?.processing_fee_rules === "object") {
                            
                            processingFeeRules = useProfTransferInvoicePayment?.processing_fee_rules?.fee_per_items ?? [];
                        }
                    }
                       

                    let rulesToFilter = processingFeeRules.filter(
                        item =>
                            item?.sales_order_item_id ==
                                useItem?.sales_order_item_comission
                                    ?.sales_order_item_id &&
                            item?.stage_id ==
                                useItem?.sales_order_item_comission?.stage_id
                    )

                    if (rulesToFilter?.length <= 0) continue

                    totalProcessingFeeElectronic += rulesToFilter?.reduce(
                        (acc, item) => acc + item?.fee_value,
                        0
                    )

                    useItem.processing_fee_rules.total_fee_electronic += totalProcessingFeeElectronic
                }
            }
        }

        totalProcessingFee =
            totalProcessingFeeManual + totalProcessingFeeElectronic

        invoice.total_processing_fee =
            (invoice?.total_processing_fee ?? 0) + (totalProcessingFee ?? 0)
        invoice.total_processing_fee_manual =
            (invoice?.total_processing_fee_manual ?? 0) +
            (totalProcessingFeeManual ?? 0)
        invoice.total_processing_fee_electronic =
            (invoice?.total_processing_fee_electronic ?? 0) +
            (totalProcessingFeeElectronic ?? 0)
        return {
            totalProcessingFeeManual,
            totalProcessingFeeElectronic,
            totalProcessingFee,
        }
    }
    const calcTotalAnticipationFees = (items, invoice) => {
        let totalAnticipationFeeManual = 0
        let totalAnticipationFeeElectronic = 0
        let totalAnticipationFee = 0
        let profTransferInvoicePayments =
            invoice?.prof_transfer_invoice_payment ?? []

        for (let i = 0; i < items.length; i++) {
            let useItem = items[i]

            totalAnticipationFeeManual +=
                useItem?.sales_order_item_comission?.anticipation_fee_value ?? 0

            useItem.anticipation_fee_rules = {
                total_fee_manual: totalAnticipationFeeManual,
                total_fee_electronic: 0,
                fees: [],
            }

            for (let j = 0; j < profTransferInvoicePayments.length; j++) {
                let useProfTransferInvoicePayment =
                    profTransferInvoicePayments[j]
                if (useProfTransferInvoicePayment?.anticipation_fee_rules) {

                    let anticipationFeeRules = [];
                    if (typeof useProfTransferInvoicePayment?.anticipation_fee_rules == "string") {
                        anticipationFeeRules = JSON.parse(useProfTransferInvoicePayment?.anticipation_fee_rules)?.fee_per_items_installments ?? [];
                    }
                    else
                    {
                    if (typeof useProfTransferInvoicePayment?.anticipation_fee_rules === "object") {
                        
                        anticipationFeeRules = useProfTransferInvoicePayment?.anticipation_fee_rules?.fee_per_items_installments ?? [];
                    }
                    }
                     

                    let rulesToFilter = anticipationFeeRules.filter(
                        item =>
                            item?.sales_order_item_id ==
                                useItem?.sales_order_item_comission
                                    ?.sales_order_item_id &&
                            item?.stage_id ==
                                useItem?.sales_order_item_comission?.stage_id
                    )

                    if (rulesToFilter?.length <= 0) continue

                    for (let k = 0; k < rulesToFilter.length; k++) {
                        let useRule = rulesToFilter[k]

                        totalAnticipationFeeElectronic += useRule?.fee_value

                        useItem.anticipation_fee_rules.total_fee_electronic +=
                            useRule?.fee_value

                        let verifyExist = useItem.anticipation_fee_rules.fees.filter(
                            item => item?.installment == useRule?.installment
                        )
                        if (verifyExist?.length <= 0) {
                            useItem.anticipation_fee_rules.fees.push(useRule)
                        } else {
                            let newObj = { ...verifyExist }
                            newObj.fee_value += useRule?.fee_value
                            useItem.anticipation_fee_rules.fees = useItem.anticipation_fee_rules.fees.map(
                                item =>
                                    item?.installment == useRule?.installment
                                        ? newObj
                                        : item
                            )
                        }
                    }
                }
            }
        }

        totalAnticipationFee =
            totalAnticipationFeeManual + totalAnticipationFeeElectronic

        invoice.total_anticipation_fee =
            (invoice.total_anticipation_fee ?? 0) + (totalAnticipationFee ?? 0)
        invoice.total_anticipation_fee_manual =
            (invoice.total_anticipation_fee_manual ?? 0) +
            (totalAnticipationFeeManual ?? 0)
        invoice.total_anticipation_fee_electronic =
            (invoice.total_anticipation_fee_electronic ?? 0) +
            (totalAnticipationFeeElectronic ?? 0)
        return {
            totalAnticipationFeeManual,
            totalAnticipationFeeElectronic,
            totalAnticipationFee,
        }
    }
    const printReport = () => {
        window.print()
    }

    return (
        <React.Fragment>
            <div className="page-content" translate="no">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Repasse Médico"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody translate="no">
                                    <div align="end">
                                        <Link
                                            to="/relatorios/repasses-medicos"
                                            className="btn btn-dark d-print-none mr-2"
                                        >
                                            <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                        </Link>
                                        <Button
                                            className="btn btn-primary d-print-none"
                                            color="primary"
                                            onClick={printReport}
                                        >
                                            Imprimir
                                        </Button>
                                        <br />
                                        <br />
                                    </div>
                                    <Row>
                                        <Col
                                            md="8"
                                            className="d-flex justify-content-start align-items-center"
                                        >
                                            <img
                                                src={logovitta}
                                                alt=""
                                                height="60px"
                                                className="mb-2"
                                            />

                                            <div className="d-flex flex-column  ">
                                                <h4>
                                                    Unidade{" "}
                                                    {
                                                        pageData.header?.unit
                                                            ?.name
                                                    }
                                                </h4>
                                                <div className="">
                                                    CNPJ:{" "}
                                                    {
                                                        pageData.header?.unit
                                                            ?.cnpj
                                                    }
                                                </div>
                                            </div>
                                        </Col>

                                        <Col
                                            md="4"
                                            className="d-flex  flex-column "
                                        >
                                            <div className="">
                                                <strong>
                                                    Data Execução Serviços:
                                                </strong>{" "}
                                                {pageData.header?.date_exec
                                                    ? pageData.header.date_exec.substr(
                                                          8,
                                                          2
                                                      ) +
                                                      "/" +
                                                      pageData.header.date_exec.substr(
                                                          5,
                                                          2
                                                      ) +
                                                      "/" +
                                                      pageData.header.date_exec.substr(
                                                          0,
                                                          4
                                                      )
                                                    : null}
                                            </div>
                                            <div className="">
                                                <strong>Profissional:</strong>{" "}
                                                {pageData.header?.professional
                                                    ? pageData.header
                                                          ?.professional?.name
                                                    : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <h4 align="center">
                                        Demonstrativo de Repasse Médico
                                    </h4>
                                    <br />
                                    <table className="table">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">ID Nota</th>
                                                <th scope="col">Data Nota</th>
                                                <th scope="col">Ped. Venda</th>
                                                {/* <th scope="col">ID OS</th> */}
                                                <th scope="col">Paciente</th>
                                                <th scope="col">Items Nota</th>

                                                {/* <th scope="col">
                                                    Total Bruto (R$)
                                                </th> */}

                                                <th scope="col">
                                                    Pág. Caixa (R$)
                                                </th>

                                                <th scope="col">
                                                    Pág. Eletrônico (R$)
                                                </th>
                                                {pageData?.total_advance_receipt > 0 ?  <th scope="col">
                                                    Rec. Antecipado (R$)
                                                </th> : null}
                                               
                                                <th scope="col">
                                                    Taxa de processamento (R$)
                                                </th>
                                                <th scope="col">
                                                    Taxa de antecipação (R$)
                                                </th>
                                                <th scope="col">
                                                    Total Líquido (R$)
                                                </th>

                                                {/* <th scope="col">
                                                    Data Repasse
                                                </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageData.data.map((row, index) => (
                                                <tr>
                                                    <td>#{row.id}</td>
                                                    <td>
                                                        {row.created_at
                                                            ? new Date(
                                                                  row.created_at
                                                              ).toLocaleDateString(
                                                                  "pt-BR"
                                                              )
                                                            : null}{" "}
                                                    </td>
                                                    <td>
                                                        #
                                                        {row?.sales_order_id
                                                            ? row?.sales_order_id
                                                            : row?.os
                                                                  ?.sales_order_id
                                                            ? row?.os
                                                                  ?.sales_order_id
                                                            : ""}
                                                    </td>
                                                    {/* <td>#{row.os_id}</td> */}
                                                    <td>
                                                        {row.patient?.id +
                                                            " - " +
                                                            row.patient?.name}
                                                    </td>
                                                    <td>
                                                        {row.items.map(data => {
                                                            return (
                                                                <>
                                                                    {
                                                                        data
                                                                            .item
                                                                            .name
                                                                    }{" "}
                                                                    (
                                                                    {
                                                                        data.quantity
                                                                    }
                                                                    ) - R${" "}
                                                                    {new Intl.NumberFormat(
                                                                        "pt-br",
                                                                        {
                                                                            style:
                                                                                "decimal",
                                                                            currency:
                                                                                "BRL",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ).format(
                                                                        data.quantity *
                                                                            ((data.price
                                                                                ? data.price
                                                                                : 0) /
                                                                                100)
                                                                    )}
                                                                    <br />
                                                                </>
                                                            )
                                                        })}
                                                    </td>
                                                    {/* <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                ((row?.total_note_paid ??
                                                                    0) +
                                                                    (row?.total_processing_fee_manual ??
                                                                        0) +
                                                                    (row?.total_anticipation_fee_manual ??
                                                                        0)) /
                                                                    100
                                                            )}
                                                    </td> */}

                                                    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                (row.total_note_manual_paid +
                                                                    (row?.total_processing_fee_manual ||
                                                                        0) +
                                                                    (row?.total_anticipation_fee_manual ||
                                                                        0)) /
                                                                    100
                                                            )}
                                                    </td>
                                                    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                row.total_note_electronic_paid /
                                                                    100
                                                            )}
                                                    </td>
                                                    {pageData?.total_advance_receipt > 0 ?    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                row.total_advance_receipt
                                                                    ? row.total_advance_receipt /
                                                                          100
                                                                    : 0
                                                            )}
                                                    </td> : null}
                                                    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                row?.total_processing_fee /
                                                                    100 || 0
                                                            )}
                                                    </td>
                                                    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                row?.total_anticipation_fee /
                                                                    100 || 0
                                                            )}
                                                    </td>
                                                    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                ((row?.total_note_paid ??
                                                                    0) -
                                                                    (row?.total_anticipation_fee_electronic ||
                                                                        0) -
                                                                    (row?.total_processing_fee_electronic ||
                                                                        0)) /
                                                                    100
                                                            )}
                                                    </td>
                                                    {/* <td>
                                                        {row.prof_transfer_invoice_payment
                                                            ? new Date(
                                                                  row.prof_transfer_invoice_payment.created_at
                                                              ).toLocaleDateString(
                                                                  "pt-BR"
                                                              )
                                                            : null}
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                        <th colSpan={5} style={{ fontWeight:"bold",fontSize:"18px" }}>Total Geral</th>
                                        <td style={{ fontWeight:"bold" }}>
                                            {"R$ " +
                                            new Intl.NumberFormat("pt-br", {
                                                style: "decimal",
                                                currency: "BRL",
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }).format(
                                               (pageData?.calc_baseboard_data?.total_manual_paid)/100
                                            )}
                                        </td>
                                        <td style={{ fontWeight:"bold" }}>
                                            {"R$ " +
                                            new Intl.NumberFormat("pt-br", {
                                                style: "decimal",
                                                currency: "BRL",
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }).format(
                                                (pageData?.calc_baseboard_data?.total_electronic_paid)/100
                                            )}
                                        </td>
                                        {pageData?.total_advance_receipt > 0 ? (
                                            <td>
                                            {"R$ " +
                                                new Intl.NumberFormat("pt-br", {
                                                style: "decimal",
                                                currency: "BRL",
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                }).format(
                                                ((pageData.total_advance_receipt) / 100)
                                                )}
                                            </td>
                                        ) : null}
                                           <td style={{ fontWeight:"bold" }}>
                                            {"R$ " +
                                            new Intl.NumberFormat("pt-br", {
                                                style: "decimal",
                                                currency: "BRL",
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }).format(
                                                (pageData?.calc_baseboard_data?.total_processing_fee)/100
                                            )}
                                        </td>
                                        <td style={{ fontWeight:"bold" }}>
                                            {"R$ " +
                                            new Intl.NumberFormat("pt-br", {
                                                style: "decimal",
                                                currency: "BRL",
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }).format(
                                                (pageData?.calc_baseboard_data?.total_anticipation_fee)/100
                                            )}
                                        </td>
                                        <td style={{ fontWeight:"bold" }}>
                                            {"R$ " +
                                            new Intl.NumberFormat("pt-br", {
                                                style: "decimal",
                                                currency: "BRL",
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }).format(
                                                (pageData?.calc_baseboard_data?.total_liquid)/100
                                            )}
                                        </td>
                                        </tr>
                                    </tfoot>
                                    </table>
                                    {controlState ? (
                                        <div
                                            className="spinner-border text-info"
                                            role="status"
                                            style={{
                                                position: "absolute",
                                                left: "633px",
                                                top: "410",
                                            }}
                                        >
                                            <span className="sr-only">
                                                Sincronizando...
                                            </span>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <br />
                                  {pageData?.forecast_date_rules?.can_show ? <Row style={{ marginBottom:"10px" }}>
                                        <Col>
                                        
                                       {pageData?.forecast_date_rules?.manual_data?.length > 0 ? <PaymentForecast forecastRules={pageData?.forecast_date_rules?.manual_data ?? []} transactionMode={1}/> : null} 
                                        {pageData?.forecast_date_rules?.electronic_data?.length > 0 ? <PaymentForecast forecastRules={pageData?.forecast_date_rules?.electronic_data ?? []} transactionMode={2}/> : null}
                                        </Col>
                                    </Row> : null}
                              
                                    <Row>
                                        <Col>
                                            Recebi do Caixa da Clínica Vittá a
                                            quantia de{" "}
                                            <strong>
                                                {" "}
                                                R${" "}
                                                {new Intl.NumberFormat(
                                                    "pt-br",
                                                    {
                                                        style: "decimal",
                                                        currency: "BRL",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                ).format(
                                                    pageData.total_note_manual_paid
                                                )}{" "}
                                            </strong>{" "}
                                            referente a serviços prestados em
                                            parceria referenciados no
                                            demonstrativo.
                                            {pageData.total_note_electronic_paid >
                                            0 ? (
                                                <>
                                                    <br />
                                                    Estou ciente de que também
                                                    receberei o valor de{" "}
                                                    <strong>
                                                        R${" "}
                                                        {new Intl.NumberFormat(
                                                            "pt-br",
                                                            {
                                                                style:
                                                                    "decimal",
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ).format(
                                                            pageData.total_note_electronic_paid
                                                        )}
                                                    </strong>{" "}
                                                    através de transferência
                                                    bancária.
                                                </>
                                            ) : (
                                                ""
                                            )}
                                     
                                         
                                            {pageData.total_advance_receipt >
                                            0 ? (
                                                <>
                                                    <br />
                                                    Estou ciente que também
                                                    recebi antecipadamente o
                                                    valor de{" "}
                                                    <strong>
                                                        R${" "}
                                                        {new Intl.NumberFormat(
                                                            "pt-br",
                                                            {
                                                                style:
                                                                    "decimal",
                                                                currency: "BRL",
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ).format(
                                                            pageData.total_advance_receipt
                                                        )}
                                                        .
                                                    </strong>{" "}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Row>
                                    <br />
                                    <strong> Data Recebimento: </strong>

                                    <br />
                                    <br />
                                    <br />
                                    <Row>
                                        <Col className="d-flex flex-column align-items-center">
                                            <div style={{ width: "300px" }}>
                                                <hr
                                                    style={{
                                                        borderTop:
                                                            "1px solid rgba(0, 0, 0, 0.7)",
                                                    }}
                                                ></hr>
                                            </div>
                                            <div>
                                                {" "}
                                                <strong>
                                                    {" "}
                                                    {
                                                        pageData.header
                                                            ?.professional?.name
                                                    }{" "}
                                                </strong>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DemonstrativeReport
