import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Input,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { Formik, Field, Form } from "formik";
import { useDispatch } from "react-redux";
import { showSweetAlertDialog, showToast } from "store/layout/actions";
import useCashControl from "services/vitta-core/cash-control";
import { search as userServiceSearch } from "services/vitta-core/user";
import { search as searchUnits } from "services/vitta-core/unit";

const ReactSelectInput = ({ field, form: { setFieldValue }, ...props }) => (
  <Select {...field} {...props} onChange={(option) => setFieldValue(field.name, option)} />
);

const AsyncSelectInput = ({ field, form: { setFieldValue }, ...props }) => (
  <AsyncSelect {...field} {...props} onChange={(option) => setFieldValue(field.name, option)} />
);

const FinancialControlReport = () => {
  const [controlState, setControlState] = useState(false);
  const dispatch = useDispatch();
  const { exportFinancialControlsReport } = useCashControl();

  const loadUserInputOptions = async (inputValue) => {
    try {
      const response = await userServiceSearch({ term: inputValue }, 1, 50);
      return [
        { label: "Todos", value: "all" }, 
        ...response.data.map((user) => ({
          label: `#${user.id} - ${user.name}`,
          value: user.id,
        })),
      ];
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const loadUnitOptions = async (inputValue) => {
    try {
      const response = await searchUnits({ term: inputValue }, 1, 50);
      return [
        { label: "Todos", value: "all" }, 
        ...response.data.map((unit) => ({
          label: unit.name,
          value: unit.id,
        })),
      ];
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const processOptions = [
    { label: "Todos", value: "all" },
    { label: "Abertura de Caixa", value: 1 },
    { label: "Fechamento de Caixa", value: 2 },
    { label: "Recebimento de Cliente", value: 3 },
    { label: "Depósito Bancário", value: 4 },
    { label: "Subsídio Médico", value: 5 },
    { label: "Outra Operação", value: 6 },
    { label: "Transferência entre Caixas", value: 7 },
    { label: "Máquina de Café", value: 8 },
  ];

  const onSubmit = async (data_form) => {
    let unit_id = data_form.unit_id?.map((item) => item.value);
    let process_id = data_form.process_id?.map((item) => item.value);
    let user_id = data_form.user_id?.map((item) => item.value);

    if (unit_id?.includes("all")) unit_id = null;
    if (process_id?.includes("all")) process_id = null;
    if (user_id?.includes("all")) user_id = null;

    let filter = {
      unit_id: unit_id ? unit_id.join(",") : null,
      created_user_id: user_id ? user_id.join(",") : null,
      process_origin_id: process_id ? process_id.join(",") : null,
      start_created_at: data_form.start_created_at,
      end_created_at: data_form.end_created_at,
    };

    if (!filter.start_created_at || !filter.end_created_at) {
      return dispatch(
        showSweetAlertDialog({
          title: "Erro",
          text: "Insira as datas corretamente",
          type: "error",
          showConfirm: true,
        })
      );
    }

    try {
      setControlState(true);
      const result = await exportFinancialControlsReport(filter);
      var headers = result.headers;
      var blob = new Blob([result.data], {
        type: headers["content-type"],
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Relatório Controle Financeiro.xlsx";
      link.click();

      dispatch(showToast({ title: "Sucesso", text: "Relatório exportado com sucesso!", type: "success" }));
    } catch (error) {
      console.error("Erro ao exportar o relatório:", error);
    } finally {
      setControlState(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Relatório" breadcrumbItem="Controle Financeiro" />
          <Row className="justify-content-center">
            <Col xs="6">
              <Card>
                <CardBody>
                  <Formik
                    initialValues={{
                      user_id: [],
                      unit_id: [],
                      process_id: [],
                      start_created_at: null,
                      end_created_at: null,
                    }}
                    onSubmit={onSubmit}
                  >
                    {formProps => (
                      <Form>
                        {/* Seleção de Usuários */}
                        <Row className="d-flex justify-content-center">
                          <Col sm="8" className="mt-2">
                            <Label>Usuário</Label>
                            <Field
                              name="user_id"
                              placeholder="Selecionar Usuário"
                              cacheOptions
                              defaultOptions
                              loadOptions={loadUserInputOptions}
                              component={AsyncSelectInput}
                              isMulti
                              isClearable
                            />
                          </Col>
                        </Row>

                        {/* Seleção de Unidades */}
                        <Row className="d-flex justify-content-center mt-2">
                          <Col sm="8">
                            <Label>Unidade</Label>
                            <Field
                              name="unit_id"
                              placeholder="Selecione a Unidade"
                              loadOptions={loadUnitOptions}
                              isMulti
                              component={AsyncSelectInput}
                            />
                          </Col>
                        </Row>

                        {/* Seleção de Processos */}
                        <Row className="d-flex justify-content-center mt-2">
                          <Col sm="8">
                            <Label>Processo de Origem</Label>
                            <Field
                              name="process_id"
                              placeholder="Selecione o Processo"
                              options={processOptions}
                              component={ReactSelectInput}
                              isMulti
                              isClearable
                            />
                          </Col>
                        </Row>

                        {/* Campos de Data */}
                        <Row className="d-flex justify-content-center mt-2">
                          <Col sm="8">
                            <Label>Data Inicial</Label>
                            <Input name="start_created_at" type="date" onChange={formProps.handleChange} />
                          </Col>
                        </Row>

                        <Row className="d-flex justify-content-center mt-2">
                          <Col sm="8">
                            <Label>Data Final</Label>
                            <Input name="end_created_at" type="date" onChange={formProps.handleChange} />
                          </Col>
                        </Row>

                        {/* Botão de Enviar */}
                        <Row className="d-flex justify-content-center mt-4">
                          <Col sm="8" className="text-right">
                            <Button type="submit" className="btn btn-dark" disabled={controlState}>
                              {controlState ? "Gerando Relatório..." : "Gerar Relatório"}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FinancialControlReport;
