import { useState, useCallback } from "react";
import { checkTransactionUpdates } from "services/vitta-core/sales-order-payment";

const usePaymentStatus = (paymentId) => {
    const fetchPaymentStatus = useCallback(async () => {
        if (!paymentId) return null;

        try {
            const updatedPayment = await checkTransactionUpdates(paymentId);
            console.log("Novo status recebido:", updatedPayment);
            return updatedPayment; 
        } catch (error) {
            console.error("Erro ao buscar status do pagamento:", error);
            return null;
        }
    }, [paymentId]);

    return { refreshStatus: fetchPaymentStatus }; 
};

export default usePaymentStatus;
