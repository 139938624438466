import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup,
    CardTitle,
    Table,
    Label,
    Input,
    Container,
    UncontrolledTooltip,
} from "reactstrap"
import debounce from "debounce-promise"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FastField, FieldArray, Form, Formik } from "formik"
import React, { useState, useEffect } from "react"
import NumberFormat from "react-number-format"
import AsyncSelect from "react-select/async"
import { loadDataEditform, update, create } from "services/vitta-core/item"
import {search} from "services/vitta-core/price-items"
import { search as itemGroupServiceSearch } from "services/vitta-core/item-groups"

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => <Input type="text" {...field} {...props} />

const CurrencyInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)
const defaultPriceItemsValue = {
    name:'',
    description:'',
    group_id:null,
    erp_id:'',
    price_table_item:null
}

const defaultValues = {
    price: 0,
    price_table: null,
}

const RegistrationPriceItems = () => {

    const { id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const [pageData, setPageData] = useState({
        price_items: defaultPriceItemsValue,
    })

    const [erpID, setErpID] = useState('')


    const LoadPriceTableOption = async() =>{
        return new Promise(async (resolve, reject) => {
            try {
                let data = await search()
                let price_table_values = []
                data.forEach(element => {
                    if(element.id === 12)  //Por enquanto somente a tabela de preço da facunicamps pode ser adicionada manualmente
                    {
                        price_table_values.push({
                            label: element.name,
                            value: element.id,
                        })
                    }
                })

                return resolve(price_table_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsPriceTable = debounce(LoadPriceTableOption, 600)

    const loadItemGroupInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await itemGroupServiceSearch(
                    { term: inputValue },
                    1,
                    50
                )

                let users_values = []
                response.data.forEach(element => {
                    users_values.push({
                        label: `#${element.id} - ` + element.name,
                        value: element.id,
                    })
                })

                return resolve(users_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsItemGroups = debounce(loadItemGroupInputOptions, 600)
    useEffect(() => {
        if (id) loadDataEditPage()
    }, [id])

    const loadDataEditPage = async () => {
        try {
            let data = await loadDataEditform(id)
            let value = {}
            value.name = data?.priceItems?.name
            value.description = data?.priceItems?.description
            value.group = {
                label:data?.priceItems?.group?.name,
                value:data?.priceItems?.group?.id
            }
            value.erp_id = data?.priceItems?.erp_id
            setErpID(data?.priceItems?.erp_id)
            value.price_table_item = []
            if(data?.priceItems?.price_table_items){
                for (let i = 0; i < data?.priceItems?.price_table_items?.length; i++) {
                    let row = data.priceItems?.price_table_items[i];
                    let option = {
                        label: row?.price_table?.name,
                        value: row?.price_table?.id
                    }
                    row.price_table = option
                    row.price = parseInt(row.price)/100 
                    value.price_table_item.push(row)
                }
            }
            setPageData({ price_items: value })

        } catch (e) {
            console.log(e)
        }
    }
    const onSubmit = (form_data) =>{
        let formSubmit = JSON.parse(JSON.stringify(form_data))
        let text = id? "Tem certeza que deseja alterar esse cadastro? Os dados podem ser sobrescritos pela  integração com o SAP.": "Tem certeza que deseja cadastrar esse serviço? Os dados podem ser sobrescritos pela  integração com o SAP."
        return dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: text,
                type: "warning",
                onConfirmAction: () => {
                    handlesubmit(formSubmit)
                },
                showCancel: true,
                showConfirm: true,
                confirmBtnText: "Sim",
                cancelBtnText: "Não",
    
            })
        )
    }
    

    const handlesubmit = async (formSubmit) => {
        try {
            let filter = {}
            if (formSubmit.name) filter.name = formSubmit.name
            if (formSubmit.description) filter.description = formSubmit.description
            if (formSubmit.group) filter.group = formSubmit.group.value
            if (formSubmit.erp_id) filter.erp_id = formSubmit.erp_id
            if (formSubmit.price_table_item && formSubmit.price_table_item.length>0){
                let table_value = formSubmit.price_table_item.filter(e => e.price_table !== null)
                for(let i=0; i<table_value.length; i++){
                    if(formSubmit.price_table_item.find(e=>e.price_table===table_value[i].price_table.value)){
                        return dispatch(
                            showSweetAlertDialog({
                                title: "Ops... ",
                                text:
                                    "Tabela de Preço igual",
                                type: "error",
                            })
                        )
                    }
                    table_value[i].price_table = table_value[i].price_table.value
                }
                filter.price_table_item = table_value
            }
            if (id) {
                await update(id, filter)
            } else {
                await create(filter)
            }
            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )
            history.push("/servicos")
        } catch (e) {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text:
                        e?.response?.data?.error,
                    type: "error",
                })
            )
            console.log(e)
        }

    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Serviços"
                        breadcrumbItem="Serviços"
                    />

                    <Formik
                        initialValues={pageData.price_items}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                    >
                        {({ values }) => (
                            <Form>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            Dados do Serviço
                                        </CardTitle>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>
                                                        Nome
                                                    </Label>
                                                    <FastField
                                                        name="name"
                                                        component={
                                                            TextInput
                                                        }
                                                        defaultValue={
                                                            values?.name
                                                        }
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>
                                                        Descrição
                                                    </Label>
                                                    <FastField
                                                        name="description"
                                                        component={
                                                            TextInput
                                                        }
                                                        defaultValue={
                                                            values?.description
                                                        }
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>
                                                        Grupo
                                                    </Label>
                                                    <FastField
                                                        name={`group`}
                                                        placeholder="Selecione um grupo"
                                                        // cacheOptions
                                                        // defaultOptions
                                                        // isClearable
                                                        loadOptions={debounceOptionsItemGroups}
                                                        component={AsyncSelectInput}
                                                        defaultValue={values?.group}
                                                        isDisabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>
                                                        Código Sap
                                                    </Label>
                                                    <FastField
                                                        name="erp_id"
                                                        component={
                                                            TextInput
                                                        }
                                                        defaultValue={
                                                            values?.erp_id
                                                        }
                                                        // disabled={id && erpID?true:false}
                                                        disabled
                                                        style={id && erpID?{backgroundColor:"#f8f4f4"}:{backgroundColor:"white"}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                    </Card>
                                        {id?
                                        <Card>
                                        <CardBody>
                                            <CardTitle>
                                                Dados da Tabela de Preço
                                            </CardTitle>
                                        <FieldArray
                                            name="price_table_item"
                                            render={arrayHelpers => (
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Table className="table table-bordered table-striped" id="TableItems">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th scope="col">Tabela de Preço</th>
                                                                    <th scope="col">Valor</th>
                                                                    {/* <th scope="col"></th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>{
                                                                values?.price_table_item?.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <tr key={index}>
                                                                            <td style={{ width: '46%' }}>
                                                                                <FormGroup style={{ marginBottom: "0px" }}>
                                                                                    <FastField
                                                                                        name={`price_table_item[${index}].price_table`}
                                                                                        placeholder="Selecione uma tabela de preço"
                                                                                        // cacheOptions
                                                                                        // defaultOptions
                                                                                        // isClearable
                                                                                        loadOptions={debounceOptionsPriceTable}
                                                                                        component={AsyncSelectInput}
                                                                                        defaultValue={item?.price_table}
                                                                                        isDisabled={ item?.price_table?.value ? true : false }

                                                                                    />
                                                                                </FormGroup>

                                                                            </td>
                                                                            <td style={{ width: '46%' }}>
                                                                                <FormGroup  style={{ marginBottom: "0px" }}>
                                                                                    <FastField
                                                                                        name={`price_table_item[${index}].price`}
                                                                                        defaultValue={
                                                                                            item?.price
                                                                                        }
                                                                                        displayType={"input"}
                                                                                        thousandSeparator={"."}
                                                                                        decimalSeparator={","}
                                                                                        className="form-control"
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        allowEmptyFormatting={true}
                                                                                        prefix={"R$ "}
                                                                                        component={
                                                                                            CurrencyInput
                                                                                        }
                                                                                        disabled={ item?.price_table?.value && item?.price_table?.value != 12}
                                                                                    />
                                                                                </FormGroup>

                                                                            </td>
                                                                            {/* <td style={{ width: '8%' }}>
                                                                                <FormGroup className="mt-2 text-center">
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="text-danger"
                                                                                        onClick={() => {
                                                                                            arrayHelpers.remove(
                                                                                                index
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        Excluir
                                                                                    </Link>
                                                                                </FormGroup>
                                                                            </td> */}
                                                                        </tr>
                                                                    )
                                                                )}
                                                            </tbody>
                                                            {<tfoot>
                                                                <Col>
                                                                    <Button
                                                                        id="iconedittooltip4"
                                                                        color="primary"
                                                                        className="mb-2 mt-2"
                                                                        onClick={() => {
                                                                            arrayHelpers.push(defaultValues)
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-plus"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="left"
                                                                            target={
                                                                                "iconedittooltip4"
                                                                            }
                                                                        >
                                                                            Clique
                                                                            para
                                                                            adicionar
                                                                        </UncontrolledTooltip>
                                                                    </Button>
                                                                </Col>
                                                            </tfoot>}

                                                        </Table>
                                                    </Col>

                                                </Row>
                                            )}
                                        >

                                        </FieldArray>
                                        </CardBody>
                                    </Card>
                                        :''}
                                        <Row>
                                            <Col>
                                                <div align="end">
                                                    { <Button
                                                        color="primary"
                                                        type="submit"
                                                        className="mr-2"
                                                    >
                                                        Salvar
                                                    </Button> }
                                                    <Link
                                                        to="/servicos"
                                                        className="btn btn-danger"
                                                    >
                                                        Cancelar
                                                    </Link>
                                                </div>

                                            </Col>
                                        </Row>
                            </Form>
                        )}


                    </Formik>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default RegistrationPriceItems